// Game-editor: Naam van een item of action wijzigen

.edit-name {
	display: grid;
	grid-template-columns: auto 1fr;
	margin-bottom: 2rem;

	&_icon-container {
		display: flex;
		align-self: end;

		.icon {
			height: 3.4rem;
		}

		.icon--input {
			stroke-width: 0;
		}
	}

	&_name-container {
		display: flex;
		flex-direction: column;

		.form_label,
		.form_text-field {
			margin-left: 1rem;
			width: 23.6rem;
		}
	}
}
