.storyline {
	margin: 1em 0 1em 0.5em;

	.icon--dropdown,
	.icon--dropdown-right {
		height: 1.6rem;
		fill: var(--color-grey-light);
		stroke: var(--color-grey-light);
	}

	.icon--selected {
		fill: var(--color-primary);
		stroke: var(--color-primary);
		stroke-width: 2rem;
	}

	.name--selected {
		color: var(--color-primary);
		font-weight: 600;
		cursor: pointer;
	}

	.icon--dropdown-right {
		transform: rotate(270deg);
	}

	&_game {
		color: var(--color-grey-very-dark);
		font-size: 1.8rem;
		font-weight: 900;
		margin-bottom: 0.5rem;

		.game_name {
			position: pointer;
		}
	}

	&_category,
	&_chapter,
	&_scene,
	&_screen,
	&_timeline,
	&_item,
	&_subitem {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
		line-height: 1.7;
		cursor: pointer;

		&--selected {
			border-radius: 5px;
			cursor: pointer;
		}
	}

	&_category,
	&_chapter,
	&_scene {
		align-items: flex-start;

		.category_icon--dropdown,
		.chapter_icon--dropdown,
		.scene_icon--dropdown {
			margin-top: 0.5rem;
		}
	}

	.chapter,
	.scene {
		&_name {
			display: flex;
			flex-direction: row;
			column-gap: 0.5rem;
			line-height: 1.2;
			margin-top: 0.4rem;
			padding-bottom: 0.5rem;
		}
	}

	.storyline_rename-field {
		border: 1px dashed var(--color-grey-very-dark);
		margin: -1px;
	}

	&_chapter,
	&_category {
		padding-left: 0.5rem;
		padding-top: 0.2em;

		&--placeholder {
			border-bottom: 2px solid var(--color-primary);
			margin: 0 2.3rem;
		}
	}

	&_scene {
		display: flex;
		margin-left: 2.3rem;
		padding-left: 0.3rem;

		&--placeholder {
			border-bottom: 2px solid var(--color-primary);
			margin: 0 3.8rem;
		}
	}

	&_screen,
	&_timeline {
		display: flex;
		margin-left: 3.8rem;
		padding-left: 0.3rem;

		.icon {
			height: 1.5rem;
		}

		&-thumbnail {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-left: 3.6rem;
			cursor: pointer;

			.screen_thumbnail,
			.timeline_thumbnail {
				width: 8rem;
				width: calc(16rem / 2.5);
				height: calc(9rem / 2.5);
				background-color: var(--color-grey-light);
				box-shadow: 0px 0px 0px 1px var(--color-grey-very-dark);

				&.thumbnail--selected {
					box-shadow: 0px 0px 0px 2px var(--color-primary);
				}
			}

			.screen_name,
			.timeline_name {
				margin-bottom: 0.7rem;
				font-size: 1.4rem;
				border: 1px solid transparent;
			}
		}
	}

	&_item {
		margin-left: 6rem;
		padding-left: 0.3rem;

		.icon {
			height: 1.5rem;
			stroke-width: 1rem;
		}
	}

	&_subitem {
		margin-left: 8.3rem;
		padding-left: 0.3rem;

		.icon {
			height: 1.5rem;
			stroke-width: 1rem;
		}
	}
}
