.table {
	width: 100%;

	&_header {
		text-align: left;
		border-bottom: 1px solid var(--color-grey-very-light);
		font-weight: 600;
		cursor: default;

		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		&:not(:last-child) {
			padding-right: 1rem;
		}
	}

	&_cell {
		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		&:not(:last-child) {
			padding-right: 1rem;
		}

		.icon {
			height: 1.5rem;
		}
	}

	&_row--clickable {
		cursor: pointer;
		&:hover {
			background-color: var(--color-grey-light);
		}
	}
	// tr:nth-child(even) {
	// 	background-color: rgb(246, 243, 243);
	// }
}
