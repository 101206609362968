.dashboard-trainer_dashboard-live {
	display: flex;
	flex-direction: column;
	background-color: var(--color-grey-very-dark);
	min-height: 100vh;
	max-width: 100vw;
	aspect-ratio: 16 / 9;
	border-radius: 0;
	row-gap: 0.5em;
	padding: 1rem 3rem;

	&.tijdelijk {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}

	.dashboard-live_header {
		display: flex;

		.dashboard-live_names-container {
			margin-top: 2rem;

			.dashboard-live_game {
				color: white;
				font-size: 3rem;
			}

			.dashboard-live_round {
				color: white;
				font-size: 1.8rem;
				padding-top: 0.5rem;
			}
		}

		.dashboard-live_timer-container {
			margin-left: auto;
			display: flex;
			flex-direction: column;

			.dashboard-live_titles-container {
				color: white;
				display: grid;
				grid-template-columns: 1fr 1.5rem 1fr 1.5rem 1fr;
				column-gap: 2rem;
				text-align: center;
				font-size: 2rem;
				padding: 0 2rem;
			}

			.dashboard-live_timer {
				margin-top: 0.2rem;
				background-color: white;
				font-size: 3rem;
				border-radius: 15px;
				display: grid;
				grid-template-columns: 1fr auto 1fr auto 1fr;
				column-gap: 2rem;
				text-align: center;
				font-size: 7rem;
				padding: 0 1rem;

				&.dashboard-live_green {
					background-color: rgb(180, 255, 180);
					border: 2px solid rgb(50, 120, 50);
				}

				&.dashboard-live_orange {
					background-color: rgb(252, 206, 187);
					border: 2px solid rgb(252, 120, 68);
				}

				&.dashboard-live_red {
					background-color: rgb(248, 137, 137);
					border: 2px solid red;
				}
			}
		}
	}

	.dashboard-live_scores {
		align-self: center;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin: 6rem 0 1rem 0;
		column-gap: 3rem;
		row-gap: 2rem;
		width: 90%;

		.dashboard-live_score-container {
			display: flex;
			flex-direction: column;
			row-gap: 0.5rem;

			.player_nickname {
				color: white;
				text-align: center;
				font-size: 2rem;
			}

			.player_score {
				background-color: white;
				font-size: 5rem;
				text-align: center;
				border-radius: 15px;
				padding: 2rem 2rem;
			}
		}
	}
}

.warning {
	margin-top: 2rem;
	font-weight: 600;
}
