//Opem deze popup door op een van de items in de item-bar te klikken.

.add-item {
	.edit-name {
		width: 28rem;
		margin-left: 2rem;
		margin-top: 1rem;
		margin-right: 5rem;
	}
}
