.footer {
	min-height: 2.6rem;
	color: white;
	background-color: var(--color-grey-dark);
	margin-top: 0.5rem;
	display: grid;
	grid-template-columns: 1fr auto 32rem;
	padding: 0.2rem 0;
	align-items: center;

	&_numbers {
		margin-left: 0.5rem;
		font-size: 1.6rem;
		display: flex;
		gap: 0.8rem;
		cursor: default;

		.footer_number {
			display: flex;
			gap: 0.4rem;

			.footer_number_value {
				color: var(--color-green-light);
			}
		}
	}

	&_zoom {
		display: flex;
		column-gap: 1rem;

		.zoom-percent {
			align-self: center;
			width: 4rem;
			text-align: center;
			cursor: default;
		}

		.zoom-slider {
			width: 15rem;
		}

		input[type='range']::-webkit-slider-runnable-track {
			width: 100%;
			height: 0.5rem;
			cursor: pointer;
			box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
			background: white;
			border-radius: 25px;
			border: 0px solid #000101;
		}

		input[type='range']::-webkit-slider-thumb {
			box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
			height: 1.2rem;
			width: 3rem;
			border-radius: 7px;
			background: #a3a1a1;
			cursor: pointer;
			-webkit-appearance: none;
			margin-top: -3.6px;
		}

		input[type='range']:focus::-webkit-slider-runnable-track {
			background: var(--color-grey-very-light);
		}

		.icon {
			fill: white;
			height: 1.2rem;
		}

		.icon-container:last-of-type {
			margin-left: 0.5rem;
		}

		.zoom-to-fit {
			margin-left: 0.5rem;
			padding: 0 0.2rem;

			&--active {
				padding: 0 0.2rem;
				background-color: var(--color-grey-medium);
				border-radius: 0.2rem;
			}
		}

		.icon--zoom-to-fit {
			stroke-width: 0;
			height: 2rem;
		}
	}

	&_saved {
		margin-left: auto;
		margin-right: 10rem;
		display: flex;
		column-gap: 0.2rem;
		align-items: flex-end;
		justify-content: flex-end;

		&-text {
			color: var(--color-green-light);
			font-size: 1.6rem;
			cursor: default;
		}

		.icon-container {
			align-self: flex-start;
		}
	}

	&_icon--saved {
		fill: var(--color-green-light);
		height: 2.5rem;
	}
}
