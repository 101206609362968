.file-manager {
	@include popup;
	width: 120rem;
	height: 60rem;
	background-color: white;
	display: grid;
	grid-template-rows: auto 1fr auto;
	align-items: baseline;
	overflow: hidden;

	&_header {
		padding: 1rem;
		font-size: 2rem;
		font-weight: 500;
		display: grid;
		grid-template-columns: auto 1fr auto;
		align-items: center;
		border-bottom: 0.1px solid var(--color-grey-very-light);
	}

	&_title {
		justify-self: center;
		cursor: default;
	}

	.icon--add-folder {
		margin-left: 1rem;
		height: 2.8rem;
	}

	&_files {
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	&_files-container {
		display: grid;
		grid-template-columns: 2fr 1fr;
		@include scrollbar;
		scrollbar-gutter: stable;
	}

	&_breadcrumbs-container {
		grid-row: 1 / -1;
		display: flex;
		align-items: center;
		margin: 1rem 0;
	}

	.breadcrumbs {
		padding-top: 0.5rem;
		column-gap: 0.3rem;

		&_text {
			color: var(--color-grey-very-dark);
			&.drag-over {
				outline: 2px solid var(--color-primary);
				outline-offset: 0.5rem;
			}
		}

		&_haakje {
			color: var(--color-grey-very-dark);
		}
	}

	.icon--folder-yellow {
		height: 2.5rem;
		margin-left: 0.2rem;
	}

	.table-container {
		border-right: 0.1px solid var(--color-grey-very-light);
		padding-left: 1rem;
		@include scrollbar;
	}

	.table {
		padding: 2rem 0.5rem 2rem 2rem;
		width: 100%;
		table-layout: fixed;

		.name {
			width: 40%;
		}

		.numer {
			width: 10%;
		}

		.size {
			width: 15%;
		}

		.date {
			width: 25%;
		}

		.edit {
			width: 10%;
		}

		&_header {
			text-align: left;
		}

		tr:hover {
			color: var(--color-grey-very-dark);
			background-color: var(--color-grey-very-light);
		}

		tr.drag-over {
			outline: 2px solid var(--color-primary);
			outline-offset: -2px;
		}

		tr:not(:hover) {
			.dropdown-option_edit-button,
			.dropdown-option_delete-button {
				display: none;
			}
		}

		.selected {
			color: var(--color-grey-very-dark);
			background-color: var(--color-grey-very-light);
		}

		.icon--folder-yellow,
		.icon--img,
		.icon--play-sound,
		.icon--video,
		.icon--pdf,
		.icon--ppt,
		.icon--word,
		.icon--excel {
			margin: 0;
			padding-right: 0.5em;
			vertical-align: bottom;
		}

		.icon--pen,
		.icon--delete {
			height: 1em;
		}

		.icon--delete {
			margin: 0;
		}

		tr {
			vertical-align: middle;
		}

		td {
			padding: 0.2rem 0;
		}

		tr td:last-child {
			display: flex;
			align-items: center;

			.icon--delete {
				padding: 0 0 0 0.5em;
			}
		}
	}

	&_preview {
		max-width: 90%;
		max-height: 100%;
		justify-self: center;
		align-self: center;

		.preview_container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.preview_name {
				display: none;
			}

			.icon--play-sound {
				height: 10rem;
				width: 10rem;
				fill: var(--color-grey-very-light);
				stroke: var(--color-grey-very-light);

				&:hover {
					fill: var(--color-grey-very-light);
					stroke: var(--color-grey-very-light);
				}
			}

			video {
				width: 100%;
				height: auto;
			}

			.icon--play,
			.icon--pause {
				background-color: var(--color-primary);
				border-radius: 50%;
				fill: white;
				stroke: white;
				height: 5rem;
				width: 5rem;
				padding: 1rem;
			}
		}
	}

	&_footer {
		display: grid;
		grid-template-columns: auto auto 1fr auto auto;
		align-items: center;
		padding: 2rem;
	}

	.btn {
		min-width: 14rem;

		&--add:disabled {
			cursor: default;
		}
	}

	.icon--delete {
		padding: 0 10rem;
	}

	.btn--cancel {
		margin-right: 3rem;
	}
}

.popup_file-references {
	&.popup {
		width: 80rem;
	}

	li {
		display: inline-block;
	}

	ul {
		display: flex;
		align-items: center;
	}

	ul:hover {
		color: var(--color-grey-very-dark);
		background-color: var(--color-grey-very-light);
	}

	ul:not(:hover) {
		.dropdown-option_edit-button,
		.dropdown-option_delete-button {
			display: none;
		}
	}

	.selected {
		color: var(--color-grey-very-dark);
		background-color: var(--color-grey-very-light);
	}
}
