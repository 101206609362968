//mixins spelersomgeving (preview en live)

@mixin em-scrollbar {
	overflow-y: auto;
	overflow-x: hidden;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 10em;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 0.6em rgba(0, 0, 0, 0.6);
		border-radius: 10em;
	}
}

@mixin em-scrollbar-both {
	overflow-y: auto;
	overflow-x: auto;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.5em;
		height: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 10em;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 0.6em rgba(0, 0, 0, 0.6);
		border-radius: 10em;
	}
}

//mixin mobile
@mixin em-scrollbar-mobile {
	overflow-y: auto;
	overflow-x: hidden;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 2em;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 10em;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 0.6em rgba(67, 67, 67, 0.6);
		border-radius: 10em;
		height: 2em;
	}
}

//mixins auteursomgevingen

@mixin absCenter {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
}

@mixin fixedCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
}

@mixin scrollbar {
	overflow-y: auto;
	overflow-x: hidden;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		// border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
		// border-radius: 100px;
	}
}

@mixin scrollbar-wide {
	overflow-y: auto;
	overflow-x: hidden;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 1.2rem;
		height: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		// border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
		// border-radius: 100px;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

@mixin scrollbar_effect {
	//hier kunnen we later een effect aan koppelen
	overflow-y: auto;
	overflow-x: hidden;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
		border-radius: 100px;
	}
}

@mixin scrollbar-x {
	overflow-y: hidden;
	overflow-x: auto;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 1.2rem;
		height: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		// border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
		// border-radius: 100px;
	}
	cursor: pointer;
}

@mixin scrollbar-both {
	overflow-y: auto;
	overflow-x: auto;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
		border-radius: 100px;
	}
}

@mixin scrollbar-both-wide {
	overflow-y: auto;
	overflow-x: auto;
	// scrollbar-gutter: stable;
	&::-webkit-scrollbar {
		width: 1.2rem;
		height: 1.2rem;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--color-grey-very-light);
		border-radius: 100px;
	}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
		border-radius: 100px;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

@mixin text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
}

@mixin popup {
	@include fixedCenter;
	z-index: 9999;
	border: 1px solid var(--color-grey-very-dark);
	border-radius: var(--border-radius-popup);
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	background-color: white;
}

// header	 			6rem
// breadcrumbs	 		6rem
// footer				2.6rem
// wysiwyg/header		4rem
// header sidebar		4rem
// edit-container		4rem
// header item-overview 4rem
// margin-bottom		.4rem

//story-title			4rem
//story-buttons			4rem

// padding				.8rem
// sidebar				21rem
// margin				1rem
// margin				1rem
// item-overview		28rem
// padding				.8rem

@mixin height_page-content {
	height: calc(100vh - 15.5rem); //zonder header, breadcrumbs en footer
}

@mixin height_screen {
	height: calc(100vh - 19rem); //zonder header, breadcrumbs, footer en wysiwyg
}

@mixin height_storyline {
	height: calc(100vh - 23.5rem); // zonder header, breadcrumbs, footer, wysiwyg en edit-container
}

@mixin height_storyline-dashboard {
	height: calc(100vh - 19.5rem); // zonder header
}

@mixin height_item-details-container {
	height: calc(100vh - 19.5rem); // zonder header, breadcrumbs, footer, wysiwyg, edit-container en margins
}

@mixin max-height {
	max-height: calc(100vh - 14.6rem);
}

@mixin max-height_screen {
	max-height: calc(100vh - 19rem);
}

@mixin max-height_edit-action {
	max-height: calc(100vh - 17rem);
}

@mixin max-height_page-content {
	min-height: calc(100vh - 15.5rem);
}

@mixin min-height_screen {
	min-height: calc(100vh - 19rem);
}

@mixin width_screen {
	width: calc(100vw - 52.6rem);
}

@mixin max-width_screen {
	max-width: calc(100vw - 52.6rem);
}

@mixin dropshadow {
	filter: drop-shadow(0 0 5px white);
}
