// Verschijnt als je een scherm of item vanuit action-details wilt selecteren
.select-slide {
	@include max-height_edit-action;
	@include scrollbar;
	scrollbar-gutter: stable;
	z-index: 9000;
	width: 30rem;
	margin-right: 0.2rem;
	position: absolute;
	bottom: 3.5rem;
	right: 61rem;
	border: 1px solid var(--color-grey-light);
	background-color: white;
	display: flex;
	flex-direction: column;
	padding-bottom: 2rem;
	line-height: 2.8rem;

	&_header {
		border-bottom: 1px solid var(--color-grey-very-light);
		height: 3.5rem;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;

		&-name {
			justify-self: center;
			font-weight: 500;
			color: inherit;
			padding-left: 3rem;
		}

		.icon--close {
			margin-right: 0.5rem;
		}
	}

	.storyline {
		margin-left: 1rem;
		&_game {
			margin-top: 2rem;
		}
	}
}

//Verschijnt als je bijv. een scherm of item wil kopieren en de locatie voor deze kopie wilt selecteren
.select-popup {
	.popup {
		min-width: 35rem;
		min-height: 45rem;
	}

	.storyline {
		padding: 1rem 5rem 1rem 1rem;
	}
}
