.game-editor {
	&_sidebar {
		display: grid;
		grid-template-rows: auto 1fr;

		&_edit-container {
			display: grid;
			grid-template-columns: 2fr 1fr 1fr 1fr;
			border-bottom: 1px solid var(--color-grey-very-light);
			height: 4rem;

			.icon--expand {
				fill: var(--color-grey-light);
				stroke: var(--color-grey-light);
				display: none;

				&:hover {
					fill: var(--color-grey-light);
					stroke: var(--color-grey-light);
					cursor: default;
				}
			}
		}

		&_storyline {
			margin-left: 0.5rem;
			@include scrollbar;
			@include height_storyline;

			.storyline_scene {
				margin-left: 1em;
			}

			.game_name--name {
				cursor: pointer;
			}
		}

		.storyline {
			margin-top: 2rem;
			margin-bottom: 1rem;
			column-gap: 0.1rem;
			line-height: 2.6rem;
			font-size: 1.5rem;
		}
	}

	&_breadcrumbs {
		.btn {
			font-size: 1.6rem;
			margin-bottom: 0.5rem;
			padding: 1rem 3.2rem;
			padding: 0.8rem 2.4rem;

			&--blue {
				padding: 0.8rem 2rem;
				margin: 0 0.5rem;
			}

			&--yellow:disabled {
				pointer-events: none;
			}
		}
	}

	&_main {
		@include height_page-content;
		display: grid;
		grid-template-columns: 1fr 30rem;
		position: relative;
	}

	&_screen {
		@include height_page-content;
		padding: 0 1rem;
		display: grid;
		grid-template-rows: 4rem 1fr;
		@include scrollbar-both;
		position: relative;

		&_header {
			justify-self: center;
			background-color: var(--color-grey-very-dark);
			color: white;
			text-align: center;
			align-self: center;
			font-size: 2rem;
		}

		&_frame {
			position: relative;
			overflow: auto;
			@include scrollbar-both-wide;
			overscroll-behavior: none;
		}

		&_background {
			// voor mockups
			@include max-height_screen;
			@include max-width_screen;
			// max-width: 100%;
			aspect-ratio: 16/9;
			// border: 1px solid white;
			background-color: white;
			justify-self: center;
			box-shadow: 0 0 0 2px white;

			.items {
				width: 100%;
				height: 100%;
			}
		}

		.game-editor_story {
			z-index: 99999;
			position: absolute;
			left: 0.1rem;
			bottom: 0;
			border-radius: 0;
			box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.345);
			background-color: var(--color-grey-very-dark);
			width: 82rem;
			@include height_storyline;
			// @include scrollbar;
			margin-bottom: 0.3rem;

			.story_main {
				margin-left: 0.5rem;
				position: relative;
				height: inherit;
				padding: 0.5rem 0.2rem 0.2rem 0.2rem;

				.icon-container.story_close {
					position: absolute;
					top: 0.2rem;
					right: 0.5rem;

					.icon {
						height: 2.8rem;
						fill: white;
						stroke: white;
					}
				}

				.game-editor_characteristics {
					height: inherit;

					.story-editor_characteristics {
						height: inherit;
					}
				}

				.tab {
					width: 12rem;
				}

				.characteristics_content {
					border: 1px solid var(--color-grey-very-light);
					background-color: white;
					height: 100%;
					@include scrollbar;

					.story-editor_story {
						height: 100%;
						display: block;

						.story-editor_story_background {
							height: 100%;
						}
					}

					.ql-editor {
						@include scrollbar;
					}

					.form_field--disabled {
						background-color: white;
						border: 1px solid var(--color-grey-very-light);
						white-space: break-spaces;
					}

					// .characteristics_description {
					// 	&.tab_story {
					// 		.story_overview {
					// 			height: 100%;

					// 			.story-editor_story_background {
					// 				height: 100%;

					// 				.story_text {
					// 					@include scrollbar;
					// 					max-height: 35rem;
					// 				}
					// 			}
					// 		}
					// 	}
					// }
					.character_overview {
						height: 13rem;
						border-bottom: 1px solid var(--color-grey-very-light);
						padding-bottom: 1rem;
					}
				}
			}
		}
	}
}

.item-overview {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}

.item-list,
.screen-settings {
	@include height_page-content;
	background-color: white;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;

	a,
	a:hover,
	a:focus,
	a:active {
		text-decoration: none;
		color: inherit;
	}

	&_header {
		display: flex;
		height: 4rem;
		background-color: var(--color-primary);
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		align-items: center;
		justify-content: center;
		cursor: default;

		&-name {
			color: white;
			font-size: 1.8rem;
		}
	}

	&_items {
		@include height_item-details-container;
		@include scrollbar;
		display: flex;
		flex-direction: column;
		padding: 1rem 1rem;
	}

	&_add-group {
		margin-top: auto;
		align-self: start;
		text-decoration: underline;
		font-size: 1.2rem;
		color: var(--color-primary);
		padding-top: 2rem;
	}

	&_item-container {
		display: grid;
		grid-template-columns: 2.2rem 2.2rem 3rem 1fr;
		column-gap: 0.1rem;
		row-gap: 0.7rem;
		padding-top: 0.5rem;

		&--hover {
			color: var(--color-primary);
			font-weight: 600;

			.icon {
				fill: var(--color-primary);
				stroke: var(--color-primary);
				cursor: grab;
			}
		}
	}

	&_group-container {
		.item-list_group--name {
			display: grid;
			grid-template-columns: 1.8rem 2.2rem 2.2rem auto 1fr;
			column-gap: 0.1rem;
			padding-bottom: 0;
			row-gap: 0.7rem;
			padding-top: 0.5rem;

			&--hover {
				color: var(--color-primary);
				font-weight: 600;

				.icon {
					fill: var(--color-primary);
					stroke: var(--color-primary);
					cursor: grab;
				}
			}

			.icon--dropdown {
				height: 1.8rem;
				fill: var(--color-grey-medium);
				stroke: var(--color-grey-medium);
			}

			.item-list_count {
				// color: var(--color-grey-medium);
				align-self: center;
				padding-left: 0.1rem;
			}
		}
		&--dragover {
			outline: 2px solid var(--color-grey-light);
		}
		&--hover {
			color: var(--color-primary);
			font-weight: 600;

			.icon {
				fill: var(--color-primary);
				stroke: var(--color-primary);
				cursor: grab;
			}
		}
		&--contains-hovered {
			& > .item-list_group--name {
				font-weight: 600;
			}
		}
	}

	&_item-container,
	&_group-container {
		&--component,
		&--memorygame,
		&--catchgame {
			color: #a629f4;

			.icon {
				fill: #be6fee;
				stroke: #be6fee;
			}
		}
	}

	&_item-placeholder {
		// height: 2.9rem;
		// flex-shrink: 0;
		outline: 2px solid var(--color-grey-light);
	}
	.item-list_group--items {
		margin-left: 2.4rem;
		border-left: 1px solid var(--color-grey-very-light);

		.item-list_item-container {
			&:first-child {
				padding-top: 0.2rem;
			}
		}

		.item-list_item-container {
			&:last-child {
				margin-bottom: 0.5rem;
			}
		}
	}

	.icon--lock-close {
		width: 1.3rem;
		margin-left: 0.2rem;
	}

	.icon--lock-open {
		width: 1.5rem;
		margin-left: 0.3rem;
	}

	.icon--eye-open,
	.eye-closed-icon {
		width: 2rem;
		margin: 0 0.1rem 0 0.2rem;
	}

	.icon--video,
	.icon--minigame {
		width: 2rem;
		stroke-width: 1.5rem;
	}

	.icon--text,
	.icon--img {
		width: 2rem;
	}

	.icon--login {
		fill: var(--color-grey-very-dark);
		stroke-width: 2rem;
	}

	.icon--input {
		stroke-width: 0;
	}

	.icon--quiz {
		fill: var(--color-grey-very-dark);
		stroke-width: 1.2rem;
	}

	.icon--high-scores {
		stroke-width: 1.2rem;
	}

	.item-list_name {
		margin-left: 0.3rem;
		align-self: center;
		@include text-overflow;
		max-width: 14rem;
	}

	.icon--full-screen {
		height: 2rem;
	}
}

.screen-details {
	&_settings {
		.edit-name {
			padding-top: 2rem;
		}
	}
}

.item-details,
.screen-details {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	@include height_screen;

	&_container {
		// height: calc(100vh - 14.6rem);
		@include height_item-details-container;
		@include scrollbar;
	}

	&_header {
		display: grid;
		grid-template-columns: 1fr auto;
		height: 4rem;
		background-color: var(--color-primary);
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		align-items: center;

		&-name {
			color: white;
			font-size: 1.8rem;
			justify-self: center;
			display: flex;
		}

		.icon--close,
		.icon--dropdown {
			justify-self: end;
			fill: white;
			stroke: white;
			padding-right: 0.75rem;

			&:hover {
				fill: white;
				stroke: white;
			}
		}

		button {
			padding-right: 1rem;
		}

		.icon--dropdown,
		.icon--dropdown {
			fill: white;
			stroke: white;
		}
	}

	.popup {
		// min-width: 35rem;
		// min-height: 45rem;
		max-height: 60rem;

		.storyline {
			@include scrollbar;
			padding: 1rem 5rem 1rem 1rem;
		}
	}

	&_settings {
		background-color: white;
		padding-bottom: 2rem;
		padding-left: 1rem;

		& > div:nth-of-type(1n + 4) {
			margin-top: 2rem;
		}

		&_edit-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 4rem;

			&--left,
			&--right {
				display: flex;
			}

			&--right {
				.icon {
					height: 2.3rem;
				}
			}

			.item-details_icon--lock .icon,
			.item-details_icon--lock-locked .icon {
				height: 1.6rem;
			}
			.icon-container {
				padding-right: 0.5rem;
			}
		}

		.item-copy_popup {
			width: 30rem;

			.form_checkbox {
				padding-left: 1rem;
			}
		}
		.form_number-field {
			width: 10rem;
		}

		.form_field-group {
			margin-top: 2rem;
		}

		.form_dropdown-container-out {
			width: 28rem;
		}

		.select-form_container {
			margin-left: 0;
		}

		.close-options {
			margin-top: 2rem;

			.form_checkbox-group {
				margin-top: 0;
				margin-left: 1rem;
			}

			.form_checkbox {
				height: 3.5rem;
			}

			.close-options_button {
				margin-left: auto;
				margin-right: 0.5rem;
				width: 16rem;
			}
		}

		.screen-settings_background-type {
			.form_dropdown {
				width: 28rem;
			}
		}

		.screen-settings_background-color {
			display: flex;
			margin-top: 1rem;
			margin-bottom: 3rem;
		}

		.settings_button-type {
			.form_dropdown {
				width: 28rem;
			}
		}

		.settings_colorpicker {
			margin-top: 2rem;

			.icon-container {
				justify-self: flex-start;
				margin-left: 2rem;
			}
		}

		.settings_counter {
			.form_label,
			.icon--delete {
				margin-top: 2rem;
			}

			.form_dropdown {
				width: 28rem;
			}

			.flex-container {
				display: flex;
				justify-content: center;

				.btn {
					margin-top: 2rem;
				}
			}
		}

		.settings_edit--popup {
			display: flex;
			align-items: center;
			column-gap: 2rem;
		}

		.settings_edit-type {
			.form_dropdown {
				width: 28rem;
			}
		}

		.settings_highscores {
			margin-top: 2rem;
		}

		.settings_input_correct-input {
			margin-top: 2rem;

			.form_checkbox {
				margin-top: 0.5rem;
				margin-left: 1rem;
			}
		}

		.settings_popup-size {
			.form_dropdown-container-out {
				width: 10rem;
			}
		}

		.settings_popup-type {
			.form_dropdown-container-out {
				width: 16rem;
			}
		}

		.settings_quiz_user-settings {
			margin-top: 2rem;

			.form_dropdown {
				width: 28rem;
			}
		}

		.settings_video_url {
			margin-top: 2rem;

			.form_checkbox-group {
				margin-left: 1rem;
			}
		}

		.settings_nr-tiles {
			.form_dropdown,
			.form_field {
				width: 6rem;
			}
		}

		.settings_feedback-end {
			.form_label {
				margin-right: 1rem;
			}
		}
	}

	&_actions {
		position: relative;
	}

	&_actions,
	&_references {
		background-color: white;
		margin-top: 0.8rem;

		.action-list,
		.reference-list {
			&_header {
				display: grid;
				grid-template-columns: 1fr auto;
				height: 3rem;
				background-color: var(--color-primary);
				align-items: center;

				.icon--close {
					justify-self: end;
					fill: white;
					stroke: white;
					padding-right: 0.75rem;
				}

				.icon--add {
					fill: white;
					height: 1.8rem;
					padding-right: 1rem;
				}
			}

			&_header-name {
				color: white;
				justify-self: left;
				padding-left: 0.5rem;
				display: grid;
				grid-template-columns: 1fr auto;
				height: 3rem;
				background-color: var(--color-primary);
				align-items: center;
				cursor: default;

				.icon-container {
					padding-right: 1rem;
				}

				.icon--dropdown {
					fill: white;
					stroke: white;
				}
			}

			&_action-container {
				padding-bottom: 0.5rem;
				display: grid;
				grid-template-columns: auto 3rem 1fr auto auto;
				grid-template-rows: auto;
				column-gap: 0.5rem;
				row-gap: 0.7rem;
				cursor: pointer;

				.action-list_name {
					width: 16.5rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					&:hover {
						color: var(--color-primary);
					}
				}

				.form_checkbox.input-item_checkbox-correct {
					margin-top: 0;
					grid-column: 4;
					accent-color: var(--color-btn-green);
				}

				.form_checkbox.input-item_checkbox-incorrect {
					margin-top: 0;
					grid-column: 5;
					accent-color: var(--color-btn-red);
				}
			}

			&_actions {
				display: flex;
				flex-direction: column;
				padding: 1rem 0.5rem;

				a,
				a:hover,
				a:focus,
				a:active {
					text-decoration: none;
					color: inherit;
				}

				.icon--dropdown {
					height: 2rem;
				}

				.icon--go-to-url {
					height: 2rem;
				}

				.icon--play-sound {
					height: 2rem;
				}
			}

			&_references {
				padding: 1rem 0.5rem;
				display: grid;
				grid-template-columns: auto 1fr;
				column-gap: 1rem;
				row-gap: 0.5rem;

				.icon {
					align-self: baseline;
					height: 2rem;
					align-self: center;
					padding-left: 0.3rem;

					&:hover {
						fill: var(--color-grey-medium);
						stroke: var(--color-grey-medium);
						cursor: default;
					}
				}
			}
		}
	}
}

.items {
	border: 2px solid white;
	position: relative;
}

.popup_rewards {
	min-width: 120rem;
	max-width: 90vw;
	height: 90vw;
	@include scrollbar-both;
}

// .game-editor_story {
// 	.popup_story {
// 		&.popup {
// 			z-index: 9999;
// 			position: absolute;
// 			left: 24rem;
// 			bottom: 0;
// 			border: 1px solid var(--color-grey-very-dark);
// 			border-radius: 0;
// 			box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
// 			background-color: white;
// 			min-width: 28rem;
// 			max-width: 70vw;
// 			height: 35rem;
// 			max-height: 40rem;
// 			display: grid;
// 			grid-template-rows: auto 1fr auto;
// 			row-gap: 1rem;
// 		}
// 	}
// }
