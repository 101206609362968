.widget_dashboard-trainer {
	grid-row: 1;
	grid-column: 4;
	margin-top: 14rem;
	cursor: default;

	.widget_content {
		min-height: 8rem;
		display: flex;
		align-items: center;
		justify-content: center;

		.link {
			color: var(--color-grey-very-dark);
			text-decoration: underline;
			font-size: 1.5rem;
		}
	}
}
