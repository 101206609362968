.widget_training-files {
	grid-column-start: 5;
	grid-row: span 3;
	@include scrollbar;

	.widget_header {
		display: flex;
		text-align: center;
		position: relative;

		.icon-container {
			position: absolute;
			right: 1rem;

			.icon {
				height: 1.8rem;
				fill: white;
				stroke: white;
				stroke-width: 0;
			}
		}
	}

	.training-files_content {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 0.5rem;
		padding: 0.5rem;

		.training-file_container {
			position: relative;
			display: grid;
			grid-template-columns: 6.5rem auto;
			column-gap: 0.5rem;
			row-gap: 0.3rem;
			background-color: rgb(244, 244, 244);
			padding: 0.2rem;
			width: 100%;

			.training-file_image {
				.image {
					position: relative;
					height: 5rem;
					width: 6.5rem;
					background-color: white;

					.icon {
						height: 3rem;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						display: block;
					}

					.icon--pdf {
						height: 3.5rem;
					}
				}

				.button-container {
					display: flex;
					column-gap: 0.4rem;
					justify-content: center;
					margin-top: -2rem;

					.btn--blue {
						opacity: 0.95;

						.icon {
							fill: var(--color-grey-very-dark);
							stroke: var(--color-grey-very-dark);
						}
					}

					.btn--icon-mini {
						height: 2.5rem;
						width: 2.5rem;

						.icon {
							height: 1.3rem;
							margin-top: 0.6rem;
						}

						.icon--download {
							height: 1.8rem;
							margin-top: 0.4rem;
						}
					}
				}
			}

			.training-file_description {
				font-size: 1.4rem;
				white-space: pre-line;
			}

			.training-file_name {
				grid-column: 1 / -1;
				font-size: 1.4rem;
				font-style: italic;
			}

			.training-file_edit {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				column-gap: 1rem;

				.btn--icon-mini {
					display: none;
					height: 3.5rem;
					width: 3.5rem;

					.icon {
						height: 1.6rem;
						margin-top: 0.5rem;
					}

					&.btn--orange {
						margin-left: 5rem;
					}

					&.btn--grey {
						height: 3rem;
						width: 3rem;

						.icon {
							height: 1.6rem;
							fill: var(--color-grey-medium);
							stroke: var(--color-grey-medium);
						}
					}
				}
			}

			.training-file_edit:hover {
				border: 1px solid var(--color-primary);

				.btn--icon-mini {
					display: block;
				}
			}
		}

		.training-file_container--edit {
			border: 1px solid var(--color-primary);
			cursor: pointer;

			.training-file_edit {
				display: none;
			}

			.training-file_image {
				.btn--orange {
					opacity: 0.95;

					.icon {
						fill: white;
						stroke: white;
					}
				}
			}

			.training-file_description {
				background-color: white;
				height: fit-content;

				.description_edit {
					width: 100%;
					cursor: auto;
				}
			}

			.training-file_save {
				position: absolute;
				top: -2.3rem;
				right: -0.1rem;
				z-index: 1;

				.btn.btn--close {
					font-size: 1.2rem;
					padding: 0.4rem 1.2rem 0.1rem 1.2rem;
					border-radius: 0;
				}
			}
		}
	}

	.btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
