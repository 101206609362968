.wysiwyg-bar {
	display: flex;
	align-items: center;
	column-gap: 0.2rem;
	color: var(--color-grey-very-dark);
	font-size: 1.5rem;

	.icon {
		height: 2rem;
		fill: white;
		stroke: white;
	}

	.active.wysiwyg-bar_icon {
		border: 1px solid var(--color-grey-medium);
		border-radius: 0;
		background-color: rgba(211, 211, 211, 0.5);
		border-radius: 6px;
	}

	.form_dropdown {
		.icon--dropdown {
			fill: var(--color-grey-medium);
			stroke: var(--color-grey-medium);
			top: 0.7rem;
		}
	}

	.form_field,
	.form_dropdown-options {
		border: none;
	}

	.form_dropdown-option {
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		row-gap: 1rem;
	}

	&_dropdown--style {
		.form_dropdown {
			margin-left: -0.2rem;
		}

		.form_dropdown-options {
			padding: 0.5rem;

			.form_dropdown-option {
				padding: 0;
				padding-left: 0.5rem;
				margin: 0.5rem;
				margin-left: 0;
				display: flex;
				row-gap: 1rem;
				text-align: left;
			}
			.form_dropdown-option--selected {
				background-color: white;
				border-left: 3px solid var(--color-primary);

				&:hover {
					background-color: var(--color-grey-very-light);
				}
			}
		}

		.wysiwyg-bar_style {
			&--small {
				font-size: 0.75em;
			}

			&--normal {
				font-size: 1em;
			}

			&--large {
				font-size: 1.5em;
			}

			&--XL {
				font-size: 2.5em;
			}

			&--XXL {
				font-size: 4.5em;
			}
		}
	}

	&_dropdown--align,
	&_dropdown--valign {
		.form_field {
			padding: 0 0.2rem;
		}

		.form_field,
		.form_dropdown-options {
			width: 5.5rem;
			border-radius: 0;
			background-color: var(--color-grey-very-dark);
			fill: white;
			stroke: white;
		}

		.form_dropdown-option--selected {
			border: 1px solid var(--color-grey-medium);
			border-radius: 0;
			background-color: rgba(211, 211, 211, 0.5);
		}

		.icon-container {
			.icon--dropdown {
				fill: white;
				stroke: white;
				margin-right: 0.5rem;
			}
		}
	}

	.colorpicker {
		display: flex;
	}

	&_icon--bold {
		margin-left: 1rem;
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--italic {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--underline {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--text-color {
		margin: 0 1rem;
	}

	&_icon--marker {
		.icon--marker {
			height: 1.6rem;
			margin-bottom: 0.1rem;
		}
	}

	&_icon--align-left {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-center {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-right {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-justify {
		padding-right: 1rem;
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-top {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-middle {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--align-bottom {
		padding-right: 1rem;
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--list-bullets {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--list-numbers {
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
	}

	&_icon--link {
		padding: 0 1rem;
		border: 1px solid var(--color-grey-very-dark);
		padding: 0.4rem;
		margin-right: 0.5rem;
	}

	&_icon--background-color {
		padding-left: 1rem;
		border-left: 1px solid var(--color-grey-medium);
	}

	&_line-style-container {
		.dropdownbutton {
			.icon.icon--line-style {
				stroke-width: 0px;
			}
		}

		.icon--line-thick {
			stroke-width: 4rem;
		}
	}

	&_border-radius-container {
		.dropdownbutton-options {
			.dropdownbutton-option {
				.icon--border-sharp {
					height: 1.5rem;
					stroke-width: 5rem;
					transform: rotate(270deg);
				}

				.icon--border-round {
					transform: rotate(270deg);
				}
			}

			.icon-container {
				width: 2rem;
			}
		}
	}

	&_container {
		display: flex;
	}

	&_icon--nickname {
		border-left: 1px solid var(--color-grey-medium);
		padding-left: 0.5rem;
		margin-left: 0.2rem;
	}
}

.dropdownbutton-container {
	position: relative;

	.dropdownbutton {
		display: flex;
	}

	.dropdownbutton-options {
		padding: 0.5rem 0;
		width: 16rem;
		position: absolute;
		right: -8rem;
		top: 3.5rem;
		color: white;
		background-color: var(--color-grey-very-dark);
		z-index: 9000;

		.dropdownbutton-option {
			display: flex;
			column-gap: 1rem;
			padding: 0 0.5rem;
			cursor: pointer;

			&--selected {
				border: 1px solid var(--color-grey-medium);
				border-radius: 0;
				background-color: rgba(211, 211, 211, 0.5);
			}
		}

		.icon-container {
			width: 2rem;
		}
	}
}
