// popup
.popup_components,
.popup_create_components {
	&.popup {
		width: 66rem;
		height: 52rem;
		gap: 0.5rem;
	}

	.components_game {
		border-bottom: 1px solid gray;
		padding-bottom: 3rem;

		&--flex {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&--title {
			text-align: center;
			font-weight: 500;
			padding: 0 0 0.5rem 0;
		}
		&--container {
			display: flex;
			column-gap: 1rem;
			align-items: center;
		}

		&--img {
			width: 16rem;
			height: 9rem;
			background-color: darkkhaki;

			&.selected {
				border: 3px solid var(--color-primary);
			}
		}
	}

	.components_standard--flex {
		margin: 1rem 0;
		gap: 0.5rem;
		display: grid;
		grid-template-rows: repeat(6, 1fr);
		grid-auto-flow: column;
		grid-auto-columns: 20rem;
		@include scrollbar-x;
	}

	.components_standard {
		.components_title--container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&--title {
			text-align: center;
			font-weight: 600;
			padding: 1rem 0 0.5rem 0;
		}

		&--add {
			position: absolute;
			top: 1rem;
			right: 0;
		}

		&--container {
			display: flex;
			column-gap: 0.5rem;
			align-items: center;
			user-select: none;
		}

		&--img {
			width: 5rem;
			height: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			&:empty {
				background-color: lightgray;
			}

			&.selected {
				outline: 3px solid var(--color-primary);
				outline-offset: -3px;
				border-radius: 6px;
			}
			.icon {
				height: 80%;
			}
		}

		&--name {
			@include text-overflow;
			width: 15rem;
			&.selected {
				font-weight: 600;
				color: var(--color-primary);
			}
		}
	}
}

.popup_create_components .popup_main {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto auto 1fr;

	.create-component {
		&_name {
			margin-bottom: 2rem;
		}

		&_scope {
			width: 28rem;
		}

		&_icon {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto 1fr;

			.form-label {
				grid-column: span 2;
			}
		}

		&_select {
			border-top: 1px solid var(--color-grey-very-light);
			font-weight: 500;
			margin-top: 1rem;
			grid-column: span 2;
		}

		&_items {
			grid-column: span 2;
			display: flex;
			flex-direction: column;
			max-height: 75%;
			flex-wrap: wrap;
		}
	}
}
