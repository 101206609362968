// Game-editor: Open de popup add action door op het plusje achter Actions te klikken

.add-action {
	position: absolute;
	top: 3rem;
	left: 0;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--color-grey-very-light);

	&_header {
		border-bottom: 1px solid var(--color-grey-very-light);
		height: 3.2rem;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		cursor: default;

		&-name {
			justify-self: center;
			font-weight: 500;
			color: inherit;
		}

		.icon--close {
			margin-right: 0.5rem;
		}
	}

	&_actions {
		padding: 0 1rem;
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		padding-top: 1rem;
		row-gap: 0.7rem;
	}

	&_action-container {
		display: flex;
		gap: 0.5rem;
		align-items: top;

		.icon {
			align-self: baseline;
			height: 2rem;
			fill: var(--color-grey-dark);
			stroke: var(--color-grey-dark);
		}

		.icon--stop-sound {
			height: 2.2rem;
			stroke-width: 0;
		}

		.icon--time-stop {
			fill: var(--color-grey-medium);
			stroke: var(--color-grey-medium);
		}
	}

	&_action-container:last-child {
		margin-top: 2rem;
		margin-bottom: 1rem;
		grid-column: 1 / -1;
	}
}
