.form {
	&_label {
		color: var(--color-grey-dark);
		font-size: 1.5rem;
		font-weight: 500;
		cursor: default;

		&-left {
			display: grid;
			grid-template-columns: 1fr auto;
			align-items: center;
			width: 28rem;

			.form_label {
				justify-self: end;
				margin-bottom: 0;
				margin-right: 1rem;
			}

			.form_field {
				justify-self: end;
			}
		}

		&-left--justify-left {
			display: grid;
			grid-template-columns: auto 1fr;
			align-items: center;

			.form_number-field {
				justify-self: start;
			}

			.form_label {
				margin-bottom: 0;
				margin-right: 1rem;
			}
		}
	}

	&_field {
		font-size: inherit;
		font-family: inherit;
		width: 28rem;
		height: 3.5rem;
		color: inherit;
		background-color: white;
		padding: 1rem;
		border: none;
		border-top: 1px solid var(--color-grey-very-light);
		border-left: 1px solid var(--color-grey-very-light);
		border-right: 1px solid var(--color-grey-very-light);
		border-bottom: 1px solid var(--color-primary);
		display: flex;
		align-items: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow-x: hidden;
		overflow-y: hidden;

		&:focus {
			outline: none;
		}

		&:is(textarea) {
			white-space: pre-wrap;
		}

		&--disabled,
		&:disabled {
			background: var(--color-grey-very-light);
			display: flex;
			column-gap: 1rem;
			align-items: center;
			cursor: default;
			border-bottom: 1px solid var(--color-grey-very-light);
		}

		&--medium {
			width: 16rem;
		}

		&--small {
			width: 10rem;
		}

		&--very-small {
			width: 7rem;
		}

		&-group {
			display: flex;
			column-gap: 2rem;
		}

		&.has-error {
			box-shadow: 0 0 0 2px var(--color-primary);
			// background-color: #fef3ed;
		}
	}

	&_number-field {
		position: relative;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		justify-self: end;

		.icon-container {
			flex-direction: column;
			width: 0;

			.icon {
				height: 1.5rem;
			}

			.icon--arrow-up {
				position: absolute;
				top: 0;
				right: 0.5rem;
				transform: rotate(180deg);
			}

			.icon--arrow-down {
				position: absolute;
				top: 2rem;
				right: 0.5rem;
			}
		}
	}

	&_time-field,
	&_timeWithSeconds-field {
		width: 12rem;
	}

	&_dropdown {
		position: relative;

		&.has-error {
			box-shadow: 0 0 0 2px var(--color-primary);
			background-color: #fef3ed;

			.form_field {
				box-shadow: none;
				border-bottom: 1px solid var(--color-grey-very-light);
			}
		}

		.icon--dropdown {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}

		&-container-in {
			height: auto;
			display: flex;
			align-items: center;
		}

		&-container-out {
			display: flex;
			position: relative;
			height: 100%;
			width: 100%;
			z-index: 99999;

			.form_field {
				border-bottom: 1px solid var(--color-grey-very-light);
				margin-bottom: 0;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow-x: hidden;
			}

			.icon--dropdown {
				transform: rotate(180deg);
				position: absolute;
				top: 0.6rem;
				right: 0.2rem;
			}
		}

		&-options {
			z-index: 9999;
			position: absolute;
			top: 3.5rem;
			left: 0;
			background-color: white;
			height: auto;
			width: 100%;
			border-left: 1px solid var(--color-grey-very-light);
			border-right: 1px solid var(--color-grey-very-light);
			border-bottom: 1px solid var(--color-primary);
			display: flex;
			flex-direction: column;
			@include scrollbar;

			.form_dropdown-option {
				padding-bottom: 0.5rem;
				padding: 0 1rem;
				display: flex;
				grid-template-columns: 1fr 1.5rem 1.5rem;
				column-gap: 0.5rem;
				cursor: pointer;

				.dropdown-option_label {
					flex-grow: 1;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow-x: hidden;
				}

				.icon-container {
					width: 1.6rem;
				}

				&:not(:hover) {
					.dropdown-option_edit-button,
					.dropdown-option_delete-button {
						display: none;
					}
				}
			}

			&:not(:hover) .form_dropdown-option--selected,
			.form_dropdown-option:hover {
				background-color: var(--color-grey-very-light);

				.icon {
					fill: var(--color-grey-dark);
					stroke: var(--color-grey-dark);
				}
			}

			&--medium {
				width: 16rem;
			}

			&--small {
				width: 10rem;
			}

			&--very-small {
				width: 7rem;
			}

			.btn {
				text-transform: none;
				font-size: 1.4rem;
				padding: 1rem;
				margin: 0.21rem;
				line-height: 1;
				align-self: flex-end;
			}
		}

		&-selected {
			display: flex;
			cursor: pointer;

			&--empty {
				font-style: italic;
			}

			p {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow-x: hidden;
			}

			.icon--dropdown {
				position: absolute;
				top: 0.5rem;
				right: 1.5rem;
			}
		}

		&--icon {
			position: relative;
			display: flex;

			.icon {
				position: absolute;
				top: 0;
				right: 0.5rem;
				height: 2rem;
			}
		}
	}

	&_checkbox {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
		color: inherit;
		accent-color: var(--color-grey-medium);
		margin-top: 1.5rem;
		grid-column: 1 / -1;

		&--small {
			font-size: 1.4rem;

			.icon--checkbox {
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		&--medium {
			font-size: 1.6rem;

			.icon--checkbox {
				width: 2rem;
				height: 2rem;
			}
		}

		&-group {
			margin-top: 0.5rem;
			grid-column: 1 / -1;
			display: flex;
			flex-direction: column;
			row-gap: 0.5rem;

			.form_checkbox {
				margin-top: 0;
			}
		}

		input {
			cursor: pointer;
		}

		.checkbox_text {
			cursor: default;
			display: flex;
			gap: 0.5rem;
		}
	}

	&_radio-buttons {
		display: flex;
		column-gap: 2rem;
		margin-top: 0.5rem;
	}

	&_radio-button {
		position: relative;
		display: flex;
		align-items: center;

		.radio-button_input {
			display: none;
		}

		.radio-button_label {
			cursor: pointer;
			position: relative;
			padding-left: 3.8rem;
		}

		.radio-button_button {
			height: 2rem;
			width: 2rem;
			border: 1px solid var(--color-grey-light);
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			left: 1rem;
			top: 0;

			&::after {
				content: '';
				display: block;
				height: 1rem;
				width: 1rem;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: var(--color-primary);
				opacity: 0;
				transition: opacity 0.2s;
			}
		}
		.radio-button_input:checked ~ .radio-button_label .radio-button_button::after {
			opacity: 1;
		}
	}

	&_color-picker {
		.colorpicker {
			display: flex;
		}
	}

	.text-container {
		display: flex;
		align-items: center;
	}
}

.autogrow-wrapper {
	// idea: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
	display: grid;

	.autogrow-shadow {
		white-space: pre-wrap;
		visibility: hidden;
	}

	& > textarea {
		resize: none;
		overflow: hidden;
	}
	& > textarea,
	.autogrow-shadow {
		// Place on top of each other
		grid-area: 1 / 1 / 2 / 2;
		height: unset !important;
	}
}
