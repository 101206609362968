.sidebar {
	@include height_page-content;
	background-color: white;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;

	&_header {
		height: 4rem;
		background-color: var(--color-primary);
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.8rem;
		cursor: default;
	}
}
