.story-editor {
	&_sidebar {
		display: grid;
		grid-template-rows: auto 1fr;

		&_edit-container {
			display: grid;
			grid-template-columns: 2fr 1fr 1fr 1fr;
			border-bottom: 1px solid var(--color-grey-very-light);
			height: 4rem;

			.icon--copy,
			.icon--expand {
				pointer-events: none;
				fill: var(--color-grey-medium);
				stroke: var(--color-grey-medium);
				cursor: default;
			}

			.icon--expand {
				display: none;
			}

			.dropdownbutton-options {
				padding: 1rem 0.1rem;
				display: flex;
				flex-direction: column;
				row-gap: 0.5rem;
				width: 12rem;
				right: -8rem;

				.dropdownbutton-option:hover {
					padding: 0 1rem;

					&:hover {
						background-color: white;
						color: var(--color-grey-very-dark);
					}
				}
			}
		}

		&_storyline {
			margin-left: 0.5rem;
			@include scrollbar_effect;
			@include height_storyline;

			.game_name--name {
				cursor: pointer;
			}
		}

		.storyline {
			margin-top: 2rem;
			column-gap: 0.1rem;
			line-height: 2.6rem;
			font-size: 1.5rem;
		}
	}

	&_main {
		@include height_page-content;
		display: grid;
		grid-template-columns: 60rem 1fr;
	}

	&_story {
		@include height_page-content;
		padding: 0 1rem;
		display: grid;
		grid-template-rows: 4rem 1fr;
		overflow-x: hidden;
		overflow-y: hidden;

		&_header {
			justify-self: left;
			background-color: var(--color-grey-very-dark);
			color: white;
			text-align: center;
			align-self: center;
			font-size: 2rem;

			.color-line {
				margin-top: -0.5rem;
			}

			.wysiwyg-bar_icon--marker {
				margin-top: -0.2rem;
			}
		}

		&_background {
			@include max-height_screen;
			min-height: 30rem;
			height: fit-content;
			width: 100%;
			border: 1px solid white;
			background-color: white;
			justify-self: center;
			display: grid;
			grid-template-rows: minmax(min-content, max-content) minmax(0, 1fr) 5rem;
			padding: 1rem 1rem 0 1rem;

			.story_header {
				display: grid;
				grid-template-columns: 1fr auto;
				align-items: center;
				padding-bottom: 1rem;

				.story_title {
					text-align: center;
					width: 100%;
					font-size: 1.8rem;
					font-weight: 600;
				}

				button:hover .icon {
					fill: var(--color-grey-very-dark);
					stroke: var(--color-grey-very-dark);
				}

				.story_character {
					align-self: flex-start;

					.icon--character {
						fill: var(--color-primary);
						stroke: var(--color-primary);
					}

					.icon--add {
						fill: var(--color-primary);
						stroke: var(--color-primary);
						height: 1.2rem;
						align-self: baseline;
					}
				}
			}

			.story_text {
				margin-bottom: 0.5rem;

				&.ql-container {
					// Overschrijf font settings van Quill
					font-size: inherit;
					font-family: inherit;
					@include scrollbar;
					scrollbar-gutter: stable;

					.ql-editor {
						@include scrollbar;
						scrollbar-gutter: stable;
					}
				}

				p {
					margin: 0;
				}
			}

			.story_button-container {
				align-self: center;
				justify-self: center;
				display: flex;
				gap: 1rem;

				.icon {
					height: 1.5rem;
					stroke-width: 0;
					&--direction-up {
						margin-bottom: 0.2rem;
					}
					&--direction-down {
						margin-top: 0.2rem;
					}
				}
			}
		}
	}

	&_characteristics {
		@include max-height_page-content;
		display: grid;
		grid-template-rows: 4rem 1fr;
		overflow-x: hidden;
		color: white;
		overflow: hidden;

		&_tabs {
			width: calc(100vw - 85rem);
			min-width: 51rem;
			display: grid;
			grid-template-rows: 4rem minmax(0, 1fr);
			height: 100%;

			.tab_container {
				display: flex;
				column-gap: 0.2rem;
				flex-wrap: wrap; // make sure it wraps
				min-width: max-content; // make sure all tabs fit on small screen
			}

			.tab {
				order: 1; // Put the labels first
				display: block;
				padding: 1rem 2rem;
				margin-right: 0.2rem;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
				cursor: pointer;
				background: var(--color-grey-very-light);
				color: var(--color-grey-very-dark);
				font-weight: 500;
				text-align: center;
				transition: background ease 0.2s;
				height: 4rem;
			}

			.last-label {
				margin-right: 0;
			}

			.characteristics_content {
				order: 99; // Put the tabs last
				flex-grow: 1;
				// width: calc(100vw - 85rem);
				min-width: 51rem;
				max-width: 90rem;
				display: none;
				// background: #fff;
				@include height_screen;
			}

			input[type='radio'] {
				display: none;
			}

			input[type='radio']:checked + label,
			.tab--selected {
				background: var(--color-primary);
				color: white;
			}

			input[type='radio']:checked + label + .characteristics_content {
				display: block;
				color: black;
			}
		}
	}
}

.characteristics_content {
	.has-error {
		.form_field {
			border: 2px solid red;
			background-color: rgb(254, 235, 235);
		}

		.form_label {
			color: red;
		}
	}

	.gameplay_overview {
		@include scrollbar-both;
	}

	.character_overview,
	.gameplay_overview {
		padding: 1rem 2rem 0 2rem;
		height: 16rem;
		background-color: white;
		margin-bottom: 0.5rem;

		.impression-group {
			margin-top: 0.6rem;
		}

		.characters-group {
			@include scrollbar-x;
		}

		.characters-group,
		.impression-group {
			display: flex;
			column-gap: 1.2rem;

			.character-container,
			.impression-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding-top: 0.2rem;
			}

			.character_image {
				position: relative;
				height: 8rem;
				width: 8rem;
				background-color: var(--color-grey-very-light);
				border-radius: 0.5rem;
				display: flex;
				justify-content: center;

				&-container {
					width: 100%;
					height: 100%;
				}

				img {
					height: 8rem;
					width: 8rem;
					object-fit: cover;
					object-position: top;
					border-radius: 0.5rem;
				}

				&.active {
					box-shadow: 0px 0px 0px 2px #e67e22;

					.character_btn-container {
						position: absolute;
						bottom: -0.5rem;
						left: 50%;
						transform: translateX(-50%);
						display: flex;
						column-gap: 0.2rem;

						.image_delete {
							height: 2.5rem;
							width: 2.5rem;
							border-radius: 50%;
							background-color: var(--color-primary);

							.icon {
								height: 1.3rem;
								fill: white;
								stroke: white;
							}
						}

						.image_edit {
							height: 2.5rem;
							width: 2.5rem;
							border-radius: 50%;
							background-color: var(--color-primary);

							.icon {
								height: 1.3rem;
								fill: white;
								stroke: white;
							}
						}
					}
				}

				.icon--character {
					height: 4rem;

					&:hover {
						fill: var(--color-grey-medium);
						stroke: var(--color-grey-medium);
						cursor: grab;
					}
				}
			}

			.character_name {
				padding-top: 0.2rem;
				text-align: center;
				width: 9rem;
				height: 1.8rem;
				font-size: 1.1rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.impression-container {
				.image_delete {
					.icon {
						height: 1.8rem;
					}
				}
				.image_edit,
				.image_add {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-top: 2.5rem;
				}

				.impression_image {
					position: relative;
					height: 9rem;
					width: 16rem;
					background-color: var(--color-grey-very-light);
					border-radius: 0.5rem;
					border: 1px solid var(--color-grey-medium);
					display: flex;
					align-items: center;
					justify-content: center;

					&-container {
						height: 100%;
						width: 100%;
					}

					img {
						height: 9rem;
						width: 16rem;
						object-fit: cover;
						border-radius: 0.5rem;
					}

					.image_delete {
						position: absolute;
						bottom: -1rem;
						left: 50%;
						transform: translateX(-50%);
						height: 3rem;
						width: 3rem;
						border-radius: 50%;
						background-color: var(--color-primary);

						.icon {
							height: 1.6rem;
							fill: white;
							stroke: white;
						}
					}

					.image_edit {
						display: none;
					}

					.image_add {
						position: absolute;
						bottom: -1rem;
						left: 50%;
						transform: translateX(-50%);
						height: 3rem;
						width: 3rem;
						border-radius: 50%;
						background-color: var(--color-primary);

						.icon {
							height: 1.6rem;
							fill: white;
							stroke: white;
						}
					}

					.icon--image {
						height: 5rem;
						fill: var(--color-grey-light);
						stroke: var(--color-grey-light);
					}
				}
			}
		}

		.add-character {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0.5rem;
		}
	}

	.characteristics_description {
		background-color: white;
		padding: 1.5rem;

		&.tab_characters,
		&.tab_gameplay {
			height: calc(100vh - 36rem);
			@include scrollbar;
		}

		&.tab_objectives,
		&.tab_rewards,
		&.tab_timelines {
			height: calc(100vh - 19.5rem);
		}

		.character_forms-container {
			display: grid;
			grid-template-columns: 30rem 1fr 2.4rem;

			.form_number-field {
				width: 16rem;
			}
		}

		.character_textareas-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 2rem;
			margin: 2rem 0 0 0;

			textarea {
				@include scrollbar;
				width: 100%;
				height: calc(100vh - 49rem);
			}
		}

		.gameplay_textareas-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 2rem;
			margin: 0;

			textarea {
				@include scrollbar;
				width: 100%;
				height: calc(100vh - 42rem);
			}
		}

		.objectives_textareas-container {
			display: grid;
			grid-template-columns: 1fr;
			column-gap: 2rem;
			margin: 0 0 1rem 0;

			textarea {
				@include scrollbar;
				border: 1px solid var(--color-grey-very-light);
				width: 100%;
				height: calc(100vh - 23rem);
			}
		}
	}
}
