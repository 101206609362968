.dashboard {
	&_header {
		position: relative;
	}

	.btn_edit-game {
		position: absolute;
		top: -1rem;
		right: 1rem;
		font-size: 1.6rem;
		padding: 1rem 3.2rem;
		padding: 0.8rem 2.4rem;
		// display: none;

		&--blue {
			padding: 0.8rem 2rem;
			margin: 0 0.2rem;
		}
	}
}

.dashboard-start {
	.dashboard_widget-area {
		display: grid;
		grid-template-columns: minmax(66rem, 75rem) minmax(55rem, 100rem);
		column-gap: 2rem;
		// background-image: url(proefstuderen.jpg);
		// background-size: cover;
		// background-repeat: no-repeat;
		// background-position: center center;
		padding: 0 1rem 0 0;
		background-color: var(--color-grey-very-dark);
	}

	&_left {
		.dashboard-start_left-top {
			background-image: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.1)), url(placeholder_startscherm.png);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			&--layer {
				display: grid;
				grid-template-rows: 4rem 14rem auto 3rem;
				align-items: end;
				font-size: 1.6rem;
				color: white;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				height: 26.2rem;
				background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.1));
			}

			.dashboard-start_game-settings {
				justify-self: end;
				align-self: end;
				margin-right: 0.5rem;
				margin-top: 1rem;
				padding: 0.5rem;
				background-color: rgba(255, 255, 255, 0.596);
				border-radius: 50%;

				.icon {
					fill: var(--color-grey-very-dark);
					stroke: var(--color-grey-very-dark);
				}
			}

			.dashboard-start_text-container {
				display: grid;
				grid-template-rows: 1fr auto;
				align-items: end;
				width: 60%;
				height: 15rem;
				padding: 0 1rem 1rem 1rem;

				.dashboard-start_game-name {
					font-size: 2.4rem;
					padding-bottom: 0.5rem;
					margin-top: -2rem;
				}

				.dashboard-start_game-description {
					@include scrollbar;
					height: 100%;
				}
			}

			.dashboard-start_button-container {
				display: flex;
				padding-top: 2rem;
				column-gap: 1rem;
				padding: 1rem;

				.btn {
					padding: 0.4rem 3.2rem;
					width: 12rem;
				}

				.btn--orange {
					box-shadow: none;
				}

				.btn_trainer-dashboard {
					width: 20rem;
					margin-left: auto;
				}
			}
		}
		.dashboard-start_footer {
			display: grid;
			grid-template-columns: 1fr 10rem;
			align-content: center;
			padding: 0 1rem;
			color: white;
			height: 3rem;
			font-size: 1.4rem;
			background-color: rgba(28, 28, 28, 0.512);
			cursor: default;

			.footer_numbers {
				cursor: default;
			}

			.footer_last-edited {
				justify-self: end;
				cursor: default;
			}
		}

		.dashboard-start_games-group {
			display: grid;
			grid-template-rows: 15rem;
			grid-auto-rows: 15rem;
			// grid-template-columns: repeat(auto-fit, minmax(16rem, 19rem));
			grid-template-columns: repeat(auto-fit, 21rem);
			grid-auto-flow: row;
			gap: 1rem;
			padding-top: 2rem;
			// padding-left: 1rem;

			.dashboard-start_game-card {
				border: 3px solid white;
				display: grid;
				grid-template-rows: 3rem 1fr;
				// border-top-left-radius: 10px;
				// border-top-right-radius: 10px;
				background-color: white;
				box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
				cursor: pointer;

				&.selected {
					border: 3px solid var(--color-primary);
					background-color: var(--color-primary);
					color: white;
					font-weight: 500;
					font-size: 1.6rem;
				}

				.game-card_name {
					align-self: center;
					justify-self: center;
					height: 2rem;
				}

				.game-card_image {
					width: 100%;
					height: 100%;
					align-self: center;
					justify-self: center;
					background-image: url(placeholder_startscherm.png);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					background-color: white;
				}
			}
		}
	}

	&_right {
		.dashboard-start_widget-container {
			display: flex;
			flex-direction: column;
			row-gap: 1rem;

			.widget {
				.widget_header {
					border-top-left-radius: 12px;
					border-top-right-radius: 12px;
				}
			}

			.widget:not(:first-child) {
				.widget_header {
					border-radius: 0;
				}
			}
		}

		.widget {
			box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
			box-shadow: none;

			.widget_header {
				display: grid;
				grid-template-columns: 1fr auto;
				background-color: var(--color-widget-grey);
				color: white;
				font-weight: 500;
				text-align: center;
				height: 3.5rem;

				&--title {
					justify-self: center;
					padding-bottom: 1rem;
				}

				&--icon {
					margin-left: auto;

					.icon--tab {
						fill: white;
						stroke: white;
						stroke-width: 0;
						height: 2rem;
						margin-right: 1rem;
					}
				}
			}

			.widget_content {
				background-color: var(--color-widget-grey);
				color: white;
				border: none;
				color: var(--color-grey-very-light);

				.table tr:nth-child(even):not(.table_row--clickable:hover) {
					background-color: var(--color-widget-grey);
				}
			}
		}
		.dashboard-start_training-stats {
			display: flex;
			flex-direction: column;

			.training-stats_container {
				display: flex;
				flex-direction: column;
				padding-bottom: 2rem;

				.btn {
					padding: 0.4rem 3.2rem;
					justify-self: center;
					align-self: center;
					margin-top: 2rem;
				}
			}
		}

		.dashboard-start_game-stats {
			height: 26.2rem;

			.game-stats_played-games {
				padding: 1rem;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				height: 22.6rem;

				.played-games_total,
				.played-games_screens,
				.played-games_time {
					justify-self: center;
					align-self: center;
					display: flex;
					flex-direction: column;
					row-gap: 1rem;
					align-items: center;
					justify-content: center;
					height: 16rem;
					width: 16rem;
					border-radius: 50%;
					border: 1px solid var(--color-grey-very-light);
				}

				.played-games_number {
					font-size: 3.5rem;
					font-weight: 600;
					color: white;
				}

				.played-games_label {
					font-size: 1.2rem;
					color: white;
				}
			}

			.played-games_table {
				align-self: flex-start;
				justify-self: flex-start;
				display: flex;
				flex-direction: column;

				&-row {
					display: grid;
					grid-template-columns: 4rem 4rem;
				}
			}

			.game-stats_avarages {
				height: fit-content;
				border: 1px solid var(--color-grey-medium);
				padding: 1rem;
				max-width: 20rem;
			}
		}

		.dashboard-start_ai-stats {
			.ai-stats_container {
				display: flex;
				flex-direction: column;
				row-gap: 2rem;
			}

			.ai-stats_model {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 2rem;

				&--accuracy {
					justify-self: end;
				}

				&--accuracy,
				&--thumbs {
					align-self: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 16rem;
					width: 16rem;
					border-radius: 50%;
					border: 1px solid var(--color-grey-very-light);
				}

				&--thumbs {
					border-radius: 0;
					align-items: flex-start;
					border: none;
					row-gap: 1rem;

					.ai-stats_thumb--up,
					.ai-stats_thumb--down {
						display: flex;
						justify-content: center;
						column-gap: 1rem;
					}

					.icon {
						fill: var(--color-grey-very-light);
						stroke: var(--color-grey-very-light);
						stroke-width: 0;
						width: 3rem;
					}
				}

				.ai-stats_number {
					font-size: 6rem;
					font-weight: 600;
					color: white;
				}

				.ai-stats_label {
					font-size: 1.2rem;
					color: white;
				}
			}

			.ai-stats_input {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr 1fr;
				align-self: center;
				column-gap: 1rem;

				&--new,
				&--percentage,
				&--players,
				&--total {
					align-self: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 12rem;
					width: 12rem;
					border-radius: 50%;
					border: 1px solid var(--color-grey-very-light);
				}

				.ai-stats_number {
					font-size: 2.5rem;
					font-weight: 600;
					color: white;
				}

				.ai-stats_label {
					font-size: 1.2rem;
					color: white;
				}
			}
		}
	}
}

.dashboard_game-stats {
	display: none !important;
}

.game-settings_popup--game {
	.popup {
		width: 84vw;

		&_header {
			font-size: 1.8rem;
		}

		&_main {
			display: flex;
			justify-content: center;
			padding: 0 2rem;

			.content-container {
				@include scrollbar;
				height: fit-content;
				padding-top: 1rem;
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 2rem;
			}

			.widget_header {
				height: 4rem;
			}

			.game-settings_left {
				display: flex;
				flex-direction: column;
				row-gap: 2rem;

				.game-settings_data {
					width: 46rem;

					.game_delete-container {
						display: flex;
						column-gap: 1rem;
					}

					.icon--delete {
						width: 1.8rem;
						justify-self: center;
						align-self: center;
					}
				}
			}
			.game-settings_right {
				display: flex;
				flex-direction: column;
				row-gap: 2rem;

				.game-settings_menu {
					width: 46rem;
				}

				.game-settings_game-header {
					textarea {
						height: 11rem;
						width: 40rem;
						@include scrollbar;
					}
				}
			}

			.game-settings_app-icon {
				width: 46rem;
			}

			.game-settings_content {
				display: flex;
				flex-direction: column;
				padding: 2rem 3rem 3rem 3rem;
				border-right: 1px solid var(--color-primary-light);
				border-bottom: 1px solid var(--color-primary-light);
				border-left: 1px solid var(--color-primary-light);

				.game_slug,
				.game_password {
					margin-top: 3rem;
				}

				.form_checkbox {
					margin-top: 0.5rem;
					margin-left: 19rem;
				}

				.game-settings_url--title {
					margin-top: 3rem;
				}

				.game-settings_url {
					margin-top: 1rem;
					// margin-bottom: 3rem;
					font-style: italic;
					word-break: break-word;
					display: flex;
					column-gap: 0.8rem;

					span {
						color: var(--color-primary);
						font-weight: 600;
					}
				}

				.game_icon {
					.select-form_container {
						margin: 1.5rem 0;
					}
				}

				.game-settings_show_menu {
					margin-left: 0;
				}
			}
		}
	}

	.popup:nth-child(2) {
		width: 10rem;
	}

	.btn-container {
		margin-right: 3rem;
	}
}
