// Selecteren van een scherm, item, bestand o.i.d.
// Na het klikken op het pennetje verschijnt een popup (select-popup)

.select-form {
	&_container {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
		margin: 2rem 0.5rem 0.5rem 1rem;
		width: 27.8rem;

		.form_field {
			margin-bottom: 0;
			margin-left: 1rem;
			width: 26rem;
		}

		.form_checkbox {
			margin-left: 1rem;
		}
	}

	&_header {
		color: var(--color-primary);
		text-align: center;
		font-weight: 500;
		padding-bottom: 1rem;
		cursor: default;
	}

	&_edit {
		display: grid;
		grid-template-columns: auto 1fr auto;
		column-gap: 2rem;
		height: auto;
		margin-bottom: 1rem;

		.icon--video,
		video {
			height: 3.4rem;
		}

		&-name {
			align-self: end;
			font-size: 1.4rem;
			padding-bottom: 1.2rem;
			cursor: default;

			&--leeg {
				font-style: italic;
				cursor: default;
			}
		}

		&-icons {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-left: 1rem;

			.icon--go-to-screen,
			.icon--img,
			img {
				width: 5rem;
				height: 5rem;
				object-fit: cover;
				object-position: top;
			}

			.icon--play-sound,
			.icon--item {
				height: 4.5rem;
				margin-top: 0.5rem;
			}

			.circle_icon--edit {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 3rem;
				height: 3rem;
				background-color: var(--color-primary);
				border-radius: 50%;
				margin-top: -0.5rem;
				margin-bottom: 0.2rem;

				.icon--pen {
					height: 1.6rem;
					fill: white;
				}
			}
		}

		.icon--delete {
			grid-column: 3;
			grid-row: 1;
			align-self: end;
			height: 1.5rem;
			margin-bottom: 1.5rem;
			margin-right: 0.2rem;
		}
	}

	.btn {
		width: 10rem;
	}
}
