.widget_articles {
	.icon {
		height: 1.8rem;
	}

	.widget_header {
		display: grid;
		grid-template-columns: 1fr auto;
		padding-right: 2rem;

		&-title {
			justify-self: center;
		}

		.icon {
			fill: white;
			stroke: white;
			height: 1.8rem;
		}
	}

	td {
		vertical-align: middle;
	}

	.table {
		table-layout: fixed;

		&-container {
			padding: 0.5rem;
			cursor: pointer;
		}
		.column_created,
		.column_edited {
			width: 10rem;
		}
		.column_title {
			width: 30%;
		}
		.column_tags {
			width: 20%;
		}
		.column_icons {
			width: 10%;
		}
		.column_buttons {
			width: 20%;
		}
	}

	.article_buttons {
		margin: 0.2rem 0;

		.btn {
			font-size: 1.4rem;
			margin: 0 0.25rem;
			min-width: 7rem;
			padding: 0.2rem 0.7rem;
		}
	}
}

.add-article_popup {
	.popup {
		max-width: 95vw;
		max-height: 100vh;
		width: 85vw;
		// height: 95vh;
	}

	.popup_main {
		display: flex;
		flex-direction: column;
		row-gap: 2rem;

		.article_top-container {
			position: relative;
			display: flex;
			column-gap: 2rem;

			.form_field {
				width: 50rem;
			}

			.filemanager_link {
				position: absolute;
				bottom: 0;
				right: 1rem;
				color: var(--color-primary);
				text-decoration: underline;
			}
		}

		.add-article_content {
			display: grid;
			grid-template-columns: 1fr auto;
			column-gap: 0.5rem;
			height: 45rem;

			.add-article_html-container {
				min-height: 45rem;
				overflow-y: auto !important;
				overflow-x: hidden !important;
				// scrollbar-gutter: stable;
				&::-webkit-scrollbar {
					width: 0.5rem !important;
				}

				&::-webkit-scrollbar-track {
					background-color: var(--color-grey-very-light) !important;
					// border-radius: 100px;
				}

				&::-webkit-scrollbar-thumb {
					box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6) !important;
					// border-radius: 100px;
				}
			}

			.add-article_html {
				background-color: black;
				color: white;
				font-family: Menlo, Consolas, 'Courier New', Courier, monospace;
				tab-size: 4;

				min-height: 45rem;
				overflow-y: auto !important;
				overflow-x: hidden !important;
				// scrollbar-gutter: stable;
				&::-webkit-scrollbar {
					width: 0.5rem !important;
				}

				&::-webkit-scrollbar-track {
					background-color: var(--color-grey-very-light) !important;
					// border-radius: 100px;
				}

				&::-webkit-scrollbar-thumb {
					box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6) !important;
					// border-radius: 100px;
				}
			}

			.add-article_preview {
				width: 45rem;
				border: 1px solid var(--color-grey-light);
				@include scrollbar;
			}
		}
	}
}
