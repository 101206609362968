.edit-score-rule {
	& > .popup {
		width: 110rem;
		height: 90vh;
		row-gap: 0;
	}

	.popup_score-rule {
		display: grid;
		grid-template-columns: 36rem 1fr;
		padding: 0;
	}

	&_step {
		display: flex;
		column-gap: 1rem;

		.btn_number {
			flex: 0 0 3rem;
		}

		.btn--icon {
			height: 3rem;
			width: 3rem;

			.icon {
				height: 1.5rem;
			}
		}

		.form_dropdown-options {
			.form_dropdown-option {
				height: 2.5rem;
				display: flex;
				align-items: center;
				padding: 0 0.8rem;

				&--selected {
					margin: 0 0.2rem;
					padding: 0 0.6rem;
				}

				&:hover {
					margin: 0 0.2rem;
					padding: 0 0.6rem;
				}
			}
		}
	}

	&_left {
		background-color: var(--color-grey-very-light);
		padding: 2rem;
		height: 100%;
		border-bottom-left-radius: var(--border-radius-popup);

		.form_dropdown {
			margin-bottom: 2rem;
		}

		.edit-score-rule_description {
			margin-top: 3rem;
		}

		textarea {
			height: 28rem;
			width: 32rem;
			margin-bottom: 2rem;
			@include scrollbar;
		}
		textarea.form_field {
			margin-bottom: 0;
		}

		.form_field {
			background-color: white;
			border: 1px solid var(--color-grey-very-light);
		}
	}

	&_right {
		display: flex;
		flex-direction: column;
		column-gap: 1rem;
		padding: 2rem 2rem 0 2rem;
		overflow: hidden;
		position: relative;

		.score-rule_delete {
			margin-right: auto;
			color: blue;
			text-decoration: underline;
		}

		.btn-container {
			margin-top: auto;
		}

		.content-container {
			height: 100%;
			@include scrollbar-both;
			margin-bottom: 1rem;
		}

		.clickable-object_dropdown,
		.total-score_dropdown {
			width: 20rem;
			position: relative;

			.form_field {
				width: 20rem;
			}
		}

		.input_dropdown {
			width: 22rem;

			.form_field {
				width: 22rem;
			}
		}
		.form_number-field {
			align-self: center;
			margin-bottom: -1.3rem;

			&.multiple-objects {
				margin-bottom: -1.5rem;
			}
		}

		.form_label--small {
			font-size: 1rem;
			color: var(--color-grey-medium);
			text-align: end;
		}
	}

	&_triggers {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		.triggers_header {
			display: flex;
			column-gap: 1rem;

			.form_label {
				padding-right: 3rem;
				color: var(--color-primary);
				margin-top: 0.5rem;
				font-weight: 600;
			}

			.form_radio-button {
				margin-top: 0.7rem;
			}
		}

		.triggers_list {
			.trigger-container {
				display: flex;
				flex-direction: column;
				margin-bottom: 2rem;

				.number-field_container {
					display: flex;
					flex-direction: column;

					.form_label--small {
						margin-right: 1rem;
						margin-top: 1rem;
					}
				}
			}

			.trigger_select {
				display: flex;
				align-items: flex-start;

				&.trigger_clicks--total {
					align-items: center;

					.trigger_delete {
						margin-top: -1rem;
					}

					.clickable-object_dropdown {
						.trigger_delete {
							margin-top: 0;
						}
					}

					.trigger_max-clicks {
						// margin-top: 1.3rem;
					}

					.trigger_delete {
						margin-top: 0.1rem;
					}
				}

				.quiz-result {
					width: 22rem;
				}

				.form_dropdown,
				input {
					margin-right: 0.7rem;
				}

				.trigger_dropdown {
					width: 16rem;
				}

				.icon-container {
					.icon--dropdown {
						position: absolute;
						right: 0.5rem;
					}

					.icon--arrow-up {
						position: absolute;
						top: -1.9rem;
						right: 0.5rem;
					}

					.icon--arrow-down {
						position: absolute;
						top: 0.3rem;
						right: 0.5rem;
					}
				}

				.clickable-object_group {
					display: flex;
					flex-direction: column;
					row-gap: 0.5rem;

					.form_dropdown {
						width: 20rem;
					}
				}

				.trigger_max-clicks,
				.trigger_score-points {
					display: flex;
					flex-direction: column;

					.form_label {
						align-self: flex-end;
						margin-right: 0.7rem;
					}
				}

				.trigger_delete {
					justify-self: center;
					margin-left: 0.2rem;
					margin-top: 1rem;

					.icon {
						height: 1.5rem;
					}
				}

				.accolade_line {
					align-self: stretch;
					width: 0.5rem;
					border-top: 1px solid var(--color-grey-medium);
					border-right: 1px solid var(--color-grey-medium);
					border-bottom: 1px solid var(--color-grey-medium);
					margin-left: 1.2rem;
				}

				.accolade_arrow {
					margin-right: 1rem;
					align-self: stretch;

					.icon {
						transform: rotate(270deg);
						height: 1.2rem;
						margin-left: -0.4rem;
					}
				}

				.clickable-object_add {
					position: absolute;
					top: 3.4rem;
					right: 0;
					font-size: 1.2rem;
					color: blue;
					margin-right: 1.5rem;
				}

				.time-container {
					display: flex;
					flex-direction: column;
					margin-bottom: -1.3rem;
				}
			}
		}
	}

	&_effects {
		display: flex;
		flex-direction: column;
		width: 100%;

		> .form_label {
			color: var(--color-primary);
			margin-bottom: 1rem;
			margin-top: 0.5rem;
			font-weight: 600;
		}

		.effects_list {
			display: flex;
			flex-direction: column;
		}

		.effect_delete {
			margin-top: 1rem;
		}

		.effect-container_score,
		.effect-container_action {
			display: flex;
			flex-direction: column;
			margin-bottom: 2rem;

			.action_select {
				.effect_delete {
					margin-right: 2rem;
				}

				&_name {
					height: 100%;
					align-self: center;
					display: flex;
					align-items: center;
					column-gap: 0.5rem;

					.icon {
						height: 2rem;
					}
				}
			}

			.score_select,
			.action_select {
				display: flex;
				align-items: flex-start;
				column-gap: 0.7rem;

				.form_dropdown {
					width: 16rem;

					&-selected,
					.text-container,
					.form_field {
						width: 16rem;
					}
				}

				.effect_score-points {
					display: flex;
					flex-direction: column;

					.form_label {
						align-self: flex-end;
						margin-right: 0.7rem;
					}
				}

				.icon--delete {
					height: 1.6rem;
				}
			}

			.action_select {
				.btn {
					padding: 0.5rem 1.4rem;
					margin-left: 3rem;
					margin-right: 0.5rem;
				}
				&_name {
					color: var(--color-primary);
					align-self: flex-end;
					cursor: default;

					.icon {
						fill: var(--color-primary);
						stroke: var(--color-primary);
						cursor: default;
					}
				}
			}
		}

		.form_number-field {
			.form_label {
				font-size: 1rem;
				color: var(--color-grey-medium);
				text-align: end;
				padding-right: 1rem;
			}
		}

		.effect-container_feedback {
			display: flex;
			flex-direction: column;
			margin-bottom: 0.7rem;

			.feedback_select {
				display: flex;
				column-gap: 0.7rem;

				.icon--delete {
					height: 1.6rem;
				}
			}

			.wysiwyg-bar {
				margin-top: 1rem;
				background-color: var(--color-grey-very-dark);
				width: 95%;

				&_dropdown--style,
				.form_dropdown-container--out {
					width: 16rem;
					cursor: pointer;
				}

				&_dropdown--style {
					.form_field {
						border: 1px solid var(--color-grey-very-dark);
						margin-left: 0.2rem;
					}

					.form_dropdown-option {
						margin: 0;
						border-left: 1px solid white;

						&--selected {
							border-left: 1px solid var(--color-primary);
						}

						&:hover {
							padding: 0 0.5rem;
						}
					}
				}

				&_dropdown--align,
				.form_dropdown-container--out {
					width: 5.5rem;
				}
			}
			.feedback_text {
				border-right: 1px solid var(--color-grey-very-dark);
				border-bottom: 1px solid var(--color-grey-very-dark);
				border-left: 1px solid var(--color-grey-very-dark);
				margin-bottom: 0;
				width: 95%;
				height: 12rem;
				@include scrollbar;

				.ql-editor {
					padding: 1rem;
				}
			}
		}
	}
}

.score_select,
.trigger_select {
	ul {
		list-style: disc;

		:last-child {
			margin-bottom: 2rem;
		}
	}

	li {
		margin-left: 2rem;

		:last-child {
			margin-bottom: 1rem;
		}
	}
}

.trigger_add,
.effect_add {
	display: flex;
	align-items: center;
	justify-content: center;
	// margin-top: 1rem;

	.btn--icon {
		height: 3rem;
		width: 3rem;

		.icon {
			height: 1.5rem;
		}
	}
}

.line_orange {
	border-bottom: 1px solid var(--color-primary);
	margin: 2rem 0 1rem 0;
}

.popup_edit-action {
	&.popup {
		width: 40vw;
		height: 85vh;
	}

	.popup_header-name {
		display: flex;
		align-items: center;
		column-gap: 1rem;

		// .icon--action {
		// 	fill: var(--color-primary);
		// 	stroke: var(--color-primary);
		// }
	}

	.popup_main {
		display: flex;
		column-gap: 1rem;

		.score-rule_description {
			textarea {
				height: 20rem;
			}
		}

		.select-form_container {
			margin: 1rem 0;
		}

		.edit-action_right {
			.effect_settings {
				display: flex;
				flex-direction: column;

				.form_dropdown,
				.form_dropdown-selected {
					width: 28rem;

					.form_field {
						width: 28rem;
					}
				}
				.form_label-left {
					height: 4rem;
				}
			}
		}
	}
}
