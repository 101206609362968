.widget_sessions {
	.widget_header {
		display: grid;
		grid-template-columns: 1fr auto;
		padding-right: 2rem;

		&--title {
			justify-self: center;
		}

		.icon {
			fill: white;
			stroke: white;
		}
	}

	.widget_content {
		max-height: 30rem;
		@include scrollbar;

		.widget_sessions--intro {
			text-align: center;
			font-style: italic;
			padding-bottom: 0.5rem;
			cursor: default;
		}
	}
}

.popup_statistics--download {
	width: 50rem;

	.statistics-download_player-actions {
		label {
			font-size: initial;
		}
	}
}
