.contextmenu-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;

	.contextmenu-options {
		padding: 0.5rem 0;
		width: 16rem;
		position: absolute;
		color: white;
		background-color: var(--color-grey-very-dark);
		z-index: 9000;

		.contextmenu-option {
			display: flex;
			column-gap: 1rem;
			padding: 0 0.5rem;
			cursor: pointer;
			&:hover {
				color: var(--color-primary);
			}

			&--selected {
				border: 1px solid var(--color-grey-medium);
				border-radius: 0;
				background-color: rgba(211, 211, 211, 0.5);
			}
		}

		.icon-container {
			width: 2rem;
		}
	}
}
