.widget {
	&_organizations {
		.table-container {
			padding: 0.5rem;
			cursor: pointer;
		}
	}

	&_news--SA {
		grid-column-start: 5;
	}
}

.add-organization_popup {
	.popup {
		width: 120rem;
	}
}

.add-game_popup {
	.popup {
		width: 80rem;
	}
}

.add-organization_popup,
.add-game_popup {
	.popup {
		&_header {
			font-size: 1.8rem;
		}

		&_main {
			display: flex;
			justify-content: center;
			padding: 0 2rem;

			.content-container_organization {
				grid-template-columns: 34rem 34rem 34rem;
			}

			.content-container_game {
				grid-template-columns: 34rem 34rem;
			}

			.content-container {
				@include scrollbar;
				height: fit-content;
				display: grid;
				column-gap: 5rem;
				align-items: start;
				padding-top: 1rem;

				.widget_header {
					height: 4rem;
				}

				.widget_content {
					border: 1px solid var(--color-primary-light);
					display: flex;
					flex-direction: column;
					row-gap: 3rem;
					padding: 1rem 3rem 1rem 3rem;
				}

				.organization_notes,
				.game_notes {
					.form_field {
						height: 10rem;
						max-width: 28rem;
						@include scrollbar;
					}
				}

				.organization_center-container {
					display: flex;
					flex-direction: column;
					gap: 4.9rem;
				}

				.game_settings {
					.form_checkbox {
						margin-top: -2.5rem;
						align-self: end;
					}
				}

				.game_contact,
				.organization_admin,
				.organization_contact {
					.form_checkbox {
						margin-top: -0.5rem;
					}
				}
			}
		}

		.btn-container {
			margin-right: 3rem;
		}
	}
}
