.widget_users {
	cursor: default;

	td span {
		display: block;
	}

	&--name {
		max-width: 20rem;
		@include text-overflow;
	}

	&--role {
		max-width: 40rem;
		@include text-overflow;
	}

	&--game {
		max-width: 20rem;
		@include text-overflow;
	}

	&--active {
		max-width: 8rem;
		@include text-overflow;
	}
}
