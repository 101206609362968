html {
	/* //percentage of user's browser font-size: 1rem = 10px, 10px/16px = 62.5% */
	font-size: 62.5%;
	overflow-x: hidden;
	height: 100%;
}

b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

u {
	text-decoration: underline;
}

input[type='checkbox'] {
	all: revert;
}

input[type='radio'] {
	all: revert;
}

body {
	height: 100%;
}

button {
	cursor: pointer;
}

.hidden {
	display: none !important;
}
