.breadcrumbs {
	padding: 0 0.8rem;
	padding-top: 2rem;
	padding-bottom: 0.5rem;
	display: flex;
	column-gap: 1rem;
	align-items: center;
	height: 6rem;

	&_icon--home {
		width: 3.2rem;
		fill: var(--color-blue);
		cursor: pointer;

		&:hover {
			cursor: pointer;
			filter: brightness(0.8);
		}
	}

	&-container {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
		cursor: pointer;
	}

	&_text {
		color: white;
		&--number {
			margin-right: 0.5rem;
		}
	}

	&_haakje {
		color: white;
	}

	.btn-container {
		margin-left: auto;
	}
}
