.learning-article {
	&_card {
		background-color: white;
		cursor: pointer;

		&--image {
			object-fit: cover;
			aspect-ratio: 16 / 9;
		}

		&--text {
			padding: 0.5rem;
			height: 10rem;
			display: flex;
			flex-direction: column;

			.article_name {
				text-align: center;
				font-weight: 500;
				flex: 0 0 80%;
			}

			.article_time {
				text-align: end;
				flex: 0 0 10%;
			}
		}
	}

	&_popup {
		.popup {
			min-width: 45rem;
		}

		.popup_header {
			background-color: var(--color-grey-very-dark);
			border-top-right-radius: var(--border-radius-popup);
			border-top-left-radius: var(--border-radius-popup);
			border: 1px solid white;
			color: var(--color-grey-very-light);

			.icon {
				fill: var(--color-grey-light);
				stroke: var(--color-grey-light);
			}
		}
	}

	&_help {
		background-color: white;
		@include scrollbar;
		padding: 1rem;
	}
}

.learning-article_styles {
	p {
		margin-top: 0.5rem;
	}

	h1 {
		font-size: 1.8rem;
		font-weight: 600;
		text-align: center;
		padding-top: 0.5rem;
	}

	h2 {
		font-weight: 600;
		padding-top: 2rem;
	}

	ol {
		li {
			margin-left: 3rem;
			list-style-type: decimal;
		}
	}

	ul {
		li {
			margin-left: 3rem;
			list-style-type: disc;
		}
	}

	.image {
		display: flex;
		justify-content: center;
		margin-bottom: 2.5rem;
		margin-top: 1rem;

		img {
			border: 2px solid black;
			width: 65%;
			box-shadow: 10px 10px 0 0 var(--color-primary-light);
		}
	}

	.video {
		display: flex;
		justify-content: center;

		video {
			width: 90%;
			border: 2px solid black;
			box-shadow: 10px 10px 0 0 var(--color-grey-dark);
		}
	}
}
