.widget_played-games {
	grid-row: 1 / -1;
	display: grid;
	grid-template-rows: auto 1fr;

	.widget_content {
		padding: 1rem;
		height: 33rem;
	}

	.played-games_selection-container {
		display: flex;
		justify-content: center;
		gap: 8rem;

		.form_dropdown {
			position: relative;
		}

		.form_field {
			padding-right: 5rem;
		}

		.select-game {
			.form_field {
				width: fit-content;
				border: none;
			}
		}

		.select-year {
			.form_field {
				width: 14rem;
				padding-right: 5rem;
			}
		}
	}

	.played-games_stats-bars,
	.played-games_months,
	.stats-bar_started,
	.stats-bar_finished {
		display: grid;
		grid-template-columns: 5rem 3rem 1fr 5rem 10rem;
		cursor: default;
	}

	.played-games_stats-bars {
		margin-top: 2rem;
	}

	.played-games_table {
		margin-top: 1rem;
	}

	.stats-bar {
		&_y-label {
			writing-mode: tb-rl;
			transform: rotate(-180deg);
			justify-self: end;
			padding-top: 0.2rem;
			font-size: 1.4rem;
			font-weight: 500;
			margin-right: 1rem;
		}

		&_y-values {
			display: grid;
			padding-right: 1rem;

			.y-value {
				align-self: start;
				text-align: right;
				padding: 0 0 1.5rem 0;
			}
		}

		&_bars-container {
			display: flex;

			.stats-bar_bar-container {
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.stats-bar_bar {
					width: 50%;
					background-color: #e16123;
					background-color: var(--color-primary);
					align-self: center;
					justify-content: center;
				}
			}
		}

		&_months-container {
			grid-column: 3;
			display: grid;
			grid-template-columns: repeat(12, 2fr);
			justify-items: center;
			color: 1rem;
			font-size: 1.2rem;
			font-weight: 500;
		}

		&_table--labels {
			grid-column: 1 / span 2;
		}

		&_started,
		&_finished {
			align-items: center;

			&--label {
				grid-column: 1 / span 2;
				font-size: 1.4rem;
				font-weight: 500;
			}

			&--numbers-container {
				display: grid;
				grid-template-columns: repeat(12, 2fr);
				justify-items: center;
				padding: 0.5rem 0;
			}
		}

		&_started--numbers-container {
			border: 1px solid var(--color-grey-very-light);
		}

		&_finished--numbers-container {
			border-left: 1px solid var(--color-grey-very-light);
			border-right: 1px solid var(--color-grey-very-light);
			border-bottom: 1px solid var(--color-grey-very-light);
		}

		&_total {
			text-align: right;
			padding-right: 1rem;
			font-weight: 600;
			align-self: center;

			&--label {
				padding-left: 1rem;
				font-size: 1.4rem;
				font-weight: 500;
			}
		}
	}
}
