.page {
	height: 100vh;
	width: 100%;
	display: grid;
	grid-template-rows: auto auto minmax(0, 1fr) auto;
	background-color: var(--color-grey-very-dark);
	@include scrollbar-both;

	&_content {
		position: relative;
		@include height_page-content;
		display: grid;
		grid-template-columns: 24rem 1fr;
		padding: 0 0.5rem;
	}
}
