.widget_games {
	grid-column-start: 4;
	margin-bottom: 4rem;
	cursor: default;

	td {
		vertical-align: middle;
		padding-top: 0.2rem;
	}

	.icon {
		height: 1.6rem;
	}

	.popup {
		min-width: 45rem;
		width: 45rem;
	}
}
