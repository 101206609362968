// voor dashboards superadmin, admin en game

.widget {
	width: 100%;
	font-size: 1.5rem;
	box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
	height: fit-content;

	&_header {
		height: 3rem;
		background-color: var(--color-primary);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		cursor: default;
	}

	&_content {
		min-height: 5rem;
		background-color: #fff;
		padding: 0.5rem;
		border-right: 1px solid var(--color-grey-very-light);
		border-bottom: 1px solid var(--color-grey-very-light);
		border-left: 1px solid var(--color-grey-very-light);
	}

	&--1 {
		grid-column-end: span 1;
	}
	&--2 {
		grid-column-end: span 2;
	}
	&--3 {
		grid-column-end: span 3;
	}
	&--4 {
		grid-column-end: span 4;
	}
	&--5 {
		grid-column-end: span 5;
	}
}
.dashboard_widget-area {
	.column-group--span-2 {
		grid-column-end: span 2;
	}

	.column-group--span-3 {
		grid-column-end: span 3;
	}
}
