.page-not-found {
	background-image: url(404background5.jpg);
	background-size: cover;
}

.page-not-found_container {
	display: flex;
	flex-direction: column;
	margin-left: 25%;
	margin-top: 10%;
	width: 60rem;
	height: min-content;
	background-color: rgba(255, 255, 255, 0.421);
	color: rgb(48, 48, 48);
	text-align: center;
	padding: 2rem;
}

.page-not-found_number {
	font-weight: 700;
	font-size: 6rem;
	margin: 5rem;
}

.page-not-found_text {
	font-size: 4rem;
	margin: 0;
}
