//preview
.score_build {
	font-size: 1em;

	&.popup {
		width: 20em;

		.popup_header {
			height: 1.8em;

			&-name {
				margin-left: 3em;
			}

			.icon--close {
				height: 2em;
				margin-right: 0.5em;
			}
		}
	}

	.popup_main {
		dl {
			display: grid;
			grid-template-columns: auto auto;
		}
	}
}
