body {
	font-family: var(--font-default);
	font-size: 1.5rem;
	line-height: 1.5;
	font-weight: 400;
	color: var(--color-grey-very-dark);
}

.link {
	font-size: 1.2rem;
	color: blue;
}

.right {
	text-align: end;
}
