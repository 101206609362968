:root {
  --font-default: "Roboto", sans-serif;
  --color-primary: #e67e22;
  --color-primary-light: #f8d3c0;
  --color-primary-very-light: #feece3;
  --color-item-select: #612103;
  --color-primary-dark: #9a4f0e;
  --color-blue: #36d2da;
  --color-blue-light: #bae5e7;
  --color-yellow: #ffd56d;
  --color-yellow-light: #f7e6bb;
  --color-green: #41bd55;
  --color-green-light: #7be98d;
  --color-grey-very-very-light: #f6f6f6;
  --color-grey-very-light: #ebe8e8;
  --color-grey-transparant: rgba(235, 232, 232, 0.5);
  --color-grey-light: #c4c4c4;
  --color-grey-medium: #8b8686;
  --color-grey-dark: #626161;
  --color-grey-very-dark: #3d3c3c;
  --border-radius-popup: 15px;
  --color-textarea-transparant-black: #3d3c3cc1;
  --color-textarea-transparant-white: #ffffffa8;
  --color-widget-grey: #3d3c3c;
  --color-widget-grey: #484747;
  --color-btn-blue: var(--color-blue);
  --color-btn-green: #009378;
  --color-btn-grey: var(--color-grey-very-light);
  --color-btn-orange: var(--color-primary);
  --color-btn-red: rgb(233, 86, 86);
  --color-btn-yellow: var(--color-yellow);
  --color-btn-dark: var(--color-very-dark);
  --color-btn-green-disabled: #c7d1d0;
  --color-btn-blue-disabled: #cde9ea;
  --color-btn-grey-disabled: #f0f0f0;
  --color-btn-orange-disabled: #feece3;
  --color-btn-red-disabled: rgb(235, 208, 208);
  --color-btn-yellow-disabled: #f1ecdd;
  --color-btn-dark-disabled: var(--color-grey);
  --color-failure: rgb(249, 207, 207);
  --color-success: #c9fcf7;
}

/***
    The new CSS reset - version 1.7.3 (last updated 7.8.2022)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable=true]) {
  -webkit-user-drag: element;
}

html {
  /* //percentage of user's browser font-size: 1rem = 10px, 10px/16px = 62.5% */
  font-size: 62.5%;
  overflow-x: hidden;
  height: 100%;
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

input[type=checkbox] {
  all: revert;
}

input[type=radio] {
  all: revert;
}

body {
  height: 100%;
}

button {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

body {
  font-family: var(--font-default);
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--color-grey-very-dark);
}

.link {
  font-size: 1.2rem;
  color: blue;
}

.right {
  text-align: end;
}

.action-details {
  width: 30rem;
  max-height: calc(100vh - 17rem);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9000;
  scrollbar-gutter: stable;
  position: absolute;
  bottom: 0;
  right: 31rem;
  border: 1px solid var(--color-grey-light);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}
.action-details::-webkit-scrollbar {
  width: 0.5rem;
}
.action-details::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.action-details::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.action-details_header {
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 3.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: default;
}
.action-details_header-name {
  justify-self: center;
  font-weight: 500;
  color: inherit;
  padding-left: 3rem;
}
.action-details_header .icon--close {
  margin-right: 0.5rem;
}
.action-details_settings {
  background-color: white;
}
.action-details_settings_edit-container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
}
.action-details_settings_edit-container .icon--copy,
.action-details_settings_edit-container .icon--delete {
  padding-right: 1rem;
}
.action-details_settings .settings_tag,
.action-details_settings .settings_timeline {
  padding-left: 1rem;
  margin-bottom: 10rem;
}
.action-details_settings .settings_tag .form_dropdown,
.action-details_settings .settings_timeline .form_dropdown {
  width: 28rem;
}
.action-details_settings .settings_trigger {
  padding: 1rem;
}
.action-details_settings .settings_url {
  padding-left: 1rem;
}
.action-details_settings .form_checkbox {
  padding-left: 1rem;
}
.action-details .select-form_container {
  margin-top: 0;
}
.action-details .edit-name {
  padding-left: 1rem;
}

.effect {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 1rem 0 1rem;
}
.effect_header {
  color: var(--color-primary);
  font-weight: 500;
  margin: 2rem 0 1rem 0;
  text-align: center;
  cursor: default;
}
.effect_settings {
  position: relative;
  align-self: flex-start;
  margin: 1rem;
}
.effect_settings .form_field {
  margin-left: 0;
  margin-bottom: 1rem;
  column-gap: 1rem;
}
.effect_settings .form_dropdown-option {
  display: flex;
  column-gap: 1rem;
  margin: 0.5rem 0;
}
.effect_settings .form_label {
  margin-right: 1rem;
  margin-left: 0;
}
.effect_settings .form_label-left {
  margin-right: 0;
}
.effect_settings .form_label-left {
  width: 26rem;
}
.effect_settings .settings_speed .form_field--small,
.effect_settings .settings_smoothness .form_field--small,
.effect_settings .settings_duration .form_field--small,
.effect_settings .settings_delay .form_field--small {
  width: 12rem;
}
.effect_icon--edit {
  position: absolute;
  top: 0;
  right: 0;
}
.effect_icon--edit .icon {
  height: 2rem;
}
.effect_direction .icon-arrow--left {
  rotate: -180deg;
}
.effect_direction .icon-arrow--up {
  rotate: 270deg;
}
.effect_direction .icon-arrow--down {
  rotate: 90deg;
}

.add-action {
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.add-action_header {
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 3.2rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: default;
}
.add-action_header-name {
  justify-self: center;
  font-weight: 500;
  color: inherit;
}
.add-action_header .icon--close {
  margin-right: 0.5rem;
}
.add-action_actions {
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding-top: 1rem;
  row-gap: 0.7rem;
}
.add-action_action-container {
  display: flex;
  gap: 0.5rem;
  align-items: top;
}
.add-action_action-container .icon {
  align-self: baseline;
  height: 2rem;
  fill: var(--color-grey-dark);
  stroke: var(--color-grey-dark);
}
.add-action_action-container .icon--stop-sound {
  height: 2.2rem;
  stroke-width: 0;
}
.add-action_action-container .icon--time-stop {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}
.add-action_action-container:last-child {
  margin-top: 2rem;
  margin-bottom: 1rem;
  grid-column: 1/-1;
}

.add-item .edit-name {
  width: 28rem;
  margin-left: 2rem;
  margin-top: 1rem;
  margin-right: 5rem;
}

.edit-score-rule > .popup {
  width: 110rem;
  height: 90vh;
  row-gap: 0;
}
.edit-score-rule .popup_score-rule {
  display: grid;
  grid-template-columns: 36rem 1fr;
  padding: 0;
}
.edit-score-rule_step {
  display: flex;
  column-gap: 1rem;
}
.edit-score-rule_step .btn_number {
  flex: 0 0 3rem;
}
.edit-score-rule_step .btn--icon {
  height: 3rem;
  width: 3rem;
}
.edit-score-rule_step .btn--icon .icon {
  height: 1.5rem;
}
.edit-score-rule_step .form_dropdown-options .form_dropdown-option {
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
}
.edit-score-rule_step .form_dropdown-options .form_dropdown-option--selected {
  margin: 0 0.2rem;
  padding: 0 0.6rem;
}
.edit-score-rule_step .form_dropdown-options .form_dropdown-option:hover {
  margin: 0 0.2rem;
  padding: 0 0.6rem;
}
.edit-score-rule_left {
  background-color: var(--color-grey-very-light);
  padding: 2rem;
  height: 100%;
  border-bottom-left-radius: var(--border-radius-popup);
}
.edit-score-rule_left .form_dropdown {
  margin-bottom: 2rem;
}
.edit-score-rule_left .edit-score-rule_description {
  margin-top: 3rem;
}
.edit-score-rule_left textarea {
  height: 28rem;
  width: 32rem;
  margin-bottom: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-score-rule_left textarea::-webkit-scrollbar {
  width: 0.5rem;
}
.edit-score-rule_left textarea::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.edit-score-rule_left textarea::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.edit-score-rule_left textarea.form_field {
  margin-bottom: 0;
}
.edit-score-rule_left .form_field {
  background-color: white;
  border: 1px solid var(--color-grey-very-light);
}
.edit-score-rule_right {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  padding: 2rem 2rem 0 2rem;
  overflow: hidden;
  position: relative;
}
.edit-score-rule_right .score-rule_delete {
  margin-right: auto;
  color: blue;
  text-decoration: underline;
}
.edit-score-rule_right .btn-container {
  margin-top: auto;
}
.edit-score-rule_right .content-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 1rem;
}
.edit-score-rule_right .content-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.edit-score-rule_right .content-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.edit-score-rule_right .content-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.edit-score-rule_right .clickable-object_dropdown,
.edit-score-rule_right .total-score_dropdown {
  width: 20rem;
  position: relative;
}
.edit-score-rule_right .clickable-object_dropdown .form_field,
.edit-score-rule_right .total-score_dropdown .form_field {
  width: 20rem;
}
.edit-score-rule_right .input_dropdown {
  width: 22rem;
}
.edit-score-rule_right .input_dropdown .form_field {
  width: 22rem;
}
.edit-score-rule_right .form_number-field {
  align-self: center;
  margin-bottom: -1.3rem;
}
.edit-score-rule_right .form_number-field.multiple-objects {
  margin-bottom: -1.5rem;
}
.edit-score-rule_right .form_label--small {
  font-size: 1rem;
  color: var(--color-grey-medium);
  text-align: end;
}
.edit-score-rule_triggers {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.edit-score-rule_triggers .triggers_header {
  display: flex;
  column-gap: 1rem;
}
.edit-score-rule_triggers .triggers_header .form_label {
  padding-right: 3rem;
  color: var(--color-primary);
  margin-top: 0.5rem;
  font-weight: 600;
}
.edit-score-rule_triggers .triggers_header .form_radio-button {
  margin-top: 0.7rem;
}
.edit-score-rule_triggers .triggers_list .trigger-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.edit-score-rule_triggers .triggers_list .trigger-container .number-field_container {
  display: flex;
  flex-direction: column;
}
.edit-score-rule_triggers .triggers_list .trigger-container .number-field_container .form_label--small {
  margin-right: 1rem;
  margin-top: 1rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select {
  display: flex;
  align-items: flex-start;
}
.edit-score-rule_triggers .triggers_list .trigger_select.trigger_clicks--total {
  align-items: center;
}
.edit-score-rule_triggers .triggers_list .trigger_select.trigger_clicks--total .trigger_delete {
  margin-top: -1rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select.trigger_clicks--total .clickable-object_dropdown .trigger_delete {
  margin-top: 0;
}
.edit-score-rule_triggers .triggers_list .trigger_select.trigger_clicks--total .trigger_delete {
  margin-top: 0.1rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .quiz-result {
  width: 22rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .form_dropdown,
.edit-score-rule_triggers .triggers_list .trigger_select input {
  margin-right: 0.7rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_dropdown {
  width: 16rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .icon-container .icon--dropdown {
  position: absolute;
  right: 0.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .icon-container .icon--arrow-up {
  position: absolute;
  top: -1.9rem;
  right: 0.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .icon-container .icon--arrow-down {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .clickable-object_group {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .clickable-object_group .form_dropdown {
  width: 20rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_max-clicks,
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_score-points {
  display: flex;
  flex-direction: column;
}
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_max-clicks .form_label,
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_score-points .form_label {
  align-self: flex-end;
  margin-right: 0.7rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_delete {
  justify-self: center;
  margin-left: 0.2rem;
  margin-top: 1rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .trigger_delete .icon {
  height: 1.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .accolade_line {
  align-self: stretch;
  width: 0.5rem;
  border-top: 1px solid var(--color-grey-medium);
  border-right: 1px solid var(--color-grey-medium);
  border-bottom: 1px solid var(--color-grey-medium);
  margin-left: 1.2rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .accolade_arrow {
  margin-right: 1rem;
  align-self: stretch;
}
.edit-score-rule_triggers .triggers_list .trigger_select .accolade_arrow .icon {
  transform: rotate(270deg);
  height: 1.2rem;
  margin-left: -0.4rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .clickable-object_add {
  position: absolute;
  top: 3.4rem;
  right: 0;
  font-size: 1.2rem;
  color: blue;
  margin-right: 1.5rem;
}
.edit-score-rule_triggers .triggers_list .trigger_select .time-container {
  display: flex;
  flex-direction: column;
  margin-bottom: -1.3rem;
}
.edit-score-rule_effects {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.edit-score-rule_effects > .form_label {
  color: var(--color-primary);
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  font-weight: 600;
}
.edit-score-rule_effects .effects_list {
  display: flex;
  flex-direction: column;
}
.edit-score-rule_effects .effect_delete {
  margin-top: 1rem;
}
.edit-score-rule_effects .effect-container_score,
.edit-score-rule_effects .effect-container_action {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.edit-score-rule_effects .effect-container_score .action_select .effect_delete,
.edit-score-rule_effects .effect-container_action .action_select .effect_delete {
  margin-right: 2rem;
}
.edit-score-rule_effects .effect-container_score .action_select_name,
.edit-score-rule_effects .effect-container_action .action_select_name {
  height: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.edit-score-rule_effects .effect-container_score .action_select_name .icon,
.edit-score-rule_effects .effect-container_action .action_select_name .icon {
  height: 2rem;
}
.edit-score-rule_effects .effect-container_score .score_select,
.edit-score-rule_effects .effect-container_score .action_select,
.edit-score-rule_effects .effect-container_action .score_select,
.edit-score-rule_effects .effect-container_action .action_select {
  display: flex;
  align-items: flex-start;
  column-gap: 0.7rem;
}
.edit-score-rule_effects .effect-container_score .score_select .form_dropdown,
.edit-score-rule_effects .effect-container_score .action_select .form_dropdown,
.edit-score-rule_effects .effect-container_action .score_select .form_dropdown,
.edit-score-rule_effects .effect-container_action .action_select .form_dropdown {
  width: 16rem;
}
.edit-score-rule_effects .effect-container_score .score_select .form_dropdown-selected,
.edit-score-rule_effects .effect-container_score .score_select .form_dropdown .text-container,
.edit-score-rule_effects .effect-container_score .score_select .form_dropdown .form_field,
.edit-score-rule_effects .effect-container_score .action_select .form_dropdown-selected,
.edit-score-rule_effects .effect-container_score .action_select .form_dropdown .text-container,
.edit-score-rule_effects .effect-container_score .action_select .form_dropdown .form_field,
.edit-score-rule_effects .effect-container_action .score_select .form_dropdown-selected,
.edit-score-rule_effects .effect-container_action .score_select .form_dropdown .text-container,
.edit-score-rule_effects .effect-container_action .score_select .form_dropdown .form_field,
.edit-score-rule_effects .effect-container_action .action_select .form_dropdown-selected,
.edit-score-rule_effects .effect-container_action .action_select .form_dropdown .text-container,
.edit-score-rule_effects .effect-container_action .action_select .form_dropdown .form_field {
  width: 16rem;
}
.edit-score-rule_effects .effect-container_score .score_select .effect_score-points,
.edit-score-rule_effects .effect-container_score .action_select .effect_score-points,
.edit-score-rule_effects .effect-container_action .score_select .effect_score-points,
.edit-score-rule_effects .effect-container_action .action_select .effect_score-points {
  display: flex;
  flex-direction: column;
}
.edit-score-rule_effects .effect-container_score .score_select .effect_score-points .form_label,
.edit-score-rule_effects .effect-container_score .action_select .effect_score-points .form_label,
.edit-score-rule_effects .effect-container_action .score_select .effect_score-points .form_label,
.edit-score-rule_effects .effect-container_action .action_select .effect_score-points .form_label {
  align-self: flex-end;
  margin-right: 0.7rem;
}
.edit-score-rule_effects .effect-container_score .score_select .icon--delete,
.edit-score-rule_effects .effect-container_score .action_select .icon--delete,
.edit-score-rule_effects .effect-container_action .score_select .icon--delete,
.edit-score-rule_effects .effect-container_action .action_select .icon--delete {
  height: 1.6rem;
}
.edit-score-rule_effects .effect-container_score .action_select .btn,
.edit-score-rule_effects .effect-container_action .action_select .btn {
  padding: 0.5rem 1.4rem;
  margin-left: 3rem;
  margin-right: 0.5rem;
}
.edit-score-rule_effects .effect-container_score .action_select_name,
.edit-score-rule_effects .effect-container_action .action_select_name {
  color: var(--color-primary);
  align-self: flex-end;
  cursor: default;
}
.edit-score-rule_effects .effect-container_score .action_select_name .icon,
.edit-score-rule_effects .effect-container_action .action_select_name .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  cursor: default;
}
.edit-score-rule_effects .form_number-field .form_label {
  font-size: 1rem;
  color: var(--color-grey-medium);
  text-align: end;
  padding-right: 1rem;
}
.edit-score-rule_effects .effect-container_feedback {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7rem;
}
.edit-score-rule_effects .effect-container_feedback .feedback_select {
  display: flex;
  column-gap: 0.7rem;
}
.edit-score-rule_effects .effect-container_feedback .feedback_select .icon--delete {
  height: 1.6rem;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar {
  margin-top: 1rem;
  background-color: var(--color-grey-very-dark);
  width: 95%;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--style,
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar .form_dropdown-container--out {
  width: 16rem;
  cursor: pointer;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--style .form_field {
  border: 1px solid var(--color-grey-very-dark);
  margin-left: 0.2rem;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--style .form_dropdown-option {
  margin: 0;
  border-left: 1px solid white;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--style .form_dropdown-option--selected {
  border-left: 1px solid var(--color-primary);
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--style .form_dropdown-option:hover {
  padding: 0 0.5rem;
}
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar_dropdown--align,
.edit-score-rule_effects .effect-container_feedback .wysiwyg-bar .form_dropdown-container--out {
  width: 5.5rem;
}
.edit-score-rule_effects .effect-container_feedback .feedback_text {
  border-right: 1px solid var(--color-grey-very-dark);
  border-bottom: 1px solid var(--color-grey-very-dark);
  border-left: 1px solid var(--color-grey-very-dark);
  margin-bottom: 0;
  width: 95%;
  height: 12rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-score-rule_effects .effect-container_feedback .feedback_text::-webkit-scrollbar {
  width: 0.5rem;
}
.edit-score-rule_effects .effect-container_feedback .feedback_text::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.edit-score-rule_effects .effect-container_feedback .feedback_text::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.edit-score-rule_effects .effect-container_feedback .feedback_text .ql-editor {
  padding: 1rem;
}

.score_select ul,
.trigger_select ul {
  list-style: disc;
}
.score_select ul :last-child,
.trigger_select ul :last-child {
  margin-bottom: 2rem;
}
.score_select li,
.trigger_select li {
  margin-left: 2rem;
}
.score_select li :last-child,
.trigger_select li :last-child {
  margin-bottom: 1rem;
}

.trigger_add,
.effect_add {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trigger_add .btn--icon,
.effect_add .btn--icon {
  height: 3rem;
  width: 3rem;
}
.trigger_add .btn--icon .icon,
.effect_add .btn--icon .icon {
  height: 1.5rem;
}

.line_orange {
  border-bottom: 1px solid var(--color-primary);
  margin: 2rem 0 1rem 0;
}

.popup_edit-action.popup {
  width: 40vw;
  height: 85vh;
}
.popup_edit-action .popup_header-name {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.popup_edit-action .popup_main {
  display: flex;
  column-gap: 1rem;
}
.popup_edit-action .popup_main .score-rule_description textarea {
  height: 20rem;
}
.popup_edit-action .popup_main .select-form_container {
  margin: 1rem 0;
}
.popup_edit-action .popup_main .edit-action_right .effect_settings {
  display: flex;
  flex-direction: column;
}
.popup_edit-action .popup_main .edit-action_right .effect_settings .form_dropdown,
.popup_edit-action .popup_main .edit-action_right .effect_settings .form_dropdown-selected {
  width: 28rem;
}
.popup_edit-action .popup_main .edit-action_right .effect_settings .form_dropdown .form_field,
.popup_edit-action .popup_main .edit-action_right .effect_settings .form_dropdown-selected .form_field {
  width: 28rem;
}
.popup_edit-action .popup_main .edit-action_right .effect_settings .form_label-left {
  height: 4rem;
}

.btn {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 0.8rem 2.8rem;
  border: none;
  border-radius: 5rem;
  text-decoration: none;
  text-align: center;
}
.btn:link, .btn:visited, .btn:focus, .btn:hover {
  cursor: pointer;
  text-decoration: none;
}
.btn--icon {
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  padding: 0;
}
.btn--icon .icon {
  height: 1.8rem;
}
.btn--icon .icon:hover {
  fill: white;
}
.btn--icon-mini {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  padding: 0;
}
.btn--icon-mini .icon {
  height: 1.4rem;
}
.btn--icon-mini .icon:hover {
  fill: white;
  stroke: white;
}
.btn:disabled:hover, .btn:disabled:active {
  filter: brightness(1);
  cursor: default;
  pointer-events: none;
}
.btn--orange {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: 600;
  box-shadow: 0 3px 5px var(--color-grey-light);
}
.btn--orange:hover, .btn--orange:active {
  filter: brightness(0.9);
}
.btn--orange:disabled {
  background-color: var(--color-primary-light);
  box-shadow: none;
  cursor: default;
}
.btn--orange .icon {
  fill: white;
  stroke: white;
}
.btn--orange .icon:hover {
  fill: white !important;
  stroke: white !important;
}
.btn--grey {
  background-color: var(--color-grey-very-light);
  color: var(--color-grey-very-dark);
  border: inset 1px solid var(--color-grey-very-light);
  text-shadow: 0 1px 0 white;
}
.btn--grey:hover, .btn--grey:active {
  filter: brightness(0.9);
}
.btn--grey .icon {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.btn--grey .icon:hover {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.btn--dark-grey {
  background-color: var(--color-grey-medium);
  color: white;
  box-shadow: 0 3px 5px var(--color-grey-light);
}
.btn--dark-grey:hover, .btn--dark-grey:active {
  filter: brightness(0.9);
}
.btn--dark-grey .icon {
  fill: white;
  stroke: white;
}
.btn--dark-grey .icon:hover {
  fill: white;
  stroke: white;
}
.btn--yellow {
  background-color: var(--color-yellow);
  color: var(--color-grey-very-dark);
  font-weight: 600;
}
.btn--yellow:hover, .btn--yellow:active {
  filter: brightness(0.9);
}
.btn--yellow:disabled {
  background-color: var(--color-yellow-light);
  box-shadow: none;
  color: var(--color-grey-medium);
  cursor: default;
}
.btn--blue {
  background-color: var(--color-blue);
  color: var(--color-grey-very-dark);
  font-weight: 600;
}
.btn--blue:hover, .btn--blue:active {
  filter: brightness(0.9);
}
.btn--blue:disabled {
  background-color: var(--color-blue-light);
  box-shadow: none;
  color: var(--color-grey-medium);
  cursor: default;
}
.btn--green {
  background-color: var(--color-btn-green);
  color: white;
  font-weight: 600;
}
.btn--green:hover, .btn--green:active {
  filter: brightness(0.9);
}
.btn--dark {
  background-color: var(--color-grey-very-dark);
  color: white;
  font-weight: 600;
}
.btn--dark:hover, .btn--dark:active {
  filter: brightness(0.9);
}
.btn--small {
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.6rem 1.2rem;
}
.btn--medium {
  padding: 0 2.4rem;
  font-size: 1.4rem;
}

.form_dropdown-options .btn.btn--small {
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.6rem 1.2rem;
}

button:disabled .icon,
button:disabled .icon:hover {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
  cursor: default;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 2.4rem;
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}
.icon:hover:not(.icon--disabled) {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  cursor: pointer;
}
.icon--disabled {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}

.icon--direction-down {
  transform: rotate(90deg);
}
.icon--direction-left {
  transform: rotate(180deg);
}
.icon--direction-up {
  transform: rotate(270deg);
}

.popup_components.popup,
.popup_create_components.popup {
  width: 66rem;
  height: 52rem;
  gap: 0.5rem;
}
.popup_components .components_game,
.popup_create_components .components_game {
  border-bottom: 1px solid gray;
  padding-bottom: 3rem;
}
.popup_components .components_game--flex,
.popup_create_components .components_game--flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_components .components_game--title,
.popup_create_components .components_game--title {
  text-align: center;
  font-weight: 500;
  padding: 0 0 0.5rem 0;
}
.popup_components .components_game--container,
.popup_create_components .components_game--container {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.popup_components .components_game--img,
.popup_create_components .components_game--img {
  width: 16rem;
  height: 9rem;
  background-color: darkkhaki;
}
.popup_components .components_game--img.selected,
.popup_create_components .components_game--img.selected {
  border: 3px solid var(--color-primary);
}
.popup_components .components_standard--flex,
.popup_create_components .components_standard--flex {
  margin: 1rem 0;
  gap: 0.5rem;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: 20rem;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: pointer;
}
.popup_components .components_standard--flex::-webkit-scrollbar,
.popup_create_components .components_standard--flex::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}
.popup_components .components_standard--flex::-webkit-scrollbar-track,
.popup_create_components .components_standard--flex::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.popup_components .components_standard--flex::-webkit-scrollbar-thumb,
.popup_create_components .components_standard--flex::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.popup_components .components_standard .components_title--container,
.popup_create_components .components_standard .components_title--container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.popup_components .components_standard--title,
.popup_create_components .components_standard--title {
  text-align: center;
  font-weight: 600;
  padding: 1rem 0 0.5rem 0;
}
.popup_components .components_standard--add,
.popup_create_components .components_standard--add {
  position: absolute;
  top: 1rem;
  right: 0;
}
.popup_components .components_standard--container,
.popup_create_components .components_standard--container {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  user-select: none;
}
.popup_components .components_standard--img,
.popup_create_components .components_standard--img {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_components .components_standard--img:empty,
.popup_create_components .components_standard--img:empty {
  background-color: lightgray;
}
.popup_components .components_standard--img.selected,
.popup_create_components .components_standard--img.selected {
  outline: 3px solid var(--color-primary);
  outline-offset: -3px;
  border-radius: 6px;
}
.popup_components .components_standard--img .icon,
.popup_create_components .components_standard--img .icon {
  height: 80%;
}
.popup_components .components_standard--name,
.popup_create_components .components_standard--name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  width: 15rem;
}
.popup_components .components_standard--name.selected,
.popup_create_components .components_standard--name.selected {
  font-weight: 600;
  color: var(--color-primary);
}

.popup_create_components .popup_main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto 1fr;
}
.popup_create_components .popup_main .create-component_name {
  margin-bottom: 2rem;
}
.popup_create_components .popup_main .create-component_scope {
  width: 28rem;
}
.popup_create_components .popup_main .create-component_icon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
}
.popup_create_components .popup_main .create-component_icon .form-label {
  grid-column: span 2;
}
.popup_create_components .popup_main .create-component_select {
  border-top: 1px solid var(--color-grey-very-light);
  font-weight: 500;
  margin-top: 1rem;
  grid-column: span 2;
}
.popup_create_components .popup_main .create-component_items {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  max-height: 75%;
  flex-wrap: wrap;
}

.dashboard {
  height: calc(100vh - 15.5rem);
  width: fit-content;
  margin-left: 1rem;
  display: grid;
  grid-template-rows: 4rem 1fr;
}
.dashboard_header {
  background-color: var(--color-grey-very-dark);
  color: #fff;
  font-size: 2.8rem;
  text-align: center;
  cursor: default;
}
.dashboard_widget-area {
  background-color: #fff;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(15rem, 30rem));
  grid-auto-rows: min-content;
  gap: 2.5rem;
  padding: 2.2rem 2rem;
}
.dashboard_widget-area::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.dashboard_widget-area::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.dashboard_widget-area::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.dashboard .widget_values {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dashboard .widget_values .widget_value {
  font-weight: bold;
}
.dashboard .table tr:nth-child(even):not(.table_row--clickable:hover) {
  background-color: var(--color-grey-very-very-light);
}
.dashboard_learning .dashboard_header {
  position: relative;
}
.dashboard_learning .dashboard_header .dashboard_close {
  position: absolute;
  top: 0;
  right: 1rem;
}
.dashboard_learning .dashboard_header .dashboard_close .icon {
  background-color: var(--color-grey-very-dark);
  border-radius: 50%;
  height: 4rem;
  fill: white;
  stroke: white;
}
.dashboard_learning-articles {
  background-color: var(--color-widget-grey);
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(15rem, 30rem));
  grid-auto-rows: min-content;
  gap: 2.5rem;
  padding: 2.2rem 2rem;
}
.dashboard_learning-articles::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.dashboard_learning-articles::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.dashboard_learning-articles::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}

.dashboard_sidebar {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard_sidebar-container {
  height: calc(100vh - 19.5rem);
}
.dashboard_sidebar-container .sidebar_area {
  height: 100%;
}
.dashboard_sidebar .sidebar_select-container {
  height: fit-content;
}
.dashboard_sidebar .sidebar_select-container .sidebar_select-organization,
.dashboard_sidebar .sidebar_select-container .sidebar_select-game {
  margin-top: 2rem;
}
.dashboard_sidebar .sidebar_select-container .sidebar_select-organization .form_field,
.dashboard_sidebar .sidebar_select-container .sidebar_select-game .form_field {
  width: 22rem;
}
.dashboard_sidebar .sidebar_select-container .sidebar_select-organization .form_dropdown,
.dashboard_sidebar .sidebar_select-container .sidebar_select-game .form_dropdown {
  margin-bottom: 2rem;
}
.dashboard_sidebar .sidebar_dashboard-SA {
  border: 1px solid var(--color-primary);
  background-color: var(--color-grey-very-very-light);
  padding: 0.54rem 1rem;
  margin-top: 0.1rem;
  width: fit-content;
  border-radius: 15px;
  cursor: pointer;
}
.dashboard_sidebar .sidebar_button-container {
  margin-top: 5rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  row-gap: 1rem;
}
.dashboard_sidebar .sidebar_button-container .sidebar_add-organization,
.dashboard_sidebar .sidebar_button-container .sidebar_add-game,
.dashboard_sidebar .sidebar_button-container .sidebar_users,
.dashboard_sidebar .sidebar_button-container .sidebar_organization-settings,
.dashboard_sidebar .sidebar_button-container .sidebar_game-settings,
.dashboard_sidebar .sidebar_button-container .sidebar_learning,
.dashboard_sidebar .sidebar_button-container .sidebar_learning--selected {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  cursor: pointer;
}
.dashboard_sidebar .sidebar_button-container .icon-container {
  height: 2.8rem;
}
.dashboard_sidebar .sidebar_button-container .icon-container .icon {
  fill: var(--color-blue);
  stroke: var(--color-blue);
}
.dashboard_sidebar .sidebar_button-container .icon-container .icon--learning {
  stroke-width: 1;
}
.dashboard_sidebar .sidebar_button-container .sidebar_learning--selected {
  border-bottom: 2px solid var(--color-primary);
  margin-bottom: -2px;
}
.dashboard_sidebar .sidebar_button-container .sidebar_learning--selected .icon-container:last-child {
  margin-left: auto;
  padding-top: 1rem;
}
.dashboard_sidebar .sidebar_button-container .sidebar_learning--selected .icon--close {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.dashboard_sidebar .sidebar_back-to-admin,
.dashboard_sidebar .sidebar_back-to-game {
  margin-top: auto;
  text-decoration: underline;
}

.edit-name {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 2rem;
}
.edit-name_icon-container {
  display: flex;
  align-self: end;
}
.edit-name_icon-container .icon {
  height: 3.4rem;
}
.edit-name_icon-container .icon--input {
  stroke-width: 0;
}
.edit-name_name-container {
  display: flex;
  flex-direction: column;
}
.edit-name_name-container .form_label,
.edit-name_name-container .form_text-field {
  margin-left: 1rem;
  width: 23.6rem;
}

.file-manager {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 9999;
  border: 1px solid var(--color-grey-very-dark);
  border-radius: var(--border-radius-popup);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  background-color: white;
  width: 120rem;
  height: 60rem;
  background-color: white;
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: baseline;
  overflow: hidden;
}
.file-manager_header {
  padding: 1rem;
  font-size: 2rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-bottom: 0.1px solid var(--color-grey-very-light);
}
.file-manager_title {
  justify-self: center;
  cursor: default;
}
.file-manager .icon--add-folder {
  margin-left: 1rem;
  height: 2.8rem;
}
.file-manager_files {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.file-manager_files-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
.file-manager_files-container::-webkit-scrollbar {
  width: 0.5rem;
}
.file-manager_files-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.file-manager_files-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.file-manager_breadcrumbs-container {
  grid-row: 1/-1;
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.file-manager .breadcrumbs {
  padding-top: 0.5rem;
  column-gap: 0.3rem;
}
.file-manager .breadcrumbs_text {
  color: var(--color-grey-very-dark);
}
.file-manager .breadcrumbs_text.drag-over {
  outline: 2px solid var(--color-primary);
  outline-offset: 0.5rem;
}
.file-manager .breadcrumbs_haakje {
  color: var(--color-grey-very-dark);
}
.file-manager .icon--folder-yellow {
  height: 2.5rem;
  margin-left: 0.2rem;
}
.file-manager .table-container {
  border-right: 0.1px solid var(--color-grey-very-light);
  padding-left: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.file-manager .table-container::-webkit-scrollbar {
  width: 0.5rem;
}
.file-manager .table-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.file-manager .table-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.file-manager .table {
  padding: 2rem 0.5rem 2rem 2rem;
  width: 100%;
  table-layout: fixed;
}
.file-manager .table .name {
  width: 40%;
}
.file-manager .table .numer {
  width: 10%;
}
.file-manager .table .size {
  width: 15%;
}
.file-manager .table .date {
  width: 25%;
}
.file-manager .table .edit {
  width: 10%;
}
.file-manager .table_header {
  text-align: left;
}
.file-manager .table tr:hover {
  color: var(--color-grey-very-dark);
  background-color: var(--color-grey-very-light);
}
.file-manager .table tr.drag-over {
  outline: 2px solid var(--color-primary);
  outline-offset: -2px;
}
.file-manager .table tr:not(:hover) .dropdown-option_edit-button,
.file-manager .table tr:not(:hover) .dropdown-option_delete-button {
  display: none;
}
.file-manager .table .selected {
  color: var(--color-grey-very-dark);
  background-color: var(--color-grey-very-light);
}
.file-manager .table .icon--folder-yellow,
.file-manager .table .icon--img,
.file-manager .table .icon--play-sound,
.file-manager .table .icon--video,
.file-manager .table .icon--pdf,
.file-manager .table .icon--ppt,
.file-manager .table .icon--word,
.file-manager .table .icon--excel {
  margin: 0;
  padding-right: 0.5em;
  vertical-align: bottom;
}
.file-manager .table .icon--pen,
.file-manager .table .icon--delete {
  height: 1em;
}
.file-manager .table .icon--delete {
  margin: 0;
}
.file-manager .table tr {
  vertical-align: middle;
}
.file-manager .table td {
  padding: 0.2rem 0;
}
.file-manager .table tr td:last-child {
  display: flex;
  align-items: center;
}
.file-manager .table tr td:last-child .icon--delete {
  padding: 0 0 0 0.5em;
}
.file-manager_preview {
  max-width: 90%;
  max-height: 100%;
  justify-self: center;
  align-self: center;
}
.file-manager_preview .preview_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-manager_preview .preview_container .preview_name {
  display: none;
}
.file-manager_preview .preview_container .icon--play-sound {
  height: 10rem;
  width: 10rem;
  fill: var(--color-grey-very-light);
  stroke: var(--color-grey-very-light);
}
.file-manager_preview .preview_container .icon--play-sound:hover {
  fill: var(--color-grey-very-light);
  stroke: var(--color-grey-very-light);
}
.file-manager_preview .preview_container video {
  width: 100%;
  height: auto;
}
.file-manager_preview .preview_container .icon--play,
.file-manager_preview .preview_container .icon--pause {
  background-color: var(--color-primary);
  border-radius: 50%;
  fill: white;
  stroke: white;
  height: 5rem;
  width: 5rem;
  padding: 1rem;
}
.file-manager_footer {
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
  padding: 2rem;
}
.file-manager .btn {
  min-width: 14rem;
}
.file-manager .btn--add:disabled {
  cursor: default;
}
.file-manager .icon--delete {
  padding: 0 10rem;
}
.file-manager .btn--cancel {
  margin-right: 3rem;
}

.popup_file-references.popup {
  width: 80rem;
}
.popup_file-references li {
  display: inline-block;
}
.popup_file-references ul {
  display: flex;
  align-items: center;
}
.popup_file-references ul:hover {
  color: var(--color-grey-very-dark);
  background-color: var(--color-grey-very-light);
}
.popup_file-references ul:not(:hover) .dropdown-option_edit-button,
.popup_file-references ul:not(:hover) .dropdown-option_delete-button {
  display: none;
}
.popup_file-references .selected {
  color: var(--color-grey-very-dark);
  background-color: var(--color-grey-very-light);
}

.form_label {
  color: var(--color-grey-dark);
  font-size: 1.5rem;
  font-weight: 500;
  cursor: default;
}
.form_label-left {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 28rem;
}
.form_label-left .form_label {
  justify-self: end;
  margin-bottom: 0;
  margin-right: 1rem;
}
.form_label-left .form_field {
  justify-self: end;
}
.form_label-left--justify-left {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.form_label-left--justify-left .form_number-field {
  justify-self: start;
}
.form_label-left--justify-left .form_label {
  margin-bottom: 0;
  margin-right: 1rem;
}
.form_field {
  font-size: inherit;
  font-family: inherit;
  width: 28rem;
  height: 3.5rem;
  color: inherit;
  background-color: white;
  padding: 1rem;
  border: none;
  border-top: 1px solid var(--color-grey-very-light);
  border-left: 1px solid var(--color-grey-very-light);
  border-right: 1px solid var(--color-grey-very-light);
  border-bottom: 1px solid var(--color-primary);
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}
.form_field:focus {
  outline: none;
}
.form_field:is(textarea) {
  white-space: pre-wrap;
}
.form_field--disabled, .form_field:disabled {
  background: var(--color-grey-very-light);
  display: flex;
  column-gap: 1rem;
  align-items: center;
  cursor: default;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.form_field--medium {
  width: 16rem;
}
.form_field--small {
  width: 10rem;
}
.form_field--very-small {
  width: 7rem;
}
.form_field-group {
  display: flex;
  column-gap: 2rem;
}
.form_field.has-error {
  box-shadow: 0 0 0 2px var(--color-primary);
}
.form_number-field {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-self: end;
}
.form_number-field .icon-container {
  flex-direction: column;
  width: 0;
}
.form_number-field .icon-container .icon {
  height: 1.5rem;
}
.form_number-field .icon-container .icon--arrow-up {
  position: absolute;
  top: 0;
  right: 0.5rem;
  transform: rotate(180deg);
}
.form_number-field .icon-container .icon--arrow-down {
  position: absolute;
  top: 2rem;
  right: 0.5rem;
}
.form_time-field, .form_timeWithSeconds-field {
  width: 12rem;
}
.form_dropdown {
  position: relative;
}
.form_dropdown.has-error {
  box-shadow: 0 0 0 2px var(--color-primary);
  background-color: #fef3ed;
}
.form_dropdown.has-error .form_field {
  box-shadow: none;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.form_dropdown .icon--dropdown {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.form_dropdown-container-in {
  height: auto;
  display: flex;
  align-items: center;
}
.form_dropdown-container-out {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 99999;
}
.form_dropdown-container-out .form_field {
  border-bottom: 1px solid var(--color-grey-very-light);
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.form_dropdown-container-out .icon--dropdown {
  transform: rotate(180deg);
  position: absolute;
  top: 0.6rem;
  right: 0.2rem;
}
.form_dropdown-options {
  z-index: 9999;
  position: absolute;
  top: 3.5rem;
  left: 0;
  background-color: white;
  height: auto;
  width: 100%;
  border-left: 1px solid var(--color-grey-very-light);
  border-right: 1px solid var(--color-grey-very-light);
  border-bottom: 1px solid var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.form_dropdown-options::-webkit-scrollbar {
  width: 0.5rem;
}
.form_dropdown-options::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.form_dropdown-options::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.form_dropdown-options .form_dropdown-option {
  padding-bottom: 0.5rem;
  padding: 0 1rem;
  display: flex;
  grid-template-columns: 1fr 1.5rem 1.5rem;
  column-gap: 0.5rem;
  cursor: pointer;
}
.form_dropdown-options .form_dropdown-option .dropdown-option_label {
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.form_dropdown-options .form_dropdown-option .icon-container {
  width: 1.6rem;
}
.form_dropdown-options .form_dropdown-option:not(:hover) .dropdown-option_edit-button,
.form_dropdown-options .form_dropdown-option:not(:hover) .dropdown-option_delete-button {
  display: none;
}
.form_dropdown-options:not(:hover) .form_dropdown-option--selected,
.form_dropdown-options .form_dropdown-option:hover {
  background-color: var(--color-grey-very-light);
}
.form_dropdown-options:not(:hover) .form_dropdown-option--selected .icon,
.form_dropdown-options .form_dropdown-option:hover .icon {
  fill: var(--color-grey-dark);
  stroke: var(--color-grey-dark);
}
.form_dropdown-options--medium {
  width: 16rem;
}
.form_dropdown-options--small {
  width: 10rem;
}
.form_dropdown-options--very-small {
  width: 7rem;
}
.form_dropdown-options .btn {
  text-transform: none;
  font-size: 1.4rem;
  padding: 1rem;
  margin: 0.21rem;
  line-height: 1;
  align-self: flex-end;
}
.form_dropdown-selected {
  display: flex;
  cursor: pointer;
}
.form_dropdown-selected--empty {
  font-style: italic;
}
.form_dropdown-selected p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.form_dropdown-selected .icon--dropdown {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
}
.form_dropdown--icon {
  position: relative;
  display: flex;
}
.form_dropdown--icon .icon {
  position: absolute;
  top: 0;
  right: 0.5rem;
  height: 2rem;
}
.form_checkbox {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: inherit;
  accent-color: var(--color-grey-medium);
  margin-top: 1.5rem;
  grid-column: 1/-1;
}
.form_checkbox--small {
  font-size: 1.4rem;
}
.form_checkbox--small .icon--checkbox {
  width: 1.6rem;
  height: 1.6rem;
}
.form_checkbox--medium {
  font-size: 1.6rem;
}
.form_checkbox--medium .icon--checkbox {
  width: 2rem;
  height: 2rem;
}
.form_checkbox-group {
  margin-top: 0.5rem;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.form_checkbox-group .form_checkbox {
  margin-top: 0;
}
.form_checkbox input {
  cursor: pointer;
}
.form_checkbox .checkbox_text {
  cursor: default;
  display: flex;
  gap: 0.5rem;
}
.form_radio-buttons {
  display: flex;
  column-gap: 2rem;
  margin-top: 0.5rem;
}
.form_radio-button {
  position: relative;
  display: flex;
  align-items: center;
}
.form_radio-button .radio-button_input {
  display: none;
}
.form_radio-button .radio-button_label {
  cursor: pointer;
  position: relative;
  padding-left: 3.8rem;
}
.form_radio-button .radio-button_button {
  height: 2rem;
  width: 2rem;
  border: 1px solid var(--color-grey-light);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 1rem;
  top: 0;
}
.form_radio-button .radio-button_button::after {
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-primary);
  opacity: 0;
  transition: opacity 0.2s;
}
.form_radio-button .radio-button_input:checked ~ .radio-button_label .radio-button_button::after {
  opacity: 1;
}
.form_color-picker .colorpicker {
  display: flex;
}
.form .text-container {
  display: flex;
  align-items: center;
}

.autogrow-wrapper {
  display: grid;
}
.autogrow-wrapper .autogrow-shadow {
  white-space: pre-wrap;
  visibility: hidden;
}
.autogrow-wrapper > textarea {
  resize: none;
  overflow: hidden;
}
.autogrow-wrapper > textarea,
.autogrow-wrapper .autogrow-shadow {
  grid-area: 1/1/2/2;
  height: unset !important;
}

.help-overlay .overlay {
  border: 1px solid var(--color-grey-very-light);
  border-radius: var(--border-radius-popup);
  z-index: 999999;
  position: absolute;
  top: 5%;
  left: 10%;
  width: 45rem;
  height: 90vh;
  background-color: var(--color-grey-very-dark);
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}
.help-overlay .overlay_header {
  background-color: var(--color-blue);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 3rem;
  padding: 0 0.5rem;
  cursor: move;
  user-select: none;
}
.help-overlay .overlay_header--name {
  text-align: center;
}
.help-overlay .overlay_header .icon-container .icon {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.help-overlay .overlay_main {
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
}
.help-overlay .overlay_main::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}
.help-overlay .overlay_main::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.help-overlay .overlay_main::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.help-overlay .overlay_main::-webkit-scrollbar-corner {
  background-color: transparent;
}
.help-overlay .overlay_main .help_search-bar {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  height: 5rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.help-overlay .overlay_main .help_search-bar .search-bar_input {
  background-color: var(--color-grey-very-light);
  height: 70%;
  width: 70%;
  justify-self: center;
}
.help-overlay .overlay_main .help_search-bar .icon {
  fill: var(--color-grey-very-light);
  stroke: var(--color-grey-very-light);
  stroke-width: 0;
}
.help-overlay .overlay_main .help_title {
  color: white;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.help-overlay .overlay_main .help-article_card {
  display: grid;
  grid-template-columns: 5rem 1fr;
  column-gap: 0.5rem;
  border: 1px solid white;
  border-bottom: none;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
}
.help-overlay .overlay_main .help-article_card:last-child {
  border-bottom: 1px solid white;
}
.help-overlay .overlay_main .help-article_card img {
  object-fit: cover;
  aspect-ratio: 1/1;
}
.help-overlay .overlay_main .help-article_card--text {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.help-overlay .overlay_main .help-article_card--title {
  width: 35rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.help-overlay .overlay_main .help-article_card--tag {
  font-size: 1.2rem;
  margin-top: auto;
}
.help-overlay .help_close {
  color: var(--color-grey-very-light);
  text-align: end;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.help-overlay_view-article .overlay_main {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.item-bar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.item-bar button:disabled .icon {
  fill: rgb(242, 178, 66);
  stroke: rgb(242, 178, 66);
  cursor: default;
}
.item-bar button.active .icon {
  fill: var(--color-item-select);
  stroke: var(--color-item-select);
}
.item-bar .icon {
  height: 3rem;
  fill: white;
  stroke: white;
  stroke-width: 1px;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.3));
}
.item-bar .icon:hover {
  fill: var(--color-item-select);
  stroke: var(--color-item-select);
}
.item-bar .icon.icon--input {
  stroke-width: 0;
}
.item-bar .icon.icon--story, .item-bar .icon.icon--popup, .item-bar .icon.icon--video, .item-bar .icon.icon--quiz, .item-bar .icon.icon--minigame, .item-bar .icon.icon--component, .item-bar .icon.icon--login, .item-bar .icon.icon--high-scores {
  stroke-width: 1rem;
}
.item-bar .icon.icon--score-rules {
  stroke-width: 0.5rem;
}
.item-bar .icon.icon--login {
  stroke-width: 1.5rem;
}
.item-bar .icon.icon--feedback {
  height: 2.6rem;
}
.item-bar .icon.icon--minigame, .item-bar .icon.icon--quiz {
  height: 3.5rem;
}
.item-bar .icon.icon--text {
  padding-left: 1.5rem;
  border-left: 3px solid white;
}
.item-bar .icon.icon--full-screen {
  height: 2.5rem;
  margin-top: 0.2rem;
}
.item-bar .icon.icon--component {
  height: 3.2rem;
}
.item-bar_text--icon {
  padding-left: 1.5rem;
  border-left: 1px solid white;
}
.item-bar_login--icon {
  padding-left: 1rem;
}

.learning-article_card {
  background-color: white;
  cursor: pointer;
}
.learning-article_card--image {
  object-fit: cover;
  aspect-ratio: 16/9;
}
.learning-article_card--text {
  padding: 0.5rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
}
.learning-article_card--text .article_name {
  text-align: center;
  font-weight: 500;
  flex: 0 0 80%;
}
.learning-article_card--text .article_time {
  text-align: end;
  flex: 0 0 10%;
}
.learning-article_popup .popup {
  min-width: 45rem;
}
.learning-article_popup .popup_header {
  background-color: var(--color-grey-very-dark);
  border-top-right-radius: var(--border-radius-popup);
  border-top-left-radius: var(--border-radius-popup);
  border: 1px solid white;
  color: var(--color-grey-very-light);
}
.learning-article_popup .popup_header .icon {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.learning-article_help {
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}
.learning-article_help::-webkit-scrollbar {
  width: 0.5rem;
}
.learning-article_help::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.learning-article_help::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

.learning-article_styles p {
  margin-top: 0.5rem;
}
.learning-article_styles h1 {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
}
.learning-article_styles h2 {
  font-weight: 600;
  padding-top: 2rem;
}
.learning-article_styles ol li {
  margin-left: 3rem;
  list-style-type: decimal;
}
.learning-article_styles ul li {
  margin-left: 3rem;
  list-style-type: disc;
}
.learning-article_styles .image {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}
.learning-article_styles .image img {
  border: 2px solid black;
  width: 65%;
  box-shadow: 10px 10px 0 0 var(--color-primary-light);
}
.learning-article_styles .video {
  display: flex;
  justify-content: center;
}
.learning-article_styles .video video {
  width: 90%;
  border: 2px solid black;
  box-shadow: 10px 10px 0 0 var(--color-grey-dark);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 9999;
  border: 1px solid var(--color-grey-very-dark);
  border-radius: var(--border-radius-popup);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  background-color: white;
  min-width: 28rem;
  max-width: 70vw;
  min-height: 18rem;
  max-height: 90vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
}
.popup_header {
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 4rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.popup_header-name {
  justify-self: center;
  margin-left: 3rem;
  font-weight: 500;
  color: inherit;
  cursor: default;
}
.popup_header p {
  margin-bottom: 0;
}
.popup_header .icon-container {
  height: 100%;
}
.popup_header .icon--close {
  margin-right: 0.5rem;
}
.popup_main {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;
}
.popup_main::-webkit-scrollbar {
  width: 0.5rem;
}
.popup_main::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.popup_main::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.popup_warning--details {
  margin-left: 3rem;
}
.popup_warning--details .popup_warning--detail {
  list-style: disc;
}
.popup .btn-container {
  justify-self: flex-end;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1rem;
  margin-right: 1rem;
}
.popup .btn-container .btn {
  min-width: 5rem;
}

.rewards_overview {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  max-height: 100%;
  font-size: 1.4rem;
}
.rewards_overview--header {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr) auto;
  border-bottom: 1px solid var(--color-grey-medium);
  font-weight: 500;
  padding-right: 2rem;
  font-weight: 600;
  cursor: default;
}
.rewards_overview--header .rewards_header--trigger,
.rewards_overview--header .rewards_header--effect,
.rewards_overview--header .rewards_header--moment {
  justify-self: center;
}
.rewards_overview--header .rewards_header--name {
  padding-left: 2.3rem;
}
.rewards_overview--header .rewards_header--score {
  width: 5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.rewards_overview--header .rewards_header--score-container {
  display: flex;
  column-gap: 1rem;
}
.rewards_overview--content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2rem;
}
.rewards_overview--content::-webkit-scrollbar {
  width: 0.5rem;
}
.rewards_overview--content::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.rewards_overview--content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.rewards_overview--content .rewards_rule-group {
  align-self: center;
  font-weight: 600;
  padding-top: 1rem;
  display: flex;
}
.rewards_overview--content .rewards_rule-group .rule-group_name {
  padding-left: 0.5rem;
  margin-bottom: 0.2rem;
  cursor: default;
}
.rewards_overview--content .rewards_rule-group .rule-group_total {
  width: 5rem;
  text-align: center;
}
.rewards_overview--content .rewards_rule-group .rule-group_total-container {
  margin-left: auto;
  display: flex;
  column-gap: 0.2rem;
}
.rewards_overview--content .icon.icon--dropdown,
.rewards_overview--content .icon.icon--dropdown-right {
  height: 1.8rem;
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.rewards_overview--content .icon.icon--dropdown-right {
  transform: rotate(270deg);
}
.rewards_overview--content .rewards_rule-container {
  margin-bottom: 0.4rem;
}
.rewards_overview--content .rewards_score-rule {
  padding-left: 2.3rem;
}
.rewards_overview--content .rewards_trigger-icons,
.rewards_overview--content .rewards_effect-icons,
.rewards_overview--content .rewards_moment {
  justify-self: center;
  align-self: center;
}
.rewards_overview--content .rewards_icons-container {
  display: flex;
  column-gap: 0.5rem;
}
.rewards_overview--content .rewards_icons-container .icon {
  height: 2rem;
  fill: var(--color-grey-dark);
  stroke: var(--color-grey-dark);
}
.rewards_overview--content .rewards_icons-container .icon--feedback {
  stroke-width: 0;
}
.rewards_overview--content .rewards_rule-container {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr) auto;
  align-items: center;
  cursor: pointer;
}
.rewards_overview--content .rule-container_hover {
  display: contents;
}
.rewards_overview--content .rule-container_hover :hover:not(.rewards_effect-action *) {
  background-color: var(--color-grey-very-light);
}
.rewards_overview--content .rewards_score {
  width: 5rem;
  text-align: center;
}
.rewards_overview--content .rewards_score-container {
  display: flex;
  column-gap: 0.2rem;
}
.rewards_overview--total {
  font-weight: 600;
  border-top: 1px solid var(--color-grey-medium);
  margin-top: 2rem;
  justify-self: end;
  width: 5rem;
}
.rewards_overview--total-container {
  display: flex;
  column-gap: 1rem;
  text-align: center;
  margin-left: auto;
  padding-right: 2rem;
}
.rewards_overview--add {
  justify-self: center;
  align-self: center;
}

.rewards_overview.rewards_overview--game .rewards_overview--header,
.rewards_overview.rewards_overview--game .rewards_rule-container {
  grid-template-columns: repeat(6, 1fr);
}
.rewards_overview.rewards_overview--game .rewards_header--actions,
.rewards_overview.rewards_overview--game .rewards_effect-actions {
  justify-self: flex-start;
  align-self: center;
}
.rewards_overview.rewards_overview--game .rewards_effect-action {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.rewards_overview.rewards_overview--game .rewards_effect-action .btn {
  padding: 0.4rem 0.8rem;
  font-size: 1.4rem;
}
.rewards_overview.rewards_overview--game .rewards_effect-action .icon--show-item {
  stroke-width: 0;
}
.rewards_overview.rewards_overview--game .rewards_effect-action-target .icon {
  height: 1.8rem;
}
.rewards_overview.rewards_overview--game .rewards_effect-action-target {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: var(--color-primary);
  font-weight: 500;
  font-size: 0.9em;
}
.rewards_overview.rewards_overview--game .rewards_effect-action-target .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}

.rewards_overview.nieuw {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rewards_overview.nieuw .rewards_hide-counter {
  margin-top: auto;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: underline;
  margin-right: 8rem;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
}

.rewards_overview {
  padding-top: 0.5rem;
  overflow: auto;
  overflow-y: auto;
  overflow-x: auto;
}
.rewards_overview::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}
.rewards_overview::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.rewards_overview::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.rewards_overview::-webkit-scrollbar-corner {
  background-color: transparent;
}
.rewards_overview .rewards_new-window {
  display: flex;
  align-items: flex-start;
  margin-top: -0.8rem;
  padding-bottom: 0.2rem;
}
.rewards_overview .rewards_new-window .icon {
  width: 1.6rem;
  fill: var(--color-grey-dark);
  stroke: var(--color-grey-dark);
}
.rewards_overview .rewards_new-window .icon:hover {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.rewards_overview.table {
  width: 100%;
}
.rewards_overview .table {
  border: 1px solid var(--color-grey-light);
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 1rem;
}
.rewards_overview .table tr {
  height: 2.6rem;
  text-align: center;
}
.rewards_overview .table th {
  vertical-align: middle;
  border-bottom: 1px solid var(--color-grey-light);
}
.rewards_overview .table td {
  vertical-align: top;
}
.rewards_overview .table_header {
  text-align: left;
  border-bottom: 1px solid var(--color-grey-very-light);
  font-weight: 600;
  background-color: var(--color-grey-very-light);
  text-align: center;
}
.rewards_overview .table_header--name {
  text-align: left;
  padding-left: 2rem;
  width: 20rem;
}
.rewards_overview .table_header--trigger {
  width: 8rem;
  padding: 0 0.5rem;
}
.rewards_overview .table_header--feedback {
  width: 8rem;
  padding: 0 0.5rem;
}
.rewards_overview .table_header--effect {
  width: 12rem;
  padding: 0 0.5rem;
}
.rewards_overview .table_header--effect-action {
  width: 15rem;
  text-align: left;
}
.rewards_overview .table_header--score {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.5rem;
  min-width: 5rem;
}
.rewards_overview .table_header--score-rule {
  width: 26rem;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.5rem;
}
.rewards_overview .table_header--max {
  width: 10rem;
  border-right: 1px solid var(--color-grey-medium);
  border-left: 1px solid var(--color-grey-medium);
  background-color: var(--color-primary-light);
  padding: 0 0.5rem;
}
.rewards_overview .table_header--player {
  width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.5rem;
}
.rewards_overview .table_rule-group:not(:first-child),
.rewards_overview .table_counter:not(:first-child) {
  height: 5rem;
}
.rewards_overview .table_rule-group:not(:first-child) th,
.rewards_overview .table_counter:not(:first-child) th {
  vertical-align: bottom;
}
.rewards_overview .table_rule-group th,
.rewards_overview .table_counter th {
  border-bottom: none;
}
.rewards_overview .table_rule-group--name, .rewards_overview .table_rule-group--counter,
.rewards_overview .table_counter--name,
.rewards_overview .table_counter--counter {
  text-align: left;
  border-bottom: 1px solid var(--color-grey-very-light);
  font-weight: 600;
}
.rewards_overview .table_rule-group--name .rule-group_name-container,
.rewards_overview .table_rule-group--name .rule-group_counter-container,
.rewards_overview .table_rule-group--name .counter_name-container, .rewards_overview .table_rule-group--counter .rule-group_name-container,
.rewards_overview .table_rule-group--counter .rule-group_counter-container,
.rewards_overview .table_rule-group--counter .counter_name-container,
.rewards_overview .table_counter--name .rule-group_name-container,
.rewards_overview .table_counter--name .rule-group_counter-container,
.rewards_overview .table_counter--name .counter_name-container,
.rewards_overview .table_counter--counter .rule-group_name-container,
.rewards_overview .table_counter--counter .rule-group_counter-container,
.rewards_overview .table_counter--counter .counter_name-container {
  display: flex;
  cursor: pointer;
}
.rewards_overview .table_rule-group--name .rule-group_name-container .icon,
.rewards_overview .table_rule-group--name .rule-group_counter-container .icon,
.rewards_overview .table_rule-group--name .counter_name-container .icon, .rewards_overview .table_rule-group--counter .rule-group_name-container .icon,
.rewards_overview .table_rule-group--counter .rule-group_counter-container .icon,
.rewards_overview .table_rule-group--counter .counter_name-container .icon,
.rewards_overview .table_counter--name .rule-group_name-container .icon,
.rewards_overview .table_counter--name .rule-group_counter-container .icon,
.rewards_overview .table_counter--name .counter_name-container .icon,
.rewards_overview .table_counter--counter .rule-group_name-container .icon,
.rewards_overview .table_counter--counter .rule-group_counter-container .icon,
.rewards_overview .table_counter--counter .counter_name-container .icon {
  height: 1.8rem;
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.rewards_overview .table_rule-group--name .rule-group_name-container p,
.rewards_overview .table_rule-group--name .rule-group_counter-container p,
.rewards_overview .table_rule-group--name .counter_name-container p, .rewards_overview .table_rule-group--counter .rule-group_name-container p,
.rewards_overview .table_rule-group--counter .rule-group_counter-container p,
.rewards_overview .table_rule-group--counter .counter_name-container p,
.rewards_overview .table_counter--name .rule-group_name-container p,
.rewards_overview .table_counter--name .rule-group_counter-container p,
.rewards_overview .table_counter--name .counter_name-container p,
.rewards_overview .table_counter--counter .rule-group_name-container p,
.rewards_overview .table_counter--counter .rule-group_counter-container p,
.rewards_overview .table_counter--counter .counter_name-container p {
  padding-left: 0.5rem;
}
.rewards_overview .table_rule-group--score,
.rewards_overview .table_counter--score {
  text-align: center;
  font-weight: 600;
  cursor: default;
}
.rewards_overview .table_rule-group--max,
.rewards_overview .table_counter--max {
  border-right: 1px solid var(--color-grey-medium);
  border-left: 1px solid var(--color-grey-medium);
  background-color: var(--color-primary-very-light);
  font-weight: 600;
  cursor: default;
}
.rewards_overview .table_timeline-event:hover,
.rewards_overview .table_score-rule:hover {
  background-color: var(--color-grey-very-light);
  cursor: pointer;
}
.rewards_overview .table_timeline-event:hover .effect-action_edit-container,
.rewards_overview .table_score-rule:hover .effect-action_edit-container {
  display: block;
}
.rewards_overview .table_timeline-event .table_feedback,
.rewards_overview .table_score-rule .table_feedback {
  width: 8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.rewards_overview .table_name {
  text-align: left;
  padding-left: 2.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.rewards_overview .table_max {
  border-right: 1px solid var(--color-grey-medium);
  border-left: 1px solid var(--color-grey-medium);
  background-color: var(--color-primary-very-light);
}
.rewards_overview .table_effect-action--container {
  display: grid;
}
.rewards_overview .table_effect-action--container .table_effect-action {
  display: flex;
  align-items: center;
  justify-content: left;
  column-gap: 0.7rem;
  position: relative;
}
.rewards_overview .table_effect-action--container .table_effect-action .rewards_add-action {
  color: var(--color-primary);
  text-decoration: underline;
}
.rewards_overview .table_effect-action--container .table_effect-action .icon {
  stroke: var(--color-primary);
  fill: var(--color-primary);
  height: 1.6rem;
  stroke-width: 0;
}
.rewards_overview .table_effect-action--container .table_effect-action--target {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  color: var(--color-primary);
}
.rewards_overview .table_effect-action--container .table_effect-action--name {
  width: 9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-size: 1.4rem;
}
.rewards_overview .rewards_icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rewards_overview .rewards_icons-container .icon {
  height: 1.8rem;
}
.rewards_overview .rewards_icons-container .icon--action {
  height: 1.6rem;
}
.rewards_overview .rewards_icons-container .icon--counter:not(:last-child),
.rewards_overview .rewards_icons-container .icon--feedback:not(:last-child) {
  margin-right: 0.5rem;
}
.rewards_overview .rewards_total--counter {
  border-top: 1px solid var(--color-grey-medium);
  font-weight: 600;
  cursor: default;
}
.rewards_overview .rewards_total--max {
  border-top: 1px solid var(--color-grey-medium);
  font-weight: 600;
  border-right: 1px solid var(--color-grey-medium);
  border-left: 1px solid var(--color-grey-medium);
  background-color: var(--color-primary-very-light);
}

.popup_hide-counters.popup {
  width: 30rem;
  max-height: 45rem;
}
.popup_hide-counters .popup_main .hide-counters_counter-container {
  margin-left: 10rem;
}

.popup.popup_view-rewards {
  min-width: 70vw;
  max-width: 80vw;
  min-height: 60vh;
  max-height: 85vh;
  row-gap: 0;
  grid-template-rows: auto 1fr;
  padding-bottom: 1rem;
}
.popup.popup_view-rewards .rewards_header--name {
  column-gap: 1rem;
}
.popup.popup_view-rewards .rewards_header-finished {
  padding-top: 1rem;
}
.popup.popup_view-rewards .rewards_hide-counter {
  display: none;
}

.popup.popup_score-build {
  min-width: 28rem;
  max-width: 90vw;
  min-height: 18rem;
  max-height: 60rem;
  width: 100%;
}
.popup.popup_score-build .popup_main {
  display: block;
}
.popup.popup_score-build .rewards_overview {
  padding-top: 0 !important;
}
.popup.popup_score-build .rule-group_container .table_name {
  background-color: white;
}
.popup.popup_score-build .rule-group_container .table_score {
  border: none;
  background-color: white;
}
.popup.popup_score-build .table_rule-group {
  height: 2rem !important;
  background-color: white !important;
}
.popup.popup_score-build .table_counter--name,
.popup.popup_score-build .table_rule-group--max,
.popup.popup_score-build .table_rule-group--score {
  font-size: 1.6rem;
  padding: 0.5rem;
  text-decoration: underline;
}
.popup.popup_score-build .table_counter--name,
.popup.popup_score-build .table_rule-group--max,
.popup.popup_score-build .table_rule-group--score {
  padding-top: 2rem;
  padding-bottom: 0.1rem;
}

.score_build {
  font-size: 1em;
}
.score_build.popup {
  width: 20em;
}
.score_build.popup .popup_header {
  height: 1.8em;
}
.score_build.popup .popup_header-name {
  margin-left: 3em;
}
.score_build.popup .popup_header .icon--close {
  height: 2em;
  margin-right: 0.5em;
}
.score_build .popup_main dl {
  display: grid;
  grid-template-columns: auto auto;
}

.select-form_container {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  margin: 2rem 0.5rem 0.5rem 1rem;
  width: 27.8rem;
}
.select-form_container .form_field {
  margin-bottom: 0;
  margin-left: 1rem;
  width: 26rem;
}
.select-form_container .form_checkbox {
  margin-left: 1rem;
}
.select-form_header {
  color: var(--color-primary);
  text-align: center;
  font-weight: 500;
  padding-bottom: 1rem;
  cursor: default;
}
.select-form_edit {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 2rem;
  height: auto;
  margin-bottom: 1rem;
}
.select-form_edit .icon--video,
.select-form_edit video {
  height: 3.4rem;
}
.select-form_edit-name {
  align-self: end;
  font-size: 1.4rem;
  padding-bottom: 1.2rem;
  cursor: default;
}
.select-form_edit-name--leeg {
  font-style: italic;
  cursor: default;
}
.select-form_edit-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
}
.select-form_edit-icons .icon--go-to-screen,
.select-form_edit-icons .icon--img,
.select-form_edit-icons img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  object-position: top;
}
.select-form_edit-icons .icon--play-sound,
.select-form_edit-icons .icon--item {
  height: 4.5rem;
  margin-top: 0.5rem;
}
.select-form_edit-icons .circle_icon--edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--color-primary);
  border-radius: 50%;
  margin-top: -0.5rem;
  margin-bottom: 0.2rem;
}
.select-form_edit-icons .circle_icon--edit .icon--pen {
  height: 1.6rem;
  fill: white;
}
.select-form_edit .icon--delete {
  grid-column: 3;
  grid-row: 1;
  align-self: end;
  height: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0.2rem;
}
.select-form .btn {
  width: 10rem;
}

.select-slide {
  max-height: calc(100vh - 17rem);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  z-index: 9000;
  width: 30rem;
  margin-right: 0.2rem;
  position: absolute;
  bottom: 3.5rem;
  right: 61rem;
  border: 1px solid var(--color-grey-light);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  line-height: 2.8rem;
}
.select-slide::-webkit-scrollbar {
  width: 0.5rem;
}
.select-slide::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.select-slide::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.select-slide_header {
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 3.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.select-slide_header-name {
  justify-self: center;
  font-weight: 500;
  color: inherit;
  padding-left: 3rem;
}
.select-slide_header .icon--close {
  margin-right: 0.5rem;
}
.select-slide .storyline {
  margin-left: 1rem;
}
.select-slide .storyline_game {
  margin-top: 2rem;
}

.select-popup .popup {
  min-width: 35rem;
  min-height: 45rem;
}
.select-popup .storyline {
  padding: 1rem 5rem 1rem 1rem;
}

.timelines_overview {
  display: flex;
  flex-direction: column;
  height: 88%;
  font-size: 1.4rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.timelines_overview::-webkit-scrollbar {
  width: 0.5rem;
}
.timelines_overview::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.timelines_overview::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.timelines_add-timeline {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: underline;
  margin-right: 8rem;
}
.timelines_group:last-child {
  height: 100%;
  align-self: top;
}
.timelines_group {
  margin-top: 1rem;
}
.timelines_group .timeline_header {
  display: flex;
  align-items: center;
}
.timelines_group .timeline_header--name {
  display: flex;
}
.timelines_group .timeline_header--name .icon--dropdown,
.timelines_group .timeline_header--name .icon--dropdown-right {
  height: 1.8rem;
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.timelines_group .timeline_header--name .icon--dropdown-right {
  transform: rotate(270deg);
}
.timelines_group .timeline_header--name .timeline_name,
.timelines_group .timeline_header--name .timeline_time {
  font-weight: 600;
  padding-top: 0.2rem;
  cursor: pointer;
}
.timelines_group .timeline_header--name .timeline_counter {
  margin-left: 3rem;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
.timelines_group .timeline_header--name .timeline_counter .edit-name_icon-container {
  align-self: center;
}
.timelines_group .timeline_header--name .timeline_counter .icon {
  height: 1.6rem;
}
.timelines_group .timeline_header--name .timeline_counter .timeline_counter--name {
  padding-top: 0.2rem;
  font-size: 1.4rem;
}
.timelines_group .timeline_header--edit {
  margin-left: 4rem;
  margin-left: auto;
  margin-right: 4rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.timelines_group .timeline_header--edit .icon-container {
  display: inline;
}
.timelines_group .timeline_header--edit .icon--pen,
.timelines_group .timeline_header--edit .icon--delete {
  height: 1.6rem;
  width: 1.6rem;
}
.timelines_group .timeline_header--counter {
  margin-left: 5rem;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
.timelines_group .timeline_header--counter .icon {
  height: 1.8rem;
}
.timelines_group .timeline_header--counter .timeline_counter--name {
  padding-top: 0.5rem;
  font-size: 1.4rem;
}
.timelines_group .timeline_header-finished {
  font-weight: 600;
  padding-left: 2rem;
}
.timelines_group .table-container {
  padding: 0.5rem 2rem 1rem 2rem;
}
.timelines_group .table {
  border: 1px solid var(--color-grey-light);
  table-layout: fixed;
  border-collapse: collapse;
}
.timelines_group .table th {
  border-bottom: 1px solid var(--color-grey-light);
  padding: 0 0.2rem;
}
.timelines_group .table tr {
  border-bottom: 1px solid var(--color-grey-very-light);
}
.timelines_group .table td {
  padding: 0 0.2rem;
}
.timelines_group .table .selected {
  color: var(--color-grey-very-dark);
  background-color: var(--color-grey-very-light);
}
.timelines_group .timeline_events--add {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup.popup_add-timeline {
  min-width: 50rem;
  min-height: 40rem;
}
.popup.popup_add-timeline .timeline_add-counter {
  display: flex;
  margin-top: 2rem;
  column-gap: 1.2rem;
  align-items: flex-end;
}
.popup.popup_add-timeline .add-timeline_name .form_field {
  width: 18rem;
}
.popup.popup_add-timeline .add-timeline_time .form_field {
  width: 9rem;
}
.popup.popup_add-timeline .add-timeline_counter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popup.popup_add-timeline .icon--counter {
  height: 3.2rem;
}
.popup.popup_add-timeline .form_dropdown,
.popup.popup_add-timeline .form_dropdown-option {
  width: 28rem;
}

.timelines_add-timeline {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: underline;
  margin-right: 8rem;
  cursor: pointer;
  width: fit-content;
}

.table.timelines_story-editor {
  width: 100%;
}
.table.timelines_story-editor .time {
  width: 15%;
}
.table.timelines_story-editor .description {
  width: 77%;
}
.table.timelines_story-editor .edit {
  width: 8%;
}
.table.timelines_story-editor tr {
  height: 2.6rem;
}
.table.timelines_story-editor tr.timeline_event--edit {
  border-bottom: 1px solid var(--color-primary);
}
.table.timelines_story-editor td,
.table.timelines_story-editor th {
  vertical-align: middle;
}
.table.timelines_story-editor tr td:last-child {
  display: flex;
  align-items: center;
}
.table.timelines_story-editor tr:hover {
  background-color: var(--color-grey-very-light);
  color: var(--color-grey-very-dark);
  cursor: pointer;
}
.table.timelines_story-editor tr:not(:hover) .dropdown-option_delete-button,
.table.timelines_story-editor tr:not(:hover) .dropdown-option_edit-button {
  display: none;
}
.table.timelines_story-editor .table_description {
  white-space: pre-wrap;
  height: inherit;
}
.table.timelines_story-editor .table_edit {
  padding-right: 2rem;
  column-gap: 0.6rem;
  margin-top: 0.4rem;
}
.table.timelines_story-editor .table_edit .icon {
  height: 1.6rem;
}

.timeline_add,
.timeline_edit {
  display: flex;
  column-gap: 0.8rem;
}
.timeline_add .icon--time,
.timeline_edit .icon--time {
  height: 3.4rem;
  width: 3.4rem;
}
.timeline_add .form_text-field,
.timeline_edit .form_text-field {
  width: 16rem;
}
.timeline_add .form_time-field,
.timeline_add .form_timeWithSeconds-field,
.timeline_edit .form_time-field,
.timeline_edit .form_timeWithSeconds-field {
  width: 8rem;
}

.timeline_counter .edit-name_icon-container:hover {
  cursor: auto;
}
.timeline_counter .edit-name_icon-container:hover .icon:hover {
  cursor: auto;
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}

.add-timeline_counter {
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
}
.add-timeline_counter .edit-name_icon-container {
  align-self: center;
}
.add-timeline_counter .form_text-field,
.add-timeline_counter .form_dropdown,
.add-timeline_counter .form_field {
  width: 16rem;
}
.add-timeline_counter .icon {
  height: 1.8rem;
}

.edit-timeline-event_name {
  width: 100%;
}

.edit-timeline-event_buttons {
  display: flex;
  justify-content: flex-end;
}

.popup.popup_view-timelines {
  min-width: 70vw;
  max-width: 80vw;
  min-height: 60vh;
  max-height: 85vh;
  row-gap: 0;
  grid-template-rows: auto 1fr;
  padding-bottom: 1rem;
}
.popup.popup_view-timelines .timeline_header--name {
  column-gap: 1rem;
}
.popup.popup_view-timelines .timeline_header-finished {
  padding-top: 1rem;
}
.popup.popup_view-timelines .preview_timelines.form_checkbox {
  margin-top: 0.5rem;
  margin-left: -1rem;
}

.popup_timeline-actions .form_dropdown {
  width: 28rem;
}
.popup_timeline-actions .form_dropdown .form_field {
  column-gap: 1rem;
}

.table.timelines_game-editor {
  width: 98%;
}
.table.timelines_game-editor .time {
  width: 10%;
}
.table.timelines_game-editor .description {
  width: 40%;
}
.table.timelines_game-editor .action {
  width: 50%;
}
.table.timelines_game-editor .table_time,
.table.timelines_game-editor .table_description,
.table.timelines_game-editor .table_action {
  vertical-align: top;
}
.table.timelines_game-editor .table_action-container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1rem;
  align-items: top;
}
.table.timelines_game-editor .table_action-container:hover {
  background-color: var(--color-grey-very-light);
  color: var(--color-grey-very-dark);
  cursor: pointer;
}
.table.timelines_game-editor .table_action-container:not(:hover) .dropdown-option_add-button, .table.timelines_game-editor .table_action-container:not(:hover) .dropdown-option_delete-button, .table.timelines_game-editor .table_action-container:not(:hover) .dropdown-option_edit-button {
  display: none;
}
.table.timelines_game-editor .table_action-container .action-icon .icon {
  height: 1.8rem;
}
.table.timelines_game-editor .table_action-container .action_item {
  padding-top: 0.2rem;
}
.table.timelines_game-editor .table_action-container .action_edit {
  display: flex;
  column-gap: 1.1rem;
  margin-top: 0.2rem;
  align-items: center;
}
.table.timelines_game-editor .table_action-container .action_edit .icon-container {
  align-items: start;
}
.table.timelines_game-editor .table_action-container .action_edit .icon {
  height: 1.6rem;
}
.table.timelines_game-editor .timeline_add-action {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
.table.timelines_game-editor_edit {
  margin-top: 0.4rem;
}

tr {
  border: none;
}

.storyline {
  margin: 1em 0 1em 0.5em;
}
.storyline .icon--dropdown,
.storyline .icon--dropdown-right {
  height: 1.6rem;
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.storyline .icon--selected {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  stroke-width: 2rem;
}
.storyline .name--selected {
  color: var(--color-primary);
  font-weight: 600;
  cursor: pointer;
}
.storyline .icon--dropdown-right {
  transform: rotate(270deg);
}
.storyline_game {
  color: var(--color-grey-very-dark);
  font-size: 1.8rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
}
.storyline_game .game_name {
  position: pointer;
}
.storyline_category, .storyline_chapter, .storyline_scene, .storyline_screen, .storyline_timeline, .storyline_item, .storyline_subitem {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  line-height: 1.7;
  cursor: pointer;
}
.storyline_category--selected, .storyline_chapter--selected, .storyline_scene--selected, .storyline_screen--selected, .storyline_timeline--selected, .storyline_item--selected, .storyline_subitem--selected {
  border-radius: 5px;
  cursor: pointer;
}
.storyline_category, .storyline_chapter, .storyline_scene {
  align-items: flex-start;
}
.storyline_category .category_icon--dropdown,
.storyline_category .chapter_icon--dropdown,
.storyline_category .scene_icon--dropdown, .storyline_chapter .category_icon--dropdown,
.storyline_chapter .chapter_icon--dropdown,
.storyline_chapter .scene_icon--dropdown, .storyline_scene .category_icon--dropdown,
.storyline_scene .chapter_icon--dropdown,
.storyline_scene .scene_icon--dropdown {
  margin-top: 0.5rem;
}
.storyline .chapter_name,
.storyline .scene_name {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  line-height: 1.2;
  margin-top: 0.4rem;
  padding-bottom: 0.5rem;
}
.storyline .storyline_rename-field {
  border: 1px dashed var(--color-grey-very-dark);
  margin: -1px;
}
.storyline_chapter, .storyline_category {
  padding-left: 0.5rem;
  padding-top: 0.2em;
}
.storyline_chapter--placeholder, .storyline_category--placeholder {
  border-bottom: 2px solid var(--color-primary);
  margin: 0 2.3rem;
}
.storyline_scene {
  display: flex;
  margin-left: 2.3rem;
  padding-left: 0.3rem;
}
.storyline_scene--placeholder {
  border-bottom: 2px solid var(--color-primary);
  margin: 0 3.8rem;
}
.storyline_screen, .storyline_timeline {
  display: flex;
  margin-left: 3.8rem;
  padding-left: 0.3rem;
}
.storyline_screen .icon, .storyline_timeline .icon {
  height: 1.5rem;
}
.storyline_screen-thumbnail, .storyline_timeline-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3.6rem;
  cursor: pointer;
}
.storyline_screen-thumbnail .screen_thumbnail,
.storyline_screen-thumbnail .timeline_thumbnail, .storyline_timeline-thumbnail .screen_thumbnail,
.storyline_timeline-thumbnail .timeline_thumbnail {
  width: 8rem;
  width: 6.4rem;
  height: 3.6rem;
  background-color: var(--color-grey-light);
  box-shadow: 0px 0px 0px 1px var(--color-grey-very-dark);
}
.storyline_screen-thumbnail .screen_thumbnail.thumbnail--selected,
.storyline_screen-thumbnail .timeline_thumbnail.thumbnail--selected, .storyline_timeline-thumbnail .screen_thumbnail.thumbnail--selected,
.storyline_timeline-thumbnail .timeline_thumbnail.thumbnail--selected {
  box-shadow: 0px 0px 0px 2px var(--color-primary);
}
.storyline_screen-thumbnail .screen_name,
.storyline_screen-thumbnail .timeline_name, .storyline_timeline-thumbnail .screen_name,
.storyline_timeline-thumbnail .timeline_name {
  margin-bottom: 0.7rem;
  font-size: 1.4rem;
  border: 1px solid transparent;
}
.storyline_item {
  margin-left: 6rem;
  padding-left: 0.3rem;
}
.storyline_item .icon {
  height: 1.5rem;
  stroke-width: 1rem;
}
.storyline_subitem {
  margin-left: 8.3rem;
  padding-left: 0.3rem;
}
.storyline_subitem .icon {
  height: 1.5rem;
  stroke-width: 1rem;
}

.table {
  width: 100%;
}
.table_header {
  text-align: left;
  border-bottom: 1px solid var(--color-grey-very-light);
  font-weight: 600;
  cursor: default;
}
.table_header--center {
  text-align: center;
}
.table_header--right {
  text-align: right;
}
.table_header:not(:last-child) {
  padding-right: 1rem;
}
.table_cell--center {
  text-align: center;
}
.table_cell--right {
  text-align: right;
}
.table_cell:not(:last-child) {
  padding-right: 1rem;
}
.table_cell .icon {
  height: 1.5rem;
}
.table_row--clickable {
  cursor: pointer;
}
.table_row--clickable:hover {
  background-color: var(--color-grey-light);
}

#tooltip {
  position: fixed;
  z-index: 99999;
  background-color: lightyellow;
  border: 1px solid var(--color-grey-very-dark);
  color: var(--color-grey-very-dark);
  padding: 0.2rem 0.5rem;
  font-size: 1.5rem;
  display: none;
}

.explanation {
  font-style: italic;
}

.widget {
  width: 100%;
  font-size: 1.5rem;
  box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
  height: fit-content;
}
.widget_header {
  height: 3rem;
  background-color: var(--color-primary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: default;
}
.widget_content {
  min-height: 5rem;
  background-color: #fff;
  padding: 0.5rem;
  border-right: 1px solid var(--color-grey-very-light);
  border-bottom: 1px solid var(--color-grey-very-light);
  border-left: 1px solid var(--color-grey-very-light);
}
.widget--1 {
  grid-column-end: span 1;
}
.widget--2 {
  grid-column-end: span 2;
}
.widget--3 {
  grid-column-end: span 3;
}
.widget--4 {
  grid-column-end: span 4;
}
.widget--5 {
  grid-column-end: span 5;
}

.dashboard_widget-area .column-group--span-2 {
  grid-column-end: span 2;
}
.dashboard_widget-area .column-group--span-3 {
  grid-column-end: span 3;
}

.widget_active-training .widget_header {
  background-color: var(--color-yellow);
  color: var(--color-grey-very-dark);
  font-weight: 500;
}
.widget_active-training .active-training_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.widget_active-training .active-training_players {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.widget_active-training .active-training_players::-webkit-scrollbar {
  width: 0.5rem;
}
.widget_active-training .active-training_players::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.widget_active-training .active-training_players::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.widget_active-training .active-players_overview {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--color-grey-very-light);
  border-right: 1px solid var(--color-grey-very-light);
}
.widget_active-training .active-players_table .active-players_header,
.widget_active-training .active-players_table .active-players_player-container {
  display: grid;
  grid-template-columns: 3rem 1fr 8rem 8rem 8rem;
  padding: 0.2rem 0 0.2rem 1rem;
}
.widget_active-training .active-players_table .active-players_header {
  font-weight: 600;
  padding: 0.5rem 0 0.5rem 1rem;
}
.widget_active-training .active-players_table .active-players_header--score,
.widget_active-training .active-players_table .active-players_header--percent,
.widget_active-training .active-players_table .active-player_score,
.widget_active-training .active-players_table .active-player_percent {
  justify-self: center;
}
.widget_active-training .active-players_table .active-player_button .btn {
  padding: 0.3rem 2rem;
  font-size: 1.4rem;
  min-width: 7rem;
  margin: 0 0.25rem;
}
.widget_active-training .active-training_score-build {
  align-self: center;
  margin-top: 1rem;
}
.widget_active-training .active-training_score-build .btn--score-build {
  color: var(--color-primary);
  font-weight: 500;
  box-shadow: 0 0 0 2px var(--color-grey-medium);
  border-radius: 5rem;
  width: 18rem;
  padding: 0.3rem 0;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.widget_active-training .active-training_score-build .btn--score-build .icon {
  width: 2rem;
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.widget_active-training .active-training_url {
  display: flex;
}
.widget_active-training .active-training_url .btn--copy-url {
  display: flex;
  column-gap: 1rem;
  width: 80%;
  border: 1px solid var(--color-grey-very-light);
  padding: 0.5rem 1rem;
  margin: 1rem 0.5rem 1rem 1rem;
  border-radius: 5rem;
  box-shadow: 0 0 0 1px var(--color-grey-light);
}
.widget_active-training .active-training_url .btn--copy-url .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.widget_active-training .active-training_url .btn--copy-url .form_label {
  color: var(--color-primary);
  padding-top: 0.2rem;
}
.widget_active-training .active-training_url .btn--view-url .icon {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.widget_active-training .active-training_notes {
  margin: 0.5rem 0 0 1rem;
  display: flex;
  flex-direction: column;
}
.widget_active-training .active-training_notes .active-training_notes-field {
  overflow-y: auto;
  overflow-x: hidden;
  height: 10rem;
  border: 1px solid var(--color-grey-very-light);
  padding: 0.2rem;
}
.widget_active-training .active-training_notes .active-training_notes-field::-webkit-scrollbar {
  width: 0.5rem;
}
.widget_active-training .active-training_notes .active-training_notes-field::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.widget_active-training .active-training_notes .active-training_notes-field::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.widget_active-training .active-training_finish-training {
  justify-self: center;
  align-self: center;
}
.widget_active-training .active-training_finish-training .btn {
  background-color: red;
  color: white;
  padding: 0.3rem 0;
  width: 14rem;
  margin: 1rem 0;
}
.widget_active-training .active-training_dashboard-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  width: 100%;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard {
  display: flex;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard .active-training_dashboard-live {
  background-color: var(--color-grey-very-dark);
  width: 100%;
  aspect-ratio: 16/9;
  margin: 1rem 0 0 1rem;
  display: flex;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard .icon-container {
  align-items: flex-end;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard .btn--tab {
  width: 4rem;
  height: 4rem;
  align-self: flex-end;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard .btn--tab .icon {
  height: 2.6rem;
  width: 2.6rem;
  fill: var(--color-grey-dark);
  stroke: var(--color-grey-dark);
  stroke-width: 0;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls {
  width: 91.2%;
  margin-left: 1rem;
  height: 11rem;
  background-color: var(--color-grey-very-dark);
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds {
  padding: 0.5rem 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  color: white;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds .icon {
  fill: white;
  stroke: white;
  height: 2.2rem;
  width: 2.2rem;
  stroke-width: 0;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds :disabled .icon {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds .dashboard-controls_left {
  transform: rotate(180deg);
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds .dashboard-controls_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds .dashboard-controls_header .dashboard-controls_name {
  font-weight: 500;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_rounds .dashboard-controls_header .dashboard-controls_time {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_button .btn {
  width: 15rem;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_button .btn--orange {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_button .btn--green:active {
  filter: none;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_button .btn--green:hover {
  cursor: default;
  filter: none;
}
.widget_active-training .active-training_dashboard-container .active-training_dashboard-controls .dashboard-controls_finish {
  color: var(--color-grey-very-light);
  text-align: end;
  padding-right: 1rem;
  margin-top: -1rem;
  text-decoration: underline;
  font-size: 1rem;
  margin-right: 0.2rem;
}

.popup_finish-training.popup {
  width: 60rem;
}
.popup_finish-training ul li {
  list-style-type: disc;
  margin-left: 3rem;
}
.popup_finish-training p {
  margin-bottom: 0.5rem;
}

.widget_articles .icon {
  height: 1.8rem;
}
.widget_articles .widget_header {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 2rem;
}
.widget_articles .widget_header-title {
  justify-self: center;
}
.widget_articles .widget_header .icon {
  fill: white;
  stroke: white;
  height: 1.8rem;
}
.widget_articles td {
  vertical-align: middle;
}
.widget_articles .table {
  table-layout: fixed;
}
.widget_articles .table-container {
  padding: 0.5rem;
  cursor: pointer;
}
.widget_articles .table .column_created,
.widget_articles .table .column_edited {
  width: 10rem;
}
.widget_articles .table .column_title {
  width: 30%;
}
.widget_articles .table .column_tags {
  width: 20%;
}
.widget_articles .table .column_icons {
  width: 10%;
}
.widget_articles .table .column_buttons {
  width: 20%;
}
.widget_articles .article_buttons {
  margin: 0.2rem 0;
}
.widget_articles .article_buttons .btn {
  font-size: 1.4rem;
  margin: 0 0.25rem;
  min-width: 7rem;
  padding: 0.2rem 0.7rem;
}

.add-article_popup .popup {
  max-width: 95vw;
  max-height: 100vh;
  width: 85vw;
}
.add-article_popup .popup_main {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.add-article_popup .popup_main .article_top-container {
  position: relative;
  display: flex;
  column-gap: 2rem;
}
.add-article_popup .popup_main .article_top-container .form_field {
  width: 50rem;
}
.add-article_popup .popup_main .article_top-container .filemanager_link {
  position: absolute;
  bottom: 0;
  right: 1rem;
  color: var(--color-primary);
  text-decoration: underline;
}
.add-article_popup .popup_main .add-article_content {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.5rem;
  height: 45rem;
}
.add-article_popup .popup_main .add-article_content .add-article_html-container {
  min-height: 45rem;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html-container::-webkit-scrollbar {
  width: 0.5rem !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light) !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6) !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html {
  background-color: black;
  color: white;
  font-family: Menlo, Consolas, "Courier New", Courier, monospace;
  tab-size: 4;
  min-height: 45rem;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html::-webkit-scrollbar {
  width: 0.5rem !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light) !important;
}
.add-article_popup .popup_main .add-article_content .add-article_html::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6) !important;
}
.add-article_popup .popup_main .add-article_content .add-article_preview {
  width: 45rem;
  border: 1px solid var(--color-grey-light);
  overflow-y: auto;
  overflow-x: hidden;
}
.add-article_popup .popup_main .add-article_content .add-article_preview::-webkit-scrollbar {
  width: 0.5rem;
}
.add-article_popup .popup_main .add-article_content .add-article_preview::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.add-article_popup .popup_main .add-article_content .add-article_preview::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}

.widget_averages {
  grid-row: 1;
  grid-column: 4;
  cursor: default;
}
.widget_averages .popup_session_details--itemclick .popup_session_details--action {
  padding-left: 1.2rem;
}

.widget_dashboard-trainer {
  grid-row: 1;
  grid-column: 4;
  margin-top: 14rem;
  cursor: default;
}
.widget_dashboard-trainer .widget_content {
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget_dashboard-trainer .widget_content .link {
  color: var(--color-grey-very-dark);
  text-decoration: underline;
  font-size: 1.5rem;
}

.widget_games {
  grid-column-start: 4;
  margin-bottom: 4rem;
  cursor: default;
}
.widget_games td {
  vertical-align: middle;
  padding-top: 0.2rem;
}
.widget_games .icon {
  height: 1.6rem;
}
.widget_games .popup {
  min-width: 45rem;
  width: 45rem;
}

.widget_media-usage .media-usage_folders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.8rem;
}
.widget_media-usage .media-usage_header-container, .widget_media-usage .media-usage_audio-container, .widget_media-usage .media-usage_documents-container, .widget_media-usage .media-usage_images-container, .widget_media-usage .media-usage_video-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}
.widget_media-usage .media-usage_header-container {
  font-weight: 600;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.widget_media-usage .media-usage_folder {
  justify-self: flex-start;
}
.widget_media-usage .media-usage_size {
  justify-self: end;
}
.widget_media-usage .media-usage_totals {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: end;
}
.widget_media-usage .media-usage_totals--labels {
  width: 100%;
  grid-column: 1/span 2;
  justify-self: end;
  border-top: 1px solid var(--color-primary-light);
  padding-top: 0.5rem;
}
.widget_media-usage .media-usage_totals--numbers {
  border-top: 1px solid var(--color-primary-light);
  padding-top: 0.5rem;
}
.widget_media-usage .media-usage_totals--percentages {
  justify-self: start;
  margin-left: 2rem;
  padding-top: 0.7rem;
}
.widget_media-usage .btn {
  padding: 0.1rem 0.7rem;
  font-size: 1.4rem;
  min-width: 7rem;
}

.widget_organizations .icon {
  height: 1.6rem;
}
.widget_organizations td {
  vertical-align: middle;
}

.widget_played-games {
  grid-row: 1/-1;
  display: grid;
  grid-template-rows: auto 1fr;
}
.widget_played-games .widget_content {
  padding: 1rem;
  height: 33rem;
}
.widget_played-games .played-games_selection-container {
  display: flex;
  justify-content: center;
  gap: 8rem;
}
.widget_played-games .played-games_selection-container .form_dropdown {
  position: relative;
}
.widget_played-games .played-games_selection-container .form_field {
  padding-right: 5rem;
}
.widget_played-games .played-games_selection-container .select-game .form_field {
  width: fit-content;
  border: none;
}
.widget_played-games .played-games_selection-container .select-year .form_field {
  width: 14rem;
  padding-right: 5rem;
}
.widget_played-games .played-games_stats-bars,
.widget_played-games .played-games_months,
.widget_played-games .stats-bar_started,
.widget_played-games .stats-bar_finished {
  display: grid;
  grid-template-columns: 5rem 3rem 1fr 5rem 10rem;
  cursor: default;
}
.widget_played-games .played-games_stats-bars {
  margin-top: 2rem;
}
.widget_played-games .played-games_table {
  margin-top: 1rem;
}
.widget_played-games .stats-bar_y-label {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  justify-self: end;
  padding-top: 0.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin-right: 1rem;
}
.widget_played-games .stats-bar_y-values {
  display: grid;
  padding-right: 1rem;
}
.widget_played-games .stats-bar_y-values .y-value {
  align-self: start;
  text-align: right;
  padding: 0 0 1.5rem 0;
}
.widget_played-games .stats-bar_bars-container {
  display: flex;
}
.widget_played-games .stats-bar_bars-container .stats-bar_bar-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.widget_played-games .stats-bar_bars-container .stats-bar_bar-container .stats-bar_bar {
  width: 50%;
  background-color: #e16123;
  background-color: var(--color-primary);
  align-self: center;
  justify-content: center;
}
.widget_played-games .stats-bar_months-container {
  grid-column: 3;
  display: grid;
  grid-template-columns: repeat(12, 2fr);
  justify-items: center;
  color: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.widget_played-games .stats-bar_table--labels {
  grid-column: 1/span 2;
}
.widget_played-games .stats-bar_started, .widget_played-games .stats-bar_finished {
  align-items: center;
}
.widget_played-games .stats-bar_started--label, .widget_played-games .stats-bar_finished--label {
  grid-column: 1/span 2;
  font-size: 1.4rem;
  font-weight: 500;
}
.widget_played-games .stats-bar_started--numbers-container, .widget_played-games .stats-bar_finished--numbers-container {
  display: grid;
  grid-template-columns: repeat(12, 2fr);
  justify-items: center;
  padding: 0.5rem 0;
}
.widget_played-games .stats-bar_started--numbers-container {
  border: 1px solid var(--color-grey-very-light);
}
.widget_played-games .stats-bar_finished--numbers-container {
  border-left: 1px solid var(--color-grey-very-light);
  border-right: 1px solid var(--color-grey-very-light);
  border-bottom: 1px solid var(--color-grey-very-light);
}
.widget_played-games .stats-bar_total {
  text-align: right;
  padding-right: 1rem;
  font-weight: 600;
  align-self: center;
}
.widget_played-games .stats-bar_total--label {
  padding-left: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.widget_sessions .widget_header {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 2rem;
}
.widget_sessions .widget_header--title {
  justify-self: center;
}
.widget_sessions .widget_header .icon {
  fill: white;
  stroke: white;
}
.widget_sessions .widget_content {
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.widget_sessions .widget_content::-webkit-scrollbar {
  width: 0.5rem;
}
.widget_sessions .widget_content::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.widget_sessions .widget_content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.widget_sessions .widget_content .widget_sessions--intro {
  text-align: center;
  font-style: italic;
  padding-bottom: 0.5rem;
  cursor: default;
}

.popup_statistics--download {
  width: 50rem;
}
.popup_statistics--download .statistics-download_player-actions label {
  font-size: initial;
}

.widget_training-files {
  grid-column-start: 5;
  grid-row: span 3;
  overflow-y: auto;
  overflow-x: hidden;
}
.widget_training-files::-webkit-scrollbar {
  width: 0.5rem;
}
.widget_training-files::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.widget_training-files::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.widget_training-files .widget_header {
  display: flex;
  text-align: center;
  position: relative;
}
.widget_training-files .widget_header .icon-container {
  position: absolute;
  right: 1rem;
}
.widget_training-files .widget_header .icon-container .icon {
  height: 1.8rem;
  fill: white;
  stroke: white;
  stroke-width: 0;
}
.widget_training-files .training-files_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  padding: 0.5rem;
}
.widget_training-files .training-files_content .training-file_container {
  position: relative;
  display: grid;
  grid-template-columns: 6.5rem auto;
  column-gap: 0.5rem;
  row-gap: 0.3rem;
  background-color: rgb(244, 244, 244);
  padding: 0.2rem;
  width: 100%;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .image {
  position: relative;
  height: 5rem;
  width: 6.5rem;
  background-color: white;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .image .icon {
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .image .icon--pdf {
  height: 3.5rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container {
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
  margin-top: -2rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container .btn--blue {
  opacity: 0.95;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container .btn--blue .icon {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container .btn--icon-mini {
  height: 2.5rem;
  width: 2.5rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container .btn--icon-mini .icon {
  height: 1.3rem;
  margin-top: 0.6rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_image .button-container .btn--icon-mini .icon--download {
  height: 1.8rem;
  margin-top: 0.4rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_description {
  font-size: 1.4rem;
  white-space: pre-line;
}
.widget_training-files .training-files_content .training-file_container .training-file_name {
  grid-column: 1/-1;
  font-size: 1.4rem;
  font-style: italic;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit .btn--icon-mini {
  display: none;
  height: 3.5rem;
  width: 3.5rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit .btn--icon-mini .icon {
  height: 1.6rem;
  margin-top: 0.5rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit .btn--icon-mini.btn--orange {
  margin-left: 5rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit .btn--icon-mini.btn--grey {
  height: 3rem;
  width: 3rem;
}
.widget_training-files .training-files_content .training-file_container .training-file_edit .btn--icon-mini.btn--grey .icon {
  height: 1.6rem;
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}
.widget_training-files .training-files_content .training-file_container .training-file_edit:hover {
  border: 1px solid var(--color-primary);
}
.widget_training-files .training-files_content .training-file_container .training-file_edit:hover .btn--icon-mini {
  display: block;
}
.widget_training-files .training-files_content .training-file_container--edit {
  border: 1px solid var(--color-primary);
  cursor: pointer;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_edit {
  display: none;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_image .btn--orange {
  opacity: 0.95;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_image .btn--orange .icon {
  fill: white;
  stroke: white;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_description {
  background-color: white;
  height: fit-content;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_description .description_edit {
  width: 100%;
  cursor: auto;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_save {
  position: absolute;
  top: -2.3rem;
  right: -0.1rem;
  z-index: 1;
}
.widget_training-files .training-files_content .training-file_container--edit .training-file_save .btn.btn--close {
  font-size: 1.2rem;
  padding: 0.4rem 1.2rem 0.1rem 1.2rem;
  border-radius: 0;
}
.widget_training-files .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget_training-groups {
  display: flex;
  flex-direction: column;
  cursor: default;
}
.widget_training-groups .training-groups_header {
  font-weight: 600;
  padding: 0.5rem 0 0.5rem 1rem;
  border-bottom: 1px solid var(--color-grey-very-light);
  display: grid;
  grid-template-columns: 12% 1fr 10% 10% 27rem;
}
.widget_training-groups .training-groups_header--number, .widget_training-groups .training-groups_header--finished {
  justify-self: center;
}
.widget_training-groups .training-groups_content {
  margin-bottom: 0.5rem;
}
.widget_training-groups .btn {
  align-self: center;
  margin-bottom: 1rem;
}
.widget_training-groups .training-groups_group-container {
  padding: 0.2rem 0 0.2rem 1rem;
  display: grid;
  grid-template-columns: 12% 1fr 10% 10% 27rem;
}
.widget_training-groups .training-groups_group-container.active {
  background-color: var(--color-grey-very-light);
}
.widget_training-groups .training-groups_group-container .training-group_number,
.widget_training-groups .training-groups_group-container .training-group_finished {
  justify-self: center;
}
.widget_training-groups .training-groups_group-container .training-group_finished .form_checkbox {
  margin-top: 0;
}
.widget_training-groups .training-groups_group-container .training-group_finished .form_checkbox .icon--checkbox {
  height: 1.6rem;
  width: 1.6rem;
  cursor: default;
}
.widget_training-groups .training-groups_group-container .training-group_button-container {
  display: flex;
  margin-left: 2rem;
}
.widget_training-groups .training-groups_group-container .training-group_buttons {
  display: flex;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn {
  padding: 0.3rem 1rem;
  font-size: 1.4rem;
  min-width: 7rem;
  margin: 0 0.25rem;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn--running, .widget_training-groups .training-groups_group-container .training-group_buttons .btn--start {
  width: 13rem;
  margin-left: 1rem;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn--running {
  background-color: #fff0c9;
  box-shadow: inset 0 0 0 2px var(--color-yellow);
  color: var(--color-grey-medium);
  font-style: italic;
  cursor: default;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn--eye {
  margin-left: 0.2rem;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn--eye p {
  padding-left: 0.2rem;
  color: var(--color-grey-medium);
  font-style: italic;
  font-size: 1.2rem;
  padding-top: 0.1rem;
}
.widget_training-groups .training-groups_group-container .training-group_buttons .btn--eye .icon {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
  height: 2.2rem;
}

.popup_training-group.popup {
  width: 100rem;
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 90vh;
}
.popup_training-group .popup_main {
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  grid-template-columns: 45rem 1fr;
}
.popup_training-group .popup_main::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.popup_training-group .popup_main::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.popup_training-group .popup_main::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.popup_training-group .popup_main .training-group_popup-left {
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_form-container {
  display: flex;
  column-gap: 2rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_form-container .training-group_number .form_field {
  width: 5rem;
  border: 1px solid var(--color-grey-light);
  background-color: var(--color-grey-very-light);
}
.popup_training-group .popup_main .training-group_popup-left .training-group_trainer,
.popup_training-group .popup_main .training-group_popup-left .training-group_container,
.popup_training-group .popup_main .training-group_popup-left .training-group_notes {
  margin-top: 3rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_date .form_field {
  width: 15rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_container {
  display: flex;
  column-gap: 2rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_finished {
  align-self: flex-end;
  margin-right: 5rem;
  justify-self: end;
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  column-gap: 0.5rem;
  margin-bottom: 0.2rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_notes .training-group_notes-field {
  width: 100%;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--color-grey-very-light);
  border-bottom: 1px solid var(--color-primary);
  padding: 0.5rem;
  height: 10rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_notes .training-group_notes-field::-webkit-scrollbar {
  width: 0.5rem;
}
.popup_training-group .popup_main .training-group_popup-left .training-group_notes .training-group_notes-field::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.popup_training-group .popup_main .training-group_popup-left .training-group_notes .training-group_notes-field::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.popup_training-group .popup_main .training-group_popup-left .btn--delete {
  background-color: red;
  color: white;
  padding: 0.3rem 0;
  width: 22rem;
  justify-self: flex-end;
  align-self: center;
  margin-top: 3rem;
}
.popup_training-group .popup_main .training-group_popup-right {
  border-left: 1px solid var(--color-grey-very-light);
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_url-container {
  display: flex;
  flex-direction: column;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_url-container .training-group_url {
  display: flex;
  column-gap: 1rem;
  background-color: #ffe6d9;
  padding: 0.3rem;
  border-radius: 2px;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_url-container .training-group_url .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.popup_training-group .popup_main .training-group_popup-right .training-group_url-container .training-group_url span {
  word-break: break-all;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_no-results {
  margin-top: 4rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_no-results p {
  height: 25rem;
  background-color: var(--color-grey-very-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container {
  margin-top: 4rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header label {
  font-weight: 800;
  font-size: 1.7rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header .btn_score-build {
  margin-left: auto;
  margin-right: 3rem;
  align-self: start;
  justify-self: end;
  color: var(--color-primary);
  font-weight: 500;
  box-shadow: 0 0 0 2px var(--color-grey-light);
  border-radius: 5rem;
  width: 21rem;
  padding: 0.3rem 0;
  display: flex;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header .btn_score-build p {
  padding-top: 0.3rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header .btn_score-build .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header .btn_delete-scores {
  stroke-width: 0;
  align-self: center;
  margin-right: 0.5rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .results_header .btn_delete-scores .icon {
  height: 1.8rem;
  width: 1.8rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container {
  display: flex;
  flex-direction: column;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_players {
  overflow-y: auto;
  overflow-x: hidden;
  height: 28rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_players::-webkit-scrollbar {
  width: 0.5rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_players::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_players::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_player {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 1rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_player .player_score {
  border: 1px solid var(--color-grey-light);
  padding: 0.6rem 1rem;
  width: 10rem;
  background-color: var(--color-grey-very-light);
  text-align: center;
  font-weight: 500;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_player .player_view {
  margin-right: 2rem;
}
.popup_training-group .popup_main .training-group_popup-right .training-group_results-container .training-group_scores-container .training-group_player .player_view .btn {
  padding: 0.3rem 1.6rem;
  margin-bottom: 0;
}
.popup_training-group .popup_main .training-group_popup-right .btn-container {
  margin-top: auto;
  margin-bottom: 0;
  height: fit-content;
}
.popup_training-group .finished-1 {
  vertical-align: super;
  font-size: 1.2rem;
  color: var(--color-primary);
}
.popup_training-group .training-group_finished-1 {
  margin-top: 2rem;
  font-style: italic;
  font-size: 1.4rem;
  padding: 0 0 1rem 0;
  align-self: flex-start;
}
.popup_training-group .btn-container {
  margin-left: auto;
  height: fit-content;
}
.popup_training-group .btn-container .btn {
  align-self: end;
  justify-self: end;
}

.popup_delete-results ul li {
  list-style-type: disc;
  margin-left: 3rem;
}
.popup_delete-results p {
  margin-bottom: 0.5rem;
}
.popup_delete-results .popup_main {
  display: block;
}

.popup_delete-training-group.popup {
  text-align: center;
}

.widget_users {
  cursor: default;
}
.widget_users td span {
  display: block;
}
.widget_users--name {
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.widget_users--role {
  max-width: 40rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.widget_users--game {
  max-width: 20rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.widget_users--active {
  max-width: 8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.wysiwyg-bar {
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
  color: var(--color-grey-very-dark);
  font-size: 1.5rem;
}
.wysiwyg-bar .icon {
  height: 2rem;
  fill: white;
  stroke: white;
}
.wysiwyg-bar .active.wysiwyg-bar_icon {
  border: 1px solid var(--color-grey-medium);
  border-radius: 0;
  background-color: rgba(211, 211, 211, 0.5);
  border-radius: 6px;
}
.wysiwyg-bar .form_dropdown .icon--dropdown {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
  top: 0.7rem;
}
.wysiwyg-bar .form_field,
.wysiwyg-bar .form_dropdown-options {
  border: none;
}
.wysiwyg-bar .form_dropdown-option {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
}
.wysiwyg-bar_dropdown--style .form_dropdown {
  margin-left: -0.2rem;
}
.wysiwyg-bar_dropdown--style .form_dropdown-options {
  padding: 0.5rem;
}
.wysiwyg-bar_dropdown--style .form_dropdown-options .form_dropdown-option {
  padding: 0;
  padding-left: 0.5rem;
  margin: 0.5rem;
  margin-left: 0;
  display: flex;
  row-gap: 1rem;
  text-align: left;
}
.wysiwyg-bar_dropdown--style .form_dropdown-options .form_dropdown-option--selected {
  background-color: white;
  border-left: 3px solid var(--color-primary);
}
.wysiwyg-bar_dropdown--style .form_dropdown-options .form_dropdown-option--selected:hover {
  background-color: var(--color-grey-very-light);
}
.wysiwyg-bar_dropdown--style .wysiwyg-bar_style--small {
  font-size: 0.75em;
}
.wysiwyg-bar_dropdown--style .wysiwyg-bar_style--normal {
  font-size: 1em;
}
.wysiwyg-bar_dropdown--style .wysiwyg-bar_style--large {
  font-size: 1.5em;
}
.wysiwyg-bar_dropdown--style .wysiwyg-bar_style--XL {
  font-size: 2.5em;
}
.wysiwyg-bar_dropdown--style .wysiwyg-bar_style--XXL {
  font-size: 4.5em;
}
.wysiwyg-bar_dropdown--align .form_field, .wysiwyg-bar_dropdown--valign .form_field {
  padding: 0 0.2rem;
}
.wysiwyg-bar_dropdown--align .form_field,
.wysiwyg-bar_dropdown--align .form_dropdown-options, .wysiwyg-bar_dropdown--valign .form_field,
.wysiwyg-bar_dropdown--valign .form_dropdown-options {
  width: 5.5rem;
  border-radius: 0;
  background-color: var(--color-grey-very-dark);
  fill: white;
  stroke: white;
}
.wysiwyg-bar_dropdown--align .form_dropdown-option--selected, .wysiwyg-bar_dropdown--valign .form_dropdown-option--selected {
  border: 1px solid var(--color-grey-medium);
  border-radius: 0;
  background-color: rgba(211, 211, 211, 0.5);
}
.wysiwyg-bar_dropdown--align .icon-container .icon--dropdown, .wysiwyg-bar_dropdown--valign .icon-container .icon--dropdown {
  fill: white;
  stroke: white;
  margin-right: 0.5rem;
}
.wysiwyg-bar .colorpicker {
  display: flex;
}
.wysiwyg-bar_icon--bold {
  margin-left: 1rem;
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--italic {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--underline {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--text-color {
  margin: 0 1rem;
}
.wysiwyg-bar_icon--marker .icon--marker {
  height: 1.6rem;
  margin-bottom: 0.1rem;
}
.wysiwyg-bar_icon--align-left {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-center {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-right {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-justify {
  padding-right: 1rem;
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-top {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-middle {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--align-bottom {
  padding-right: 1rem;
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--list-bullets {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--list-numbers {
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
}
.wysiwyg-bar_icon--link {
  padding: 0 1rem;
  border: 1px solid var(--color-grey-very-dark);
  padding: 0.4rem;
  margin-right: 0.5rem;
}
.wysiwyg-bar_icon--background-color {
  padding-left: 1rem;
  border-left: 1px solid var(--color-grey-medium);
}
.wysiwyg-bar_line-style-container .dropdownbutton .icon.icon--line-style {
  stroke-width: 0px;
}
.wysiwyg-bar_line-style-container .icon--line-thick {
  stroke-width: 4rem;
}
.wysiwyg-bar_border-radius-container .dropdownbutton-options .dropdownbutton-option .icon--border-sharp {
  height: 1.5rem;
  stroke-width: 5rem;
  transform: rotate(270deg);
}
.wysiwyg-bar_border-radius-container .dropdownbutton-options .dropdownbutton-option .icon--border-round {
  transform: rotate(270deg);
}
.wysiwyg-bar_border-radius-container .dropdownbutton-options .icon-container {
  width: 2rem;
}
.wysiwyg-bar_container {
  display: flex;
}
.wysiwyg-bar_icon--nickname {
  border-left: 1px solid var(--color-grey-medium);
  padding-left: 0.5rem;
  margin-left: 0.2rem;
}

.dropdownbutton-container {
  position: relative;
}
.dropdownbutton-container .dropdownbutton {
  display: flex;
}
.dropdownbutton-container .dropdownbutton-options {
  padding: 0.5rem 0;
  width: 16rem;
  position: absolute;
  right: -8rem;
  top: 3.5rem;
  color: white;
  background-color: var(--color-grey-very-dark);
  z-index: 9000;
}
.dropdownbutton-container .dropdownbutton-options .dropdownbutton-option {
  display: flex;
  column-gap: 1rem;
  padding: 0 0.5rem;
  cursor: pointer;
}
.dropdownbutton-container .dropdownbutton-options .dropdownbutton-option--selected {
  border: 1px solid var(--color-grey-medium);
  border-radius: 0;
  background-color: rgba(211, 211, 211, 0.5);
}
.dropdownbutton-container .dropdownbutton-options .icon-container {
  width: 2rem;
}

.breadcrumbs {
  padding: 0 0.8rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  height: 6rem;
}
.breadcrumbs_icon--home {
  width: 3.2rem;
  fill: var(--color-blue);
  cursor: pointer;
}
.breadcrumbs_icon--home:hover {
  cursor: pointer;
  filter: brightness(0.8);
}
.breadcrumbs-container {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
}
.breadcrumbs_text {
  color: white;
}
.breadcrumbs_text--number {
  margin-right: 0.5rem;
}
.breadcrumbs_haakje {
  color: white;
}
.breadcrumbs .btn-container {
  margin-left: auto;
}

.footer {
  min-height: 2.6rem;
  color: white;
  background-color: var(--color-grey-dark);
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto 32rem;
  padding: 0.2rem 0;
  align-items: center;
}
.footer_numbers {
  margin-left: 0.5rem;
  font-size: 1.6rem;
  display: flex;
  gap: 0.8rem;
  cursor: default;
}
.footer_numbers .footer_number {
  display: flex;
  gap: 0.4rem;
}
.footer_numbers .footer_number .footer_number_value {
  color: var(--color-green-light);
}
.footer_zoom {
  display: flex;
  column-gap: 1rem;
}
.footer_zoom .zoom-percent {
  align-self: center;
  width: 4rem;
  text-align: center;
  cursor: default;
}
.footer_zoom .zoom-slider {
  width: 15rem;
}
.footer_zoom input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: white;
  border-radius: 25px;
  border: 0px solid #000101;
}
.footer_zoom input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  height: 1.2rem;
  width: 3rem;
  border-radius: 7px;
  background: #a3a1a1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.6px;
}
.footer_zoom input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--color-grey-very-light);
}
.footer_zoom .icon {
  fill: white;
  height: 1.2rem;
}
.footer_zoom .icon-container:last-of-type {
  margin-left: 0.5rem;
}
.footer_zoom .zoom-to-fit {
  margin-left: 0.5rem;
  padding: 0 0.2rem;
}
.footer_zoom .zoom-to-fit--active {
  padding: 0 0.2rem;
  background-color: var(--color-grey-medium);
  border-radius: 0.2rem;
}
.footer_zoom .icon--zoom-to-fit {
  stroke-width: 0;
  height: 2rem;
}
.footer_saved {
  margin-left: auto;
  margin-right: 10rem;
  display: flex;
  column-gap: 0.2rem;
  align-items: flex-end;
  justify-content: flex-end;
}
.footer_saved-text {
  color: var(--color-green-light);
  font-size: 1.6rem;
  cursor: default;
}
.footer_saved .icon-container {
  align-self: flex-start;
}
.footer_icon--saved {
  fill: var(--color-green-light);
  height: 2.5rem;
}

.header {
  padding: 0 0.8rem;
  display: grid;
  grid-template-columns: 20rem 1fr 20rem;
  align-items: center;
}
.header_left-container a {
  display: flex;
  align-items: center;
}
.header_right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5rem;
  cursor: pointer;
}
.header_right-container .header_help {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
}
.header_right-container .header_help .icon {
  fill: var(--color-blue);
  stroke: var(--color-blue);
  height: 3rem;
}

.logo_img {
  width: 3.2rem;
  margin-right: 1rem;
}

.logo_text {
  font-weight: 400;
  color: white;
  font-family: "inter";
  font-size: 1.5rem;
}

.header_item-bar {
  background-color: var(--color-primary);
  height: 6rem;
  width: 80rem;
  justify-self: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.header_help {
  color: white;
}
.header_login .icon--avatar {
  height: 4.2rem;
  fill: var(--color-blue);
  align-self: center;
}
.header_login .dropdownbutton-options {
  top: 4.2rem;
  right: 0;
  border: 1px solid var(--color-grey-dark);
  color: white;
}
.header_login .dropdownbutton-options .dropdownbutton-option:hover {
  color: var(--color-primary);
}

.page {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr) auto;
  background-color: var(--color-grey-very-dark);
  overflow-y: auto;
  overflow-x: auto;
}
.page::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.page::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.page::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.page_content {
  position: relative;
  height: calc(100vh - 15.5rem);
  display: grid;
  grid-template-columns: 24rem 1fr;
  padding: 0 0.5rem;
}

.sidebar {
  height: calc(100vh - 15.5rem);
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.sidebar_header {
  height: 4rem;
  background-color: var(--color-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  cursor: default;
}

.contextmenu-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
.contextmenu-container .contextmenu-options {
  padding: 0.5rem 0;
  width: 16rem;
  position: absolute;
  color: white;
  background-color: var(--color-grey-very-dark);
  z-index: 9000;
}
.contextmenu-container .contextmenu-options .contextmenu-option {
  display: flex;
  column-gap: 1rem;
  padding: 0 0.5rem;
  cursor: pointer;
}
.contextmenu-container .contextmenu-options .contextmenu-option:hover {
  color: var(--color-primary);
}
.contextmenu-container .contextmenu-options .contextmenu-option--selected {
  border: 1px solid var(--color-grey-medium);
  border-radius: 0;
  background-color: rgba(211, 211, 211, 0.5);
}
.contextmenu-container .contextmenu-options .icon-container {
  width: 2rem;
}

.page-not-found {
  background-image: url(404background5.jpg);
  background-size: cover;
}

.page-not-found_container {
  display: flex;
  flex-direction: column;
  margin-left: 25%;
  margin-top: 10%;
  width: 60rem;
  height: min-content;
  background-color: rgba(255, 255, 255, 0.421);
  color: rgb(48, 48, 48);
  text-align: center;
  padding: 2rem;
}

.page-not-found_number {
  font-weight: 700;
  font-size: 6rem;
  margin: 5rem;
}

.page-not-found_text {
  font-size: 4rem;
  margin: 0;
}

.widget_organizations .table-container {
  padding: 0.5rem;
  cursor: pointer;
}
.widget_news--SA {
  grid-column-start: 5;
}

.add-organization_popup .popup {
  width: 120rem;
}

.add-game_popup .popup {
  width: 80rem;
}

.add-organization_popup .popup_header,
.add-game_popup .popup_header {
  font-size: 1.8rem;
}
.add-organization_popup .popup_main,
.add-game_popup .popup_main {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}
.add-organization_popup .popup_main .content-container_organization,
.add-game_popup .popup_main .content-container_organization {
  grid-template-columns: 34rem 34rem 34rem;
}
.add-organization_popup .popup_main .content-container_game,
.add-game_popup .popup_main .content-container_game {
  grid-template-columns: 34rem 34rem;
}
.add-organization_popup .popup_main .content-container,
.add-game_popup .popup_main .content-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  display: grid;
  column-gap: 5rem;
  align-items: start;
  padding-top: 1rem;
}
.add-organization_popup .popup_main .content-container::-webkit-scrollbar,
.add-game_popup .popup_main .content-container::-webkit-scrollbar {
  width: 0.5rem;
}
.add-organization_popup .popup_main .content-container::-webkit-scrollbar-track,
.add-game_popup .popup_main .content-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.add-organization_popup .popup_main .content-container::-webkit-scrollbar-thumb,
.add-game_popup .popup_main .content-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.add-organization_popup .popup_main .content-container .widget_header,
.add-game_popup .popup_main .content-container .widget_header {
  height: 4rem;
}
.add-organization_popup .popup_main .content-container .widget_content,
.add-game_popup .popup_main .content-container .widget_content {
  border: 1px solid var(--color-primary-light);
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding: 1rem 3rem 1rem 3rem;
}
.add-organization_popup .popup_main .content-container .organization_notes .form_field,
.add-organization_popup .popup_main .content-container .game_notes .form_field,
.add-game_popup .popup_main .content-container .organization_notes .form_field,
.add-game_popup .popup_main .content-container .game_notes .form_field {
  height: 10rem;
  max-width: 28rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.add-organization_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar,
.add-organization_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar,
.add-game_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar,
.add-game_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar {
  width: 0.5rem;
}
.add-organization_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar-track,
.add-organization_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar-track,
.add-game_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar-track,
.add-game_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.add-organization_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar-thumb,
.add-organization_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar-thumb,
.add-game_popup .popup_main .content-container .organization_notes .form_field::-webkit-scrollbar-thumb,
.add-game_popup .popup_main .content-container .game_notes .form_field::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.add-organization_popup .popup_main .content-container .organization_center-container,
.add-game_popup .popup_main .content-container .organization_center-container {
  display: flex;
  flex-direction: column;
  gap: 4.9rem;
}
.add-organization_popup .popup_main .content-container .game_settings .form_checkbox,
.add-game_popup .popup_main .content-container .game_settings .form_checkbox {
  margin-top: -2.5rem;
  align-self: end;
}
.add-organization_popup .popup_main .content-container .game_contact .form_checkbox,
.add-organization_popup .popup_main .content-container .organization_admin .form_checkbox,
.add-organization_popup .popup_main .content-container .organization_contact .form_checkbox,
.add-game_popup .popup_main .content-container .game_contact .form_checkbox,
.add-game_popup .popup_main .content-container .organization_admin .form_checkbox,
.add-game_popup .popup_main .content-container .organization_contact .form_checkbox {
  margin-top: -0.5rem;
}
.add-organization_popup .popup .btn-container,
.add-game_popup .popup .btn-container {
  margin-right: 3rem;
}

.users_popup .popup {
  width: 100%;
  height: 100%;
}
.users_popup .popup_header {
  font-size: 1.8rem;
}
.users_popup .popup_main {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}
.users_popup .popup_main .content-container {
  overflow-y: auto;
  overflow-x: auto;
  height: fit-content;
  padding-top: 1rem;
  width: 100%;
}
.users_popup .popup_main .content-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.users_popup .popup_main .content-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.users_popup .popup_main .content-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.users_popup .popup_main .widget_header {
  height: 4rem;
}
.users_popup .popup_main .users_content {
  border-right: 1px solid var(--color-primary-light);
  border-bottom: 1px solid var(--color-primary-light);
  border-left: 1px solid var(--color-primary-light);
}
.users_popup .popup_main .users_content .users_header,
.users_popup .popup_main .users_content .users_user-container {
  display: grid;
  grid-template-columns: 20rem 1fr 14rem 14rem 10rem 16rem;
  column-gap: 1rem;
  padding: 0 1rem;
}
.users_popup .popup_main .users_content .users_user-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.users_popup .popup_main .users_content .users_user-container .users_user--name,
.users_popup .popup_main .users_content .users_user-container .users_user--mail,
.users_popup .popup_main .users_content .users_user-container .users_user--game {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.users_popup .popup_main .users_content .users_user-container .users_user--game span {
  display: block;
}
.users_popup .popup_main .users_content .users_user-container .users_user--role {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.users_popup .popup_main .users_content .users_user-container .users_user--role span {
  display: block;
}
.users_popup .popup_main .users_content .users_header--active,
.users_popup .popup_main .users_content .users_user--active {
  justify-self: center;
}
.users_popup .popup_main .users_content .users_header--buttons {
  width: 16rem;
}
.users_popup .popup_main .users_buttons {
  justify-self: end;
}
.users_popup .popup_main .users_buttons .btn {
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  min-width: 7rem;
  margin: 0 0.25rem;
}
.users_popup .popup_main .users_add-user {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.users_popup .popup_main .users_add-user .btn {
  padding: 0.5rem 1rem;
}

.edit-user_popup .popup {
  width: 56rem;
  overflow-y: hidden;
}
.edit-user_popup .popup_main {
  display: flex;
  flex-direction: column;
}
.edit-user_popup .edit-user_content {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 2rem;
}
.edit-user_popup .edit-user_content::-webkit-scrollbar {
  width: 0.5rem;
}
.edit-user_popup .edit-user_content::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.edit-user_popup .edit-user_content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.edit-user_popup .edit-user_personal {
  display: grid;
  grid-template-columns: 16rem 32rem;
  row-gap: 3rem;
  column-gap: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.edit-user_popup .edit-user_first-name .form_field,
.edit-user_popup .edit-user_phone .form_field {
  width: 16rem;
}
.edit-user_popup .edit-user_last-name .form_field,
.edit-user_popup .edit-user_mail .form_field {
  width: 32rem;
}
.edit-user_popup .edit-user_warning {
  margin-bottom: 2rem;
}
.edit-user_popup .edit-user_game--role {
  align-self: flex-end;
}
.edit-user_popup .edit-user_game--first,
.edit-user_popup .edit-user_game--next {
  display: grid;
  grid-template-columns: 3rem 24rem auto;
  column-gap: 0;
}
.edit-user_popup .edit-user_game--first .edit-user_icon--delete,
.edit-user_popup .edit-user_game--next .edit-user_icon--delete {
  align-self: flex-start;
  margin-top: 3rem;
}
.edit-user_popup .edit-user_game--next {
  margin-bottom: 2rem;
}
.edit-user_popup .edit-user_game .form_dropdown,
.edit-user_popup .edit-user_game .form_field {
  width: 23rem;
}
.edit-user_popup .edit-user_role .form_dropdown,
.edit-user_popup .edit-user_role .form_field {
  width: 16rem;
}
.edit-user_popup .edit-user_role .form_checkbox-group {
  padding: 0.5rem 0 1rem 0;
  background-color: #fff;
  margin: 0;
}
.edit-user_popup .edit-user_role .form_checkbox-group .form_checkbox {
  accent-color: #dc761c;
}
.edit-user_popup .edit-user_icon--delete {
  align-self: flex-end;
  justify-self: flex-start;
}
.edit-user_popup .edit-user_icon--delete .icon-container {
  margin-bottom: 0.7rem;
}
.edit-user_popup .edit-user_icon--delete .icon {
  height: 1.8rem;
}
.edit-user_popup .edit-user_add {
  justify-self: center;
  align-self: center;
  padding-bottom: 1rem;
}

.edit-user_popup.superadmin_edit-user .popup {
  width: 85rem;
}
.edit-user_popup.superadmin_edit-user .edit-user_warning {
  font-weight: 500;
  margin-bottom: 0;
}
.edit-user_popup.superadmin_edit-user .warning {
  color: red;
}
.edit-user_popup.superadmin_edit-user .edit-user_add.edit-user_add--company {
  align-self: flex-start;
  margin-left: 12.5rem;
  margin-top: 2rem;
}
.edit-user_popup.superadmin_edit-user .edit-user_company--first,
.edit-user_popup.superadmin_edit-user .edit-user_company--next {
  display: grid;
  grid-template-columns: 3rem 24rem auto;
  margin-top: 2rem;
}
.edit-user_popup.superadmin_edit-user .edit-user_company--first .edit-user_icon--delete,
.edit-user_popup.superadmin_edit-user .edit-user_company--next .edit-user_icon--delete {
  align-self: flex-start;
  margin-top: 3rem;
}
.edit-user_popup.superadmin_edit-user .edit-user_company--first .edit-isAdmin,
.edit-user_popup.superadmin_edit-user .edit-user_company--next .edit-isAdmin {
  margin-top: 0.5rem;
  padding-left: 0.2rem;
}
.edit-user_popup.superadmin_edit-user .edit-user_company--first .edit-user_game--role .edit-user_add,
.edit-user_popup.superadmin_edit-user .edit-user_company--next .edit-user_game--role .edit-user_add {
  justify-self: center;
  align-self: center;
}
.edit-user_popup.superadmin_edit-user .edit-user_game--first,
.edit-user_popup.superadmin_edit-user .edit-user_game--next {
  display: grid;
  grid-template-columns: 3rem 24rem auto;
  column-gap: 0;
}
.edit-user_popup.superadmin_edit-user .edit-user_game--first .edit-user_icon--delete,
.edit-user_popup.superadmin_edit-user .edit-user_game--next .edit-user_icon--delete {
  align-self: flex-start;
  margin-top: 3rem;
}

.organization-settings_popup .popup {
  min-width: 110rem;
  overflow-y: auto;
  overflow-x: auto;
}
.organization-settings_popup .popup::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.organization-settings_popup .popup::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.organization-settings_popup .popup::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.organization-settings_popup .popup_header {
  font-size: 1.8rem;
}
.organization-settings_popup .popup_main {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}
.organization-settings_popup .popup_main .content-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  display: grid;
  grid-template-columns: 34rem 74rem;
  column-gap: 5rem;
  align-items: start;
  padding-top: 1rem;
}
.organization-settings_popup .popup_main .content-container::-webkit-scrollbar {
  width: 0.5rem;
}
.organization-settings_popup .popup_main .content-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.organization-settings_popup .popup_main .content-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.organization-settings_popup .popup_main .widget_header {
  height: 4rem;
}
.organization-settings_popup .popup_main .organization-settings_content {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  padding: 2rem 3rem 3rem 3rem;
  border-right: 1px solid var(--color-primary-light);
  border-bottom: 1px solid var(--color-primary-light);
  border-left: 1px solid var(--color-primary-light);
}
.organization-settings_popup .popup_main .organization-settings_content .organization_notes .form_field {
  height: 10rem;
  max-width: 28rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.organization-settings_popup .popup_main .organization-settings_content .organization_notes .form_field::-webkit-scrollbar {
  width: 0.5rem;
}
.organization-settings_popup .popup_main .organization-settings_content .organization_notes .form_field::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.organization-settings_popup .popup_main .organization-settings_content .organization_notes .form_field::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.organization-settings_popup .popup_main .organization_contact-group {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_header,
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_user-container {
  display: grid;
  grid-template-columns: 20rem 10rem 10rem 20rem;
  align-items: center;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_content {
  border-left: 1px solid var(--color-primary-light);
  border-right: 1px solid var(--color-primary-light);
  border-bottom: 1px solid var(--color-primary-light);
  max-height: 20rem;
  min-height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_content::-webkit-scrollbar {
  width: 0.5rem;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_content::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_header {
  font-weight: 600;
  padding: 0.5rem 0 0.5rem 1rem;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_header--primary, .organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_header--secondary {
  justify-self: center;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_user-container {
  padding: 0.4rem 1rem;
  border-bottom: 1px solid var(--color-grey-very-light);
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_user-container .form_radio-button {
  display: inline-block;
  justify-self: center;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_user-container .contact-overview_primary,
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_user-container .contact-overview_secondary {
  justify-self: center;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_buttons {
  justify-self: end;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_contact-overview .contact-overview_buttons .btn {
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  min-width: 7rem;
  margin: 0 0.25rem;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_add-contact {
  align-self: center;
  margin-top: 1rem;
}
.organization-settings_popup .popup_main .organization_contact-group .organization_add-contact .btn {
  padding: 0.5rem 1rem;
}
.organization-settings_popup .widget_users {
  width: fit-content;
}
.organization-settings_popup .btn-container {
  margin-right: 3rem;
}

.edit-contact_popup .organization_contact-details {
  position: relative;
}
.edit-contact_popup .organization_contact-details .contact-details_content {
  display: grid;
  grid-template-columns: 34rem 1fr;
}
.edit-contact_popup .organization_contact-details .contact-details_left {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.edit-contact_popup .organization_contact-details .contact-details_left .form_field {
  width: 30rem;
}
.edit-contact_popup .organization_contact-details .contact-details_left .contact_name .form_field,
.edit-contact_popup .organization_contact-details .contact-details_left .contact_position .form_field {
  width: 22rem;
}
.edit-contact_popup .organization_contact-details .phone-container {
  display: flex;
  column-gap: 2rem;
}
.edit-contact_popup .organization_contact-details .phone-container .form_field {
  width: 14rem;
}
.edit-contact_popup .organization_contact-details .contact-details_right {
  height: fit-content;
  width: fit-content;
}
.edit-contact_popup .organization_contact-details .contact-details_right .form_checkbox-group {
  padding: 0.5rem 10rem 1rem 1rem;
  background-color: #fff;
  margin: 0;
}
.edit-contact_popup .organization_contact-details .contact-details_right .form_checkbox-group .form_checkbox {
  accent-color: #dc761c;
}
.edit-contact_popup .organization_contact-details .contact-details_right .checkbox_text {
  font-size: 1.6rem;
  margin-right: 5rem;
  cursor: default;
}
.edit-contact_popup .organization_contact-details .contact-details_right input {
  margin-left: auto;
}

.game-settings_popup--admin .popup {
  min-width: 50rem;
  max-width: 75rem;
  cursor: default;
}
.game-settings_popup--admin .game-settings_content {
  margin: 2rem 2rem 4rem 2rem;
}
.game-settings_popup--admin .game-settings_url {
  padding-top: 1rem;
  font-style: italic;
  word-break: break-word;
}
.game-settings_popup--admin .game-settings_url span {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 1.8rem;
}
.game-settings_popup--admin .game-settings_url .icon-container {
  display: none;
}

.dashboard_header {
  position: relative;
}
.dashboard .btn_edit-game {
  position: absolute;
  top: -1rem;
  right: 1rem;
  font-size: 1.6rem;
  padding: 1rem 3.2rem;
  padding: 0.8rem 2.4rem;
}
.dashboard .btn_edit-game--blue {
  padding: 0.8rem 2rem;
  margin: 0 0.2rem;
}

.dashboard-start .dashboard_widget-area {
  display: grid;
  grid-template-columns: minmax(66rem, 75rem) minmax(55rem, 100rem);
  column-gap: 2rem;
  padding: 0 1rem 0 0;
  background-color: var(--color-grey-very-dark);
}
.dashboard-start_left .dashboard-start_left-top {
  background-image: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.1)), url(placeholder_startscherm.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.dashboard-start_left .dashboard-start_left-top--layer {
  display: grid;
  grid-template-rows: 4rem 14rem auto 3rem;
  align-items: end;
  font-size: 1.6rem;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 26.2rem;
  background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.1));
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_game-settings {
  justify-self: end;
  align-self: end;
  margin-right: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.596);
  border-radius: 50%;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_game-settings .icon {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container {
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: end;
  width: 60%;
  height: 15rem;
  padding: 0 1rem 1rem 1rem;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container .dashboard-start_game-name {
  font-size: 2.4rem;
  padding-bottom: 0.5rem;
  margin-top: -2rem;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container .dashboard-start_game-description {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container .dashboard-start_game-description::-webkit-scrollbar {
  width: 0.5rem;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container .dashboard-start_game-description::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_text-container .dashboard-start_game-description::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_button-container {
  display: flex;
  padding-top: 2rem;
  column-gap: 1rem;
  padding: 1rem;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_button-container .btn {
  padding: 0.4rem 3.2rem;
  width: 12rem;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_button-container .btn--orange {
  box-shadow: none;
}
.dashboard-start_left .dashboard-start_left-top .dashboard-start_button-container .btn_trainer-dashboard {
  width: 20rem;
  margin-left: auto;
}
.dashboard-start_left .dashboard-start_footer {
  display: grid;
  grid-template-columns: 1fr 10rem;
  align-content: center;
  padding: 0 1rem;
  color: white;
  height: 3rem;
  font-size: 1.4rem;
  background-color: rgba(28, 28, 28, 0.512);
  cursor: default;
}
.dashboard-start_left .dashboard-start_footer .footer_numbers {
  cursor: default;
}
.dashboard-start_left .dashboard-start_footer .footer_last-edited {
  justify-self: end;
  cursor: default;
}
.dashboard-start_left .dashboard-start_games-group {
  display: grid;
  grid-template-rows: 15rem;
  grid-auto-rows: 15rem;
  grid-template-columns: repeat(auto-fit, 21rem);
  grid-auto-flow: row;
  gap: 1rem;
  padding-top: 2rem;
}
.dashboard-start_left .dashboard-start_games-group .dashboard-start_game-card {
  border: 3px solid white;
  display: grid;
  grid-template-rows: 3rem 1fr;
  background-color: white;
  box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
  cursor: pointer;
}
.dashboard-start_left .dashboard-start_games-group .dashboard-start_game-card.selected {
  border: 3px solid var(--color-primary);
  background-color: var(--color-primary);
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
}
.dashboard-start_left .dashboard-start_games-group .dashboard-start_game-card .game-card_name {
  align-self: center;
  justify-self: center;
  height: 2rem;
}
.dashboard-start_left .dashboard-start_games-group .dashboard-start_game-card .game-card_image {
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  background-image: url(placeholder_startscherm.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: white;
}
.dashboard-start_right .dashboard-start_widget-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.dashboard-start_right .dashboard-start_widget-container .widget .widget_header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.dashboard-start_right .dashboard-start_widget-container .widget:not(:first-child) .widget_header {
  border-radius: 0;
}
.dashboard-start_right .widget {
  box-shadow: 0px 1px 24px rgba(149, 157, 165, 0.2);
  box-shadow: none;
}
.dashboard-start_right .widget .widget_header {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--color-widget-grey);
  color: white;
  font-weight: 500;
  text-align: center;
  height: 3.5rem;
}
.dashboard-start_right .widget .widget_header--title {
  justify-self: center;
  padding-bottom: 1rem;
}
.dashboard-start_right .widget .widget_header--icon {
  margin-left: auto;
}
.dashboard-start_right .widget .widget_header--icon .icon--tab {
  fill: white;
  stroke: white;
  stroke-width: 0;
  height: 2rem;
  margin-right: 1rem;
}
.dashboard-start_right .widget .widget_content {
  background-color: var(--color-widget-grey);
  color: white;
  border: none;
  color: var(--color-grey-very-light);
}
.dashboard-start_right .widget .widget_content .table tr:nth-child(even):not(.table_row--clickable:hover) {
  background-color: var(--color-widget-grey);
}
.dashboard-start_right .dashboard-start_training-stats {
  display: flex;
  flex-direction: column;
}
.dashboard-start_right .dashboard-start_training-stats .training-stats_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.dashboard-start_right .dashboard-start_training-stats .training-stats_container .btn {
  padding: 0.4rem 3.2rem;
  justify-self: center;
  align-self: center;
  margin-top: 2rem;
}
.dashboard-start_right .dashboard-start_game-stats {
  height: 26.2rem;
}
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 22.6rem;
}
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games .played-games_total,
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games .played-games_screens,
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games .played-games_time {
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 16rem;
  width: 16rem;
  border-radius: 50%;
  border: 1px solid var(--color-grey-very-light);
}
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games .played-games_number {
  font-size: 3.5rem;
  font-weight: 600;
  color: white;
}
.dashboard-start_right .dashboard-start_game-stats .game-stats_played-games .played-games_label {
  font-size: 1.2rem;
  color: white;
}
.dashboard-start_right .dashboard-start_game-stats .played-games_table {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
}
.dashboard-start_right .dashboard-start_game-stats .played-games_table-row {
  display: grid;
  grid-template-columns: 4rem 4rem;
}
.dashboard-start_right .dashboard-start_game-stats .game-stats_avarages {
  height: fit-content;
  border: 1px solid var(--color-grey-medium);
  padding: 1rem;
  max-width: 20rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--accuracy {
  justify-self: end;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--accuracy, .dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--thumbs {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 16rem;
  width: 16rem;
  border-radius: 50%;
  border: 1px solid var(--color-grey-very-light);
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--thumbs {
  border-radius: 0;
  align-items: flex-start;
  border: none;
  row-gap: 1rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--thumbs .ai-stats_thumb--up,
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--thumbs .ai-stats_thumb--down {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model--thumbs .icon {
  fill: var(--color-grey-very-light);
  stroke: var(--color-grey-very-light);
  stroke-width: 0;
  width: 3rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model .ai-stats_number {
  font-size: 6rem;
  font-weight: 600;
  color: white;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_model .ai-stats_label {
  font-size: 1.2rem;
  color: white;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_input {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-self: center;
  column-gap: 1rem;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_input--new, .dashboard-start_right .dashboard-start_ai-stats .ai-stats_input--percentage, .dashboard-start_right .dashboard-start_ai-stats .ai-stats_input--players, .dashboard-start_right .dashboard-start_ai-stats .ai-stats_input--total {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  border: 1px solid var(--color-grey-very-light);
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_input .ai-stats_number {
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
}
.dashboard-start_right .dashboard-start_ai-stats .ai-stats_input .ai-stats_label {
  font-size: 1.2rem;
  color: white;
}

.dashboard_game-stats {
  display: none !important;
}

.game-settings_popup--game .popup {
  width: 84vw;
}
.game-settings_popup--game .popup_header {
  font-size: 1.8rem;
}
.game-settings_popup--game .popup_main {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}
.game-settings_popup--game .popup_main .content-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
}
.game-settings_popup--game .popup_main .content-container::-webkit-scrollbar {
  width: 0.5rem;
}
.game-settings_popup--game .popup_main .content-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.game-settings_popup--game .popup_main .content-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.game-settings_popup--game .popup_main .widget_header {
  height: 4rem;
}
.game-settings_popup--game .popup_main .game-settings_left {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.game-settings_popup--game .popup_main .game-settings_left .game-settings_data {
  width: 46rem;
}
.game-settings_popup--game .popup_main .game-settings_left .game-settings_data .game_delete-container {
  display: flex;
  column-gap: 1rem;
}
.game-settings_popup--game .popup_main .game-settings_left .game-settings_data .icon--delete {
  width: 1.8rem;
  justify-self: center;
  align-self: center;
}
.game-settings_popup--game .popup_main .game-settings_right {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.game-settings_popup--game .popup_main .game-settings_right .game-settings_menu {
  width: 46rem;
}
.game-settings_popup--game .popup_main .game-settings_right .game-settings_game-header textarea {
  height: 11rem;
  width: 40rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.game-settings_popup--game .popup_main .game-settings_right .game-settings_game-header textarea::-webkit-scrollbar {
  width: 0.5rem;
}
.game-settings_popup--game .popup_main .game-settings_right .game-settings_game-header textarea::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.game-settings_popup--game .popup_main .game-settings_right .game-settings_game-header textarea::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.game-settings_popup--game .popup_main .game-settings_app-icon {
  width: 46rem;
}
.game-settings_popup--game .popup_main .game-settings_content {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 3rem 3rem;
  border-right: 1px solid var(--color-primary-light);
  border-bottom: 1px solid var(--color-primary-light);
  border-left: 1px solid var(--color-primary-light);
}
.game-settings_popup--game .popup_main .game-settings_content .game_slug,
.game-settings_popup--game .popup_main .game-settings_content .game_password {
  margin-top: 3rem;
}
.game-settings_popup--game .popup_main .game-settings_content .form_checkbox {
  margin-top: 0.5rem;
  margin-left: 19rem;
}
.game-settings_popup--game .popup_main .game-settings_content .game-settings_url--title {
  margin-top: 3rem;
}
.game-settings_popup--game .popup_main .game-settings_content .game-settings_url {
  margin-top: 1rem;
  font-style: italic;
  word-break: break-word;
  display: flex;
  column-gap: 0.8rem;
}
.game-settings_popup--game .popup_main .game-settings_content .game-settings_url span {
  color: var(--color-primary);
  font-weight: 600;
}
.game-settings_popup--game .popup_main .game-settings_content .game_icon .select-form_container {
  margin: 1.5rem 0;
}
.game-settings_popup--game .popup_main .game-settings_content .game-settings_show_menu {
  margin-left: 0;
}
.game-settings_popup--game .popup:nth-child(2) {
  width: 10rem;
}
.game-settings_popup--game .btn-container {
  margin-right: 3rem;
}

.dashboard-trainer_dashboard-live {
  display: flex;
  flex-direction: column;
  background-color: var(--color-grey-very-dark);
  min-height: 100vh;
  max-width: 100vw;
  aspect-ratio: 16/9;
  border-radius: 0;
  row-gap: 0.5em;
  padding: 1rem 3rem;
}
.dashboard-trainer_dashboard-live.tijdelijk {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.dashboard-trainer_dashboard-live .dashboard-live_header {
  display: flex;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_names-container {
  margin-top: 2rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_names-container .dashboard-live_game {
  color: white;
  font-size: 3rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_names-container .dashboard-live_round {
  color: white;
  font-size: 1.8rem;
  padding-top: 0.5rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container .dashboard-live_titles-container {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1.5rem 1fr 1.5rem 1fr;
  column-gap: 2rem;
  text-align: center;
  font-size: 2rem;
  padding: 0 2rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container .dashboard-live_timer {
  margin-top: 0.2rem;
  background-color: white;
  font-size: 3rem;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  column-gap: 2rem;
  text-align: center;
  font-size: 7rem;
  padding: 0 1rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container .dashboard-live_timer.dashboard-live_green {
  background-color: rgb(180, 255, 180);
  border: 2px solid rgb(50, 120, 50);
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container .dashboard-live_timer.dashboard-live_orange {
  background-color: rgb(252, 206, 187);
  border: 2px solid rgb(252, 120, 68);
}
.dashboard-trainer_dashboard-live .dashboard-live_header .dashboard-live_timer-container .dashboard-live_timer.dashboard-live_red {
  background-color: rgb(248, 137, 137);
  border: 2px solid red;
}
.dashboard-trainer_dashboard-live .dashboard-live_scores {
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 6rem 0 1rem 0;
  column-gap: 3rem;
  row-gap: 2rem;
  width: 90%;
}
.dashboard-trainer_dashboard-live .dashboard-live_scores .dashboard-live_score-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_scores .dashboard-live_score-container .player_nickname {
  color: white;
  text-align: center;
  font-size: 2rem;
}
.dashboard-trainer_dashboard-live .dashboard-live_scores .dashboard-live_score-container .player_score {
  background-color: white;
  font-size: 5rem;
  text-align: center;
  border-radius: 15px;
  padding: 2rem 2rem;
}

.warning {
  margin-top: 2rem;
  font-weight: 600;
}

.game-editor_sidebar {
  display: grid;
  grid-template-rows: auto 1fr;
}
.game-editor_sidebar_edit-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 4rem;
}
.game-editor_sidebar_edit-container .icon--expand {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
  display: none;
}
.game-editor_sidebar_edit-container .icon--expand:hover {
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
  cursor: default;
}
.game-editor_sidebar_storyline {
  margin-left: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 23.5rem);
}
.game-editor_sidebar_storyline::-webkit-scrollbar {
  width: 0.5rem;
}
.game-editor_sidebar_storyline::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.game-editor_sidebar_storyline::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.game-editor_sidebar_storyline .storyline_scene {
  margin-left: 1em;
}
.game-editor_sidebar_storyline .game_name--name {
  cursor: pointer;
}
.game-editor_sidebar .storyline {
  margin-top: 2rem;
  margin-bottom: 1rem;
  column-gap: 0.1rem;
  line-height: 2.6rem;
  font-size: 1.5rem;
}
.game-editor_breadcrumbs .btn {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  padding: 1rem 3.2rem;
  padding: 0.8rem 2.4rem;
}
.game-editor_breadcrumbs .btn--blue {
  padding: 0.8rem 2rem;
  margin: 0 0.5rem;
}
.game-editor_breadcrumbs .btn--yellow:disabled {
  pointer-events: none;
}
.game-editor_main {
  height: calc(100vh - 15.5rem);
  display: grid;
  grid-template-columns: 1fr 30rem;
  position: relative;
}
.game-editor_screen {
  height: calc(100vh - 15.5rem);
  padding: 0 1rem;
  display: grid;
  grid-template-rows: 4rem 1fr;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
}
.game-editor_screen::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.game-editor_screen::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.game-editor_screen::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.game-editor_screen_header {
  justify-self: center;
  background-color: var(--color-grey-very-dark);
  color: white;
  text-align: center;
  align-self: center;
  font-size: 2rem;
}
.game-editor_screen_frame {
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: auto;
  overscroll-behavior: none;
}
.game-editor_screen_frame::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}
.game-editor_screen_frame::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.game-editor_screen_frame::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.game-editor_screen_frame::-webkit-scrollbar-corner {
  background-color: transparent;
}
.game-editor_screen_background {
  max-height: calc(100vh - 19rem);
  max-width: calc(100vw - 52.6rem);
  aspect-ratio: 16/9;
  background-color: white;
  justify-self: center;
  box-shadow: 0 0 0 2px white;
}
.game-editor_screen_background .items {
  width: 100%;
  height: 100%;
}
.game-editor_screen .game-editor_story {
  z-index: 99999;
  position: absolute;
  left: 0.1rem;
  bottom: 0;
  border-radius: 0;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.345);
  background-color: var(--color-grey-very-dark);
  width: 82rem;
  height: calc(100vh - 23.5rem);
  margin-bottom: 0.3rem;
}
.game-editor_screen .game-editor_story .story_main {
  margin-left: 0.5rem;
  position: relative;
  height: inherit;
  padding: 0.5rem 0.2rem 0.2rem 0.2rem;
}
.game-editor_screen .game-editor_story .story_main .icon-container.story_close {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
}
.game-editor_screen .game-editor_story .story_main .icon-container.story_close .icon {
  height: 2.8rem;
  fill: white;
  stroke: white;
}
.game-editor_screen .game-editor_story .story_main .game-editor_characteristics {
  height: inherit;
}
.game-editor_screen .game-editor_story .story_main .game-editor_characteristics .story-editor_characteristics {
  height: inherit;
}
.game-editor_screen .game-editor_story .story_main .tab {
  width: 12rem;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content {
  border: 1px solid var(--color-grey-very-light);
  background-color: white;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content::-webkit-scrollbar {
  width: 0.5rem;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.game-editor_screen .game-editor_story .story_main .characteristics_content::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .story-editor_story {
  height: 100%;
  display: block;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .story-editor_story .story-editor_story_background {
  height: 100%;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .ql-editor {
  overflow-y: auto;
  overflow-x: hidden;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .ql-editor::-webkit-scrollbar {
  width: 0.5rem;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .ql-editor::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .ql-editor::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .form_field--disabled {
  background-color: white;
  border: 1px solid var(--color-grey-very-light);
  white-space: break-spaces;
}
.game-editor_screen .game-editor_story .story_main .characteristics_content .character_overview {
  height: 13rem;
  border-bottom: 1px solid var(--color-grey-very-light);
  padding-bottom: 1rem;
}

.item-overview {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.item-list,
.screen-settings {
  height: calc(100vh - 15.5rem);
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.item-list a,
.item-list a:hover,
.item-list a:focus,
.item-list a:active,
.screen-settings a,
.screen-settings a:hover,
.screen-settings a:focus,
.screen-settings a:active {
  text-decoration: none;
  color: inherit;
}
.item-list_header,
.screen-settings_header {
  display: flex;
  height: 4rem;
  background-color: var(--color-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.item-list_header-name,
.screen-settings_header-name {
  color: white;
  font-size: 1.8rem;
}
.item-list_items,
.screen-settings_items {
  height: calc(100vh - 19.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
}
.item-list_items::-webkit-scrollbar,
.screen-settings_items::-webkit-scrollbar {
  width: 0.5rem;
}
.item-list_items::-webkit-scrollbar-track,
.screen-settings_items::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.item-list_items::-webkit-scrollbar-thumb,
.screen-settings_items::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.item-list_add-group,
.screen-settings_add-group {
  margin-top: auto;
  align-self: start;
  text-decoration: underline;
  font-size: 1.2rem;
  color: var(--color-primary);
  padding-top: 2rem;
}
.item-list_item-container,
.screen-settings_item-container {
  display: grid;
  grid-template-columns: 2.2rem 2.2rem 3rem 1fr;
  column-gap: 0.1rem;
  row-gap: 0.7rem;
  padding-top: 0.5rem;
}
.item-list_item-container--hover,
.screen-settings_item-container--hover {
  color: var(--color-primary);
  font-weight: 600;
}
.item-list_item-container--hover .icon,
.screen-settings_item-container--hover .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  cursor: grab;
}
.item-list_group-container .item-list_group--name,
.screen-settings_group-container .item-list_group--name {
  display: grid;
  grid-template-columns: 1.8rem 2.2rem 2.2rem auto 1fr;
  column-gap: 0.1rem;
  padding-bottom: 0;
  row-gap: 0.7rem;
  padding-top: 0.5rem;
}
.item-list_group-container .item-list_group--name--hover,
.screen-settings_group-container .item-list_group--name--hover {
  color: var(--color-primary);
  font-weight: 600;
}
.item-list_group-container .item-list_group--name--hover .icon,
.screen-settings_group-container .item-list_group--name--hover .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  cursor: grab;
}
.item-list_group-container .item-list_group--name .icon--dropdown,
.screen-settings_group-container .item-list_group--name .icon--dropdown {
  height: 1.8rem;
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
}
.item-list_group-container .item-list_group--name .item-list_count,
.screen-settings_group-container .item-list_group--name .item-list_count {
  align-self: center;
  padding-left: 0.1rem;
}
.item-list_group-container--dragover,
.screen-settings_group-container--dragover {
  outline: 2px solid var(--color-grey-light);
}
.item-list_group-container--hover,
.screen-settings_group-container--hover {
  color: var(--color-primary);
  font-weight: 600;
}
.item-list_group-container--hover .icon,
.screen-settings_group-container--hover .icon {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  cursor: grab;
}
.item-list_group-container--contains-hovered > .item-list_group--name,
.screen-settings_group-container--contains-hovered > .item-list_group--name {
  font-weight: 600;
}
.item-list_item-container--component, .item-list_item-container--memorygame, .item-list_item-container--catchgame, .item-list_group-container--component, .item-list_group-container--memorygame, .item-list_group-container--catchgame,
.screen-settings_item-container--component,
.screen-settings_item-container--memorygame,
.screen-settings_item-container--catchgame,
.screen-settings_group-container--component,
.screen-settings_group-container--memorygame,
.screen-settings_group-container--catchgame {
  color: #a629f4;
}
.item-list_item-container--component .icon, .item-list_item-container--memorygame .icon, .item-list_item-container--catchgame .icon, .item-list_group-container--component .icon, .item-list_group-container--memorygame .icon, .item-list_group-container--catchgame .icon,
.screen-settings_item-container--component .icon,
.screen-settings_item-container--memorygame .icon,
.screen-settings_item-container--catchgame .icon,
.screen-settings_group-container--component .icon,
.screen-settings_group-container--memorygame .icon,
.screen-settings_group-container--catchgame .icon {
  fill: #be6fee;
  stroke: #be6fee;
}
.item-list_item-placeholder,
.screen-settings_item-placeholder {
  outline: 2px solid var(--color-grey-light);
}
.item-list .item-list_group--items,
.screen-settings .item-list_group--items {
  margin-left: 2.4rem;
  border-left: 1px solid var(--color-grey-very-light);
}
.item-list .item-list_group--items .item-list_item-container:first-child,
.screen-settings .item-list_group--items .item-list_item-container:first-child {
  padding-top: 0.2rem;
}
.item-list .item-list_group--items .item-list_item-container:last-child,
.screen-settings .item-list_group--items .item-list_item-container:last-child {
  margin-bottom: 0.5rem;
}
.item-list .icon--lock-close,
.screen-settings .icon--lock-close {
  width: 1.3rem;
  margin-left: 0.2rem;
}
.item-list .icon--lock-open,
.screen-settings .icon--lock-open {
  width: 1.5rem;
  margin-left: 0.3rem;
}
.item-list .icon--eye-open,
.item-list .eye-closed-icon,
.screen-settings .icon--eye-open,
.screen-settings .eye-closed-icon {
  width: 2rem;
  margin: 0 0.1rem 0 0.2rem;
}
.item-list .icon--video,
.item-list .icon--minigame,
.screen-settings .icon--video,
.screen-settings .icon--minigame {
  width: 2rem;
  stroke-width: 1.5rem;
}
.item-list .icon--text,
.item-list .icon--img,
.screen-settings .icon--text,
.screen-settings .icon--img {
  width: 2rem;
}
.item-list .icon--login,
.screen-settings .icon--login {
  fill: var(--color-grey-very-dark);
  stroke-width: 2rem;
}
.item-list .icon--input,
.screen-settings .icon--input {
  stroke-width: 0;
}
.item-list .icon--quiz,
.screen-settings .icon--quiz {
  fill: var(--color-grey-very-dark);
  stroke-width: 1.2rem;
}
.item-list .icon--high-scores,
.screen-settings .icon--high-scores {
  stroke-width: 1.2rem;
}
.item-list .item-list_name,
.screen-settings .item-list_name {
  margin-left: 0.3rem;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 14rem;
}
.item-list .icon--full-screen,
.screen-settings .icon--full-screen {
  height: 2rem;
}

.screen-details_settings .edit-name {
  padding-top: 2rem;
}

.item-details,
.screen-details {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: calc(100vh - 19rem);
}
.item-details_container,
.screen-details_container {
  height: calc(100vh - 19.5rem);
  overflow-y: auto;
  overflow-x: hidden;
}
.item-details_container::-webkit-scrollbar,
.screen-details_container::-webkit-scrollbar {
  width: 0.5rem;
}
.item-details_container::-webkit-scrollbar-track,
.screen-details_container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.item-details_container::-webkit-scrollbar-thumb,
.screen-details_container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.item-details_header,
.screen-details_header {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 4rem;
  background-color: var(--color-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  align-items: center;
}
.item-details_header-name,
.screen-details_header-name {
  color: white;
  font-size: 1.8rem;
  justify-self: center;
  display: flex;
}
.item-details_header .icon--close,
.item-details_header .icon--dropdown,
.screen-details_header .icon--close,
.screen-details_header .icon--dropdown {
  justify-self: end;
  fill: white;
  stroke: white;
  padding-right: 0.75rem;
}
.item-details_header .icon--close:hover,
.item-details_header .icon--dropdown:hover,
.screen-details_header .icon--close:hover,
.screen-details_header .icon--dropdown:hover {
  fill: white;
  stroke: white;
}
.item-details_header button,
.screen-details_header button {
  padding-right: 1rem;
}
.item-details_header .icon--dropdown,
.item-details_header .icon--dropdown,
.screen-details_header .icon--dropdown,
.screen-details_header .icon--dropdown {
  fill: white;
  stroke: white;
}
.item-details .popup,
.screen-details .popup {
  max-height: 60rem;
}
.item-details .popup .storyline,
.screen-details .popup .storyline {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 5rem 1rem 1rem;
}
.item-details .popup .storyline::-webkit-scrollbar,
.screen-details .popup .storyline::-webkit-scrollbar {
  width: 0.5rem;
}
.item-details .popup .storyline::-webkit-scrollbar-track,
.screen-details .popup .storyline::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.item-details .popup .storyline::-webkit-scrollbar-thumb,
.screen-details .popup .storyline::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.item-details_settings,
.screen-details_settings {
  background-color: white;
  padding-bottom: 2rem;
  padding-left: 1rem;
}
.item-details_settings > div:nth-of-type(1n + 4),
.screen-details_settings > div:nth-of-type(1n + 4) {
  margin-top: 2rem;
}
.item-details_settings_edit-container,
.screen-details_settings_edit-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}
.item-details_settings_edit-container--left, .item-details_settings_edit-container--right,
.screen-details_settings_edit-container--left,
.screen-details_settings_edit-container--right {
  display: flex;
}
.item-details_settings_edit-container--right .icon,
.screen-details_settings_edit-container--right .icon {
  height: 2.3rem;
}
.item-details_settings_edit-container .item-details_icon--lock .icon,
.item-details_settings_edit-container .item-details_icon--lock-locked .icon,
.screen-details_settings_edit-container .item-details_icon--lock .icon,
.screen-details_settings_edit-container .item-details_icon--lock-locked .icon {
  height: 1.6rem;
}
.item-details_settings_edit-container .icon-container,
.screen-details_settings_edit-container .icon-container {
  padding-right: 0.5rem;
}
.item-details_settings .item-copy_popup,
.screen-details_settings .item-copy_popup {
  width: 30rem;
}
.item-details_settings .item-copy_popup .form_checkbox,
.screen-details_settings .item-copy_popup .form_checkbox {
  padding-left: 1rem;
}
.item-details_settings .form_number-field,
.screen-details_settings .form_number-field {
  width: 10rem;
}
.item-details_settings .form_field-group,
.screen-details_settings .form_field-group {
  margin-top: 2rem;
}
.item-details_settings .form_dropdown-container-out,
.screen-details_settings .form_dropdown-container-out {
  width: 28rem;
}
.item-details_settings .select-form_container,
.screen-details_settings .select-form_container {
  margin-left: 0;
}
.item-details_settings .close-options,
.screen-details_settings .close-options {
  margin-top: 2rem;
}
.item-details_settings .close-options .form_checkbox-group,
.screen-details_settings .close-options .form_checkbox-group {
  margin-top: 0;
  margin-left: 1rem;
}
.item-details_settings .close-options .form_checkbox,
.screen-details_settings .close-options .form_checkbox {
  height: 3.5rem;
}
.item-details_settings .close-options .close-options_button,
.screen-details_settings .close-options .close-options_button {
  margin-left: auto;
  margin-right: 0.5rem;
  width: 16rem;
}
.item-details_settings .screen-settings_background-type .form_dropdown,
.screen-details_settings .screen-settings_background-type .form_dropdown {
  width: 28rem;
}
.item-details_settings .screen-settings_background-color,
.screen-details_settings .screen-settings_background-color {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.item-details_settings .settings_button-type .form_dropdown,
.screen-details_settings .settings_button-type .form_dropdown {
  width: 28rem;
}
.item-details_settings .settings_colorpicker,
.screen-details_settings .settings_colorpicker {
  margin-top: 2rem;
}
.item-details_settings .settings_colorpicker .icon-container,
.screen-details_settings .settings_colorpicker .icon-container {
  justify-self: flex-start;
  margin-left: 2rem;
}
.item-details_settings .settings_counter .form_label,
.item-details_settings .settings_counter .icon--delete,
.screen-details_settings .settings_counter .form_label,
.screen-details_settings .settings_counter .icon--delete {
  margin-top: 2rem;
}
.item-details_settings .settings_counter .form_dropdown,
.screen-details_settings .settings_counter .form_dropdown {
  width: 28rem;
}
.item-details_settings .settings_counter .flex-container,
.screen-details_settings .settings_counter .flex-container {
  display: flex;
  justify-content: center;
}
.item-details_settings .settings_counter .flex-container .btn,
.screen-details_settings .settings_counter .flex-container .btn {
  margin-top: 2rem;
}
.item-details_settings .settings_edit--popup,
.screen-details_settings .settings_edit--popup {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
.item-details_settings .settings_edit-type .form_dropdown,
.screen-details_settings .settings_edit-type .form_dropdown {
  width: 28rem;
}
.item-details_settings .settings_highscores,
.screen-details_settings .settings_highscores {
  margin-top: 2rem;
}
.item-details_settings .settings_input_correct-input,
.screen-details_settings .settings_input_correct-input {
  margin-top: 2rem;
}
.item-details_settings .settings_input_correct-input .form_checkbox,
.screen-details_settings .settings_input_correct-input .form_checkbox {
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.item-details_settings .settings_popup-size .form_dropdown-container-out,
.screen-details_settings .settings_popup-size .form_dropdown-container-out {
  width: 10rem;
}
.item-details_settings .settings_popup-type .form_dropdown-container-out,
.screen-details_settings .settings_popup-type .form_dropdown-container-out {
  width: 16rem;
}
.item-details_settings .settings_quiz_user-settings,
.screen-details_settings .settings_quiz_user-settings {
  margin-top: 2rem;
}
.item-details_settings .settings_quiz_user-settings .form_dropdown,
.screen-details_settings .settings_quiz_user-settings .form_dropdown {
  width: 28rem;
}
.item-details_settings .settings_video_url,
.screen-details_settings .settings_video_url {
  margin-top: 2rem;
}
.item-details_settings .settings_video_url .form_checkbox-group,
.screen-details_settings .settings_video_url .form_checkbox-group {
  margin-left: 1rem;
}
.item-details_settings .settings_nr-tiles .form_dropdown,
.item-details_settings .settings_nr-tiles .form_field,
.screen-details_settings .settings_nr-tiles .form_dropdown,
.screen-details_settings .settings_nr-tiles .form_field {
  width: 6rem;
}
.item-details_settings .settings_feedback-end .form_label,
.screen-details_settings .settings_feedback-end .form_label {
  margin-right: 1rem;
}
.item-details_actions,
.screen-details_actions {
  position: relative;
}
.item-details_actions, .item-details_references,
.screen-details_actions,
.screen-details_references {
  background-color: white;
  margin-top: 0.8rem;
}
.item-details_actions .action-list_header,
.item-details_actions .reference-list_header, .item-details_references .action-list_header,
.item-details_references .reference-list_header,
.screen-details_actions .action-list_header,
.screen-details_actions .reference-list_header,
.screen-details_references .action-list_header,
.screen-details_references .reference-list_header {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 3rem;
  background-color: var(--color-primary);
  align-items: center;
}
.item-details_actions .action-list_header .icon--close,
.item-details_actions .reference-list_header .icon--close, .item-details_references .action-list_header .icon--close,
.item-details_references .reference-list_header .icon--close,
.screen-details_actions .action-list_header .icon--close,
.screen-details_actions .reference-list_header .icon--close,
.screen-details_references .action-list_header .icon--close,
.screen-details_references .reference-list_header .icon--close {
  justify-self: end;
  fill: white;
  stroke: white;
  padding-right: 0.75rem;
}
.item-details_actions .action-list_header .icon--add,
.item-details_actions .reference-list_header .icon--add, .item-details_references .action-list_header .icon--add,
.item-details_references .reference-list_header .icon--add,
.screen-details_actions .action-list_header .icon--add,
.screen-details_actions .reference-list_header .icon--add,
.screen-details_references .action-list_header .icon--add,
.screen-details_references .reference-list_header .icon--add {
  fill: white;
  height: 1.8rem;
  padding-right: 1rem;
}
.item-details_actions .action-list_header-name,
.item-details_actions .reference-list_header-name, .item-details_references .action-list_header-name,
.item-details_references .reference-list_header-name,
.screen-details_actions .action-list_header-name,
.screen-details_actions .reference-list_header-name,
.screen-details_references .action-list_header-name,
.screen-details_references .reference-list_header-name {
  color: white;
  justify-self: left;
  padding-left: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  height: 3rem;
  background-color: var(--color-primary);
  align-items: center;
  cursor: default;
}
.item-details_actions .action-list_header-name .icon-container,
.item-details_actions .reference-list_header-name .icon-container, .item-details_references .action-list_header-name .icon-container,
.item-details_references .reference-list_header-name .icon-container,
.screen-details_actions .action-list_header-name .icon-container,
.screen-details_actions .reference-list_header-name .icon-container,
.screen-details_references .action-list_header-name .icon-container,
.screen-details_references .reference-list_header-name .icon-container {
  padding-right: 1rem;
}
.item-details_actions .action-list_header-name .icon--dropdown,
.item-details_actions .reference-list_header-name .icon--dropdown, .item-details_references .action-list_header-name .icon--dropdown,
.item-details_references .reference-list_header-name .icon--dropdown,
.screen-details_actions .action-list_header-name .icon--dropdown,
.screen-details_actions .reference-list_header-name .icon--dropdown,
.screen-details_references .action-list_header-name .icon--dropdown,
.screen-details_references .reference-list_header-name .icon--dropdown {
  fill: white;
  stroke: white;
}
.item-details_actions .action-list_action-container,
.item-details_actions .reference-list_action-container, .item-details_references .action-list_action-container,
.item-details_references .reference-list_action-container,
.screen-details_actions .action-list_action-container,
.screen-details_actions .reference-list_action-container,
.screen-details_references .action-list_action-container,
.screen-details_references .reference-list_action-container {
  padding-bottom: 0.5rem;
  display: grid;
  grid-template-columns: auto 3rem 1fr auto auto;
  grid-template-rows: auto;
  column-gap: 0.5rem;
  row-gap: 0.7rem;
  cursor: pointer;
}
.item-details_actions .action-list_action-container .action-list_name,
.item-details_actions .reference-list_action-container .action-list_name, .item-details_references .action-list_action-container .action-list_name,
.item-details_references .reference-list_action-container .action-list_name,
.screen-details_actions .action-list_action-container .action-list_name,
.screen-details_actions .reference-list_action-container .action-list_name,
.screen-details_references .action-list_action-container .action-list_name,
.screen-details_references .reference-list_action-container .action-list_name {
  width: 16.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item-details_actions .action-list_action-container .action-list_name:hover,
.item-details_actions .reference-list_action-container .action-list_name:hover, .item-details_references .action-list_action-container .action-list_name:hover,
.item-details_references .reference-list_action-container .action-list_name:hover,
.screen-details_actions .action-list_action-container .action-list_name:hover,
.screen-details_actions .reference-list_action-container .action-list_name:hover,
.screen-details_references .action-list_action-container .action-list_name:hover,
.screen-details_references .reference-list_action-container .action-list_name:hover {
  color: var(--color-primary);
}
.item-details_actions .action-list_action-container .form_checkbox.input-item_checkbox-correct,
.item-details_actions .reference-list_action-container .form_checkbox.input-item_checkbox-correct, .item-details_references .action-list_action-container .form_checkbox.input-item_checkbox-correct,
.item-details_references .reference-list_action-container .form_checkbox.input-item_checkbox-correct,
.screen-details_actions .action-list_action-container .form_checkbox.input-item_checkbox-correct,
.screen-details_actions .reference-list_action-container .form_checkbox.input-item_checkbox-correct,
.screen-details_references .action-list_action-container .form_checkbox.input-item_checkbox-correct,
.screen-details_references .reference-list_action-container .form_checkbox.input-item_checkbox-correct {
  margin-top: 0;
  grid-column: 4;
  accent-color: var(--color-btn-green);
}
.item-details_actions .action-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.item-details_actions .reference-list_action-container .form_checkbox.input-item_checkbox-incorrect, .item-details_references .action-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.item-details_references .reference-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.screen-details_actions .action-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.screen-details_actions .reference-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.screen-details_references .action-list_action-container .form_checkbox.input-item_checkbox-incorrect,
.screen-details_references .reference-list_action-container .form_checkbox.input-item_checkbox-incorrect {
  margin-top: 0;
  grid-column: 5;
  accent-color: var(--color-btn-red);
}
.item-details_actions .action-list_actions,
.item-details_actions .reference-list_actions, .item-details_references .action-list_actions,
.item-details_references .reference-list_actions,
.screen-details_actions .action-list_actions,
.screen-details_actions .reference-list_actions,
.screen-details_references .action-list_actions,
.screen-details_references .reference-list_actions {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
}
.item-details_actions .action-list_actions a,
.item-details_actions .action-list_actions a:hover,
.item-details_actions .action-list_actions a:focus,
.item-details_actions .action-list_actions a:active,
.item-details_actions .reference-list_actions a,
.item-details_actions .reference-list_actions a:hover,
.item-details_actions .reference-list_actions a:focus,
.item-details_actions .reference-list_actions a:active, .item-details_references .action-list_actions a,
.item-details_references .action-list_actions a:hover,
.item-details_references .action-list_actions a:focus,
.item-details_references .action-list_actions a:active,
.item-details_references .reference-list_actions a,
.item-details_references .reference-list_actions a:hover,
.item-details_references .reference-list_actions a:focus,
.item-details_references .reference-list_actions a:active,
.screen-details_actions .action-list_actions a,
.screen-details_actions .action-list_actions a:hover,
.screen-details_actions .action-list_actions a:focus,
.screen-details_actions .action-list_actions a:active,
.screen-details_actions .reference-list_actions a,
.screen-details_actions .reference-list_actions a:hover,
.screen-details_actions .reference-list_actions a:focus,
.screen-details_actions .reference-list_actions a:active,
.screen-details_references .action-list_actions a,
.screen-details_references .action-list_actions a:hover,
.screen-details_references .action-list_actions a:focus,
.screen-details_references .action-list_actions a:active,
.screen-details_references .reference-list_actions a,
.screen-details_references .reference-list_actions a:hover,
.screen-details_references .reference-list_actions a:focus,
.screen-details_references .reference-list_actions a:active {
  text-decoration: none;
  color: inherit;
}
.item-details_actions .action-list_actions .icon--dropdown,
.item-details_actions .reference-list_actions .icon--dropdown, .item-details_references .action-list_actions .icon--dropdown,
.item-details_references .reference-list_actions .icon--dropdown,
.screen-details_actions .action-list_actions .icon--dropdown,
.screen-details_actions .reference-list_actions .icon--dropdown,
.screen-details_references .action-list_actions .icon--dropdown,
.screen-details_references .reference-list_actions .icon--dropdown {
  height: 2rem;
}
.item-details_actions .action-list_actions .icon--go-to-url,
.item-details_actions .reference-list_actions .icon--go-to-url, .item-details_references .action-list_actions .icon--go-to-url,
.item-details_references .reference-list_actions .icon--go-to-url,
.screen-details_actions .action-list_actions .icon--go-to-url,
.screen-details_actions .reference-list_actions .icon--go-to-url,
.screen-details_references .action-list_actions .icon--go-to-url,
.screen-details_references .reference-list_actions .icon--go-to-url {
  height: 2rem;
}
.item-details_actions .action-list_actions .icon--play-sound,
.item-details_actions .reference-list_actions .icon--play-sound, .item-details_references .action-list_actions .icon--play-sound,
.item-details_references .reference-list_actions .icon--play-sound,
.screen-details_actions .action-list_actions .icon--play-sound,
.screen-details_actions .reference-list_actions .icon--play-sound,
.screen-details_references .action-list_actions .icon--play-sound,
.screen-details_references .reference-list_actions .icon--play-sound {
  height: 2rem;
}
.item-details_actions .action-list_references,
.item-details_actions .reference-list_references, .item-details_references .action-list_references,
.item-details_references .reference-list_references,
.screen-details_actions .action-list_references,
.screen-details_actions .reference-list_references,
.screen-details_references .action-list_references,
.screen-details_references .reference-list_references {
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
.item-details_actions .action-list_references .icon,
.item-details_actions .reference-list_references .icon, .item-details_references .action-list_references .icon,
.item-details_references .reference-list_references .icon,
.screen-details_actions .action-list_references .icon,
.screen-details_actions .reference-list_references .icon,
.screen-details_references .action-list_references .icon,
.screen-details_references .reference-list_references .icon {
  align-self: baseline;
  height: 2rem;
  align-self: center;
  padding-left: 0.3rem;
}
.item-details_actions .action-list_references .icon:hover,
.item-details_actions .reference-list_references .icon:hover, .item-details_references .action-list_references .icon:hover,
.item-details_references .reference-list_references .icon:hover,
.screen-details_actions .action-list_references .icon:hover,
.screen-details_actions .reference-list_references .icon:hover,
.screen-details_references .action-list_references .icon:hover,
.screen-details_references .reference-list_references .icon:hover {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
  cursor: default;
}

.items {
  border: 2px solid white;
  position: relative;
}

.popup_rewards {
  min-width: 120rem;
  max-width: 90vw;
  height: 90vw;
  overflow-y: auto;
  overflow-x: auto;
}
.popup_rewards::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.popup_rewards::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.popup_rewards::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}

.story-editor_sidebar {
  display: grid;
  grid-template-rows: auto 1fr;
}
.story-editor_sidebar_edit-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--color-grey-very-light);
  height: 4rem;
}
.story-editor_sidebar_edit-container .icon--copy,
.story-editor_sidebar_edit-container .icon--expand {
  pointer-events: none;
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
  cursor: default;
}
.story-editor_sidebar_edit-container .icon--expand {
  display: none;
}
.story-editor_sidebar_edit-container .dropdownbutton-options {
  padding: 1rem 0.1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 12rem;
  right: -8rem;
}
.story-editor_sidebar_edit-container .dropdownbutton-options .dropdownbutton-option:hover {
  padding: 0 1rem;
}
.story-editor_sidebar_edit-container .dropdownbutton-options .dropdownbutton-option:hover:hover {
  background-color: white;
  color: var(--color-grey-very-dark);
}
.story-editor_sidebar_storyline {
  margin-left: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 23.5rem);
}
.story-editor_sidebar_storyline::-webkit-scrollbar {
  width: 0.5rem;
}
.story-editor_sidebar_storyline::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.story-editor_sidebar_storyline::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.story-editor_sidebar_storyline .game_name--name {
  cursor: pointer;
}
.story-editor_sidebar .storyline {
  margin-top: 2rem;
  column-gap: 0.1rem;
  line-height: 2.6rem;
  font-size: 1.5rem;
}
.story-editor_main {
  height: calc(100vh - 15.5rem);
  display: grid;
  grid-template-columns: 60rem 1fr;
}
.story-editor_story {
  height: calc(100vh - 15.5rem);
  padding: 0 1rem;
  display: grid;
  grid-template-rows: 4rem 1fr;
  overflow-x: hidden;
  overflow-y: hidden;
}
.story-editor_story_header {
  justify-self: left;
  background-color: var(--color-grey-very-dark);
  color: white;
  text-align: center;
  align-self: center;
  font-size: 2rem;
}
.story-editor_story_header .color-line {
  margin-top: -0.5rem;
}
.story-editor_story_header .wysiwyg-bar_icon--marker {
  margin-top: -0.2rem;
}
.story-editor_story_background {
  max-height: calc(100vh - 19rem);
  min-height: 30rem;
  height: fit-content;
  width: 100%;
  border: 1px solid white;
  background-color: white;
  justify-self: center;
  display: grid;
  grid-template-rows: minmax(min-content, max-content) minmax(0, 1fr) 5rem;
  padding: 1rem 1rem 0 1rem;
}
.story-editor_story_background .story_header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-bottom: 1rem;
}
.story-editor_story_background .story_header .story_title {
  text-align: center;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
}
.story-editor_story_background .story_header button:hover .icon {
  fill: var(--color-grey-very-dark);
  stroke: var(--color-grey-very-dark);
}
.story-editor_story_background .story_header .story_character {
  align-self: flex-start;
}
.story-editor_story_background .story_header .story_character .icon--character {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.story-editor_story_background .story_header .story_character .icon--add {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  height: 1.2rem;
  align-self: baseline;
}
.story-editor_story_background .story_text {
  margin-bottom: 0.5rem;
}
.story-editor_story_background .story_text.ql-container {
  font-size: inherit;
  font-family: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
.story-editor_story_background .story_text.ql-container::-webkit-scrollbar {
  width: 0.5rem;
}
.story-editor_story_background .story_text.ql-container::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.story-editor_story_background .story_text.ql-container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.story-editor_story_background .story_text.ql-container .ql-editor {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
}
.story-editor_story_background .story_text.ql-container .ql-editor::-webkit-scrollbar {
  width: 0.5rem;
}
.story-editor_story_background .story_text.ql-container .ql-editor::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.story-editor_story_background .story_text.ql-container .ql-editor::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.story-editor_story_background .story_text p {
  margin: 0;
}
.story-editor_story_background .story_button-container {
  align-self: center;
  justify-self: center;
  display: flex;
  gap: 1rem;
}
.story-editor_story_background .story_button-container .icon {
  height: 1.5rem;
  stroke-width: 0;
}
.story-editor_story_background .story_button-container .icon--direction-up {
  margin-bottom: 0.2rem;
}
.story-editor_story_background .story_button-container .icon--direction-down {
  margin-top: 0.2rem;
}
.story-editor_characteristics {
  min-height: calc(100vh - 15.5rem);
  display: grid;
  grid-template-rows: 4rem 1fr;
  overflow-x: hidden;
  color: white;
  overflow: hidden;
}
.story-editor_characteristics_tabs {
  width: calc(100vw - 85rem);
  min-width: 51rem;
  display: grid;
  grid-template-rows: 4rem minmax(0, 1fr);
  height: 100%;
}
.story-editor_characteristics_tabs .tab_container {
  display: flex;
  column-gap: 0.2rem;
  flex-wrap: wrap;
  min-width: max-content;
}
.story-editor_characteristics_tabs .tab {
  order: 1;
  display: block;
  padding: 1rem 2rem;
  margin-right: 0.2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: pointer;
  background: var(--color-grey-very-light);
  color: var(--color-grey-very-dark);
  font-weight: 500;
  text-align: center;
  transition: background ease 0.2s;
  height: 4rem;
}
.story-editor_characteristics_tabs .last-label {
  margin-right: 0;
}
.story-editor_characteristics_tabs .characteristics_content {
  order: 99;
  flex-grow: 1;
  min-width: 51rem;
  max-width: 90rem;
  display: none;
  height: calc(100vh - 19rem);
}
.story-editor_characteristics_tabs input[type=radio] {
  display: none;
}
.story-editor_characteristics_tabs input[type=radio]:checked + label,
.story-editor_characteristics_tabs .tab--selected {
  background: var(--color-primary);
  color: white;
}
.story-editor_characteristics_tabs input[type=radio]:checked + label + .characteristics_content {
  display: block;
  color: black;
}

.characteristics_content .has-error .form_field {
  border: 2px solid red;
  background-color: rgb(254, 235, 235);
}
.characteristics_content .has-error .form_label {
  color: red;
}
.characteristics_content .gameplay_overview {
  overflow-y: auto;
  overflow-x: auto;
}
.characteristics_content .gameplay_overview::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.characteristics_content .gameplay_overview::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
  border-radius: 100px;
}
.characteristics_content .gameplay_overview::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.characteristics_content .character_overview,
.characteristics_content .gameplay_overview {
  padding: 1rem 2rem 0 2rem;
  height: 16rem;
  background-color: white;
  margin-bottom: 0.5rem;
}
.characteristics_content .character_overview .impression-group,
.characteristics_content .gameplay_overview .impression-group {
  margin-top: 0.6rem;
}
.characteristics_content .character_overview .characters-group,
.characteristics_content .gameplay_overview .characters-group {
  overflow-y: hidden;
  overflow-x: auto;
  cursor: pointer;
}
.characteristics_content .character_overview .characters-group::-webkit-scrollbar,
.characteristics_content .gameplay_overview .characters-group::-webkit-scrollbar {
  width: 1.2rem;
  height: 1.2rem;
}
.characteristics_content .character_overview .characters-group::-webkit-scrollbar-track,
.characteristics_content .gameplay_overview .characters-group::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.characteristics_content .character_overview .characters-group::-webkit-scrollbar-thumb,
.characteristics_content .gameplay_overview .characters-group::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.characteristics_content .character_overview .characters-group,
.characteristics_content .character_overview .impression-group,
.characteristics_content .gameplay_overview .characters-group,
.characteristics_content .gameplay_overview .impression-group {
  display: flex;
  column-gap: 1.2rem;
}
.characteristics_content .character_overview .characters-group .character-container,
.characteristics_content .character_overview .characters-group .impression-container,
.characteristics_content .character_overview .impression-group .character-container,
.characteristics_content .character_overview .impression-group .impression-container,
.characteristics_content .gameplay_overview .characters-group .character-container,
.characteristics_content .gameplay_overview .characters-group .impression-container,
.characteristics_content .gameplay_overview .impression-group .character-container,
.characteristics_content .gameplay_overview .impression-group .impression-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0.2rem;
}
.characteristics_content .character_overview .characters-group .character_image,
.characteristics_content .character_overview .impression-group .character_image,
.characteristics_content .gameplay_overview .characters-group .character_image,
.characteristics_content .gameplay_overview .impression-group .character_image {
  position: relative;
  height: 8rem;
  width: 8rem;
  background-color: var(--color-grey-very-light);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
}
.characteristics_content .character_overview .characters-group .character_image-container,
.characteristics_content .character_overview .impression-group .character_image-container,
.characteristics_content .gameplay_overview .characters-group .character_image-container,
.characteristics_content .gameplay_overview .impression-group .character_image-container {
  width: 100%;
  height: 100%;
}
.characteristics_content .character_overview .characters-group .character_image img,
.characteristics_content .character_overview .impression-group .character_image img,
.characteristics_content .gameplay_overview .characters-group .character_image img,
.characteristics_content .gameplay_overview .impression-group .character_image img {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  object-position: top;
  border-radius: 0.5rem;
}
.characteristics_content .character_overview .characters-group .character_image.active,
.characteristics_content .character_overview .impression-group .character_image.active,
.characteristics_content .gameplay_overview .characters-group .character_image.active,
.characteristics_content .gameplay_overview .impression-group .character_image.active {
  box-shadow: 0px 0px 0px 2px #e67e22;
}
.characteristics_content .character_overview .characters-group .character_image.active .character_btn-container,
.characteristics_content .character_overview .impression-group .character_image.active .character_btn-container,
.characteristics_content .gameplay_overview .characters-group .character_image.active .character_btn-container,
.characteristics_content .gameplay_overview .impression-group .character_image.active .character_btn-container {
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  column-gap: 0.2rem;
}
.characteristics_content .character_overview .characters-group .character_image.active .character_btn-container .image_delete,
.characteristics_content .character_overview .impression-group .character_image.active .character_btn-container .image_delete,
.characteristics_content .gameplay_overview .characters-group .character_image.active .character_btn-container .image_delete,
.characteristics_content .gameplay_overview .impression-group .character_image.active .character_btn-container .image_delete {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.characteristics_content .character_overview .characters-group .character_image.active .character_btn-container .image_delete .icon,
.characteristics_content .character_overview .impression-group .character_image.active .character_btn-container .image_delete .icon,
.characteristics_content .gameplay_overview .characters-group .character_image.active .character_btn-container .image_delete .icon,
.characteristics_content .gameplay_overview .impression-group .character_image.active .character_btn-container .image_delete .icon {
  height: 1.3rem;
  fill: white;
  stroke: white;
}
.characteristics_content .character_overview .characters-group .character_image.active .character_btn-container .image_edit,
.characteristics_content .character_overview .impression-group .character_image.active .character_btn-container .image_edit,
.characteristics_content .gameplay_overview .characters-group .character_image.active .character_btn-container .image_edit,
.characteristics_content .gameplay_overview .impression-group .character_image.active .character_btn-container .image_edit {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.characteristics_content .character_overview .characters-group .character_image.active .character_btn-container .image_edit .icon,
.characteristics_content .character_overview .impression-group .character_image.active .character_btn-container .image_edit .icon,
.characteristics_content .gameplay_overview .characters-group .character_image.active .character_btn-container .image_edit .icon,
.characteristics_content .gameplay_overview .impression-group .character_image.active .character_btn-container .image_edit .icon {
  height: 1.3rem;
  fill: white;
  stroke: white;
}
.characteristics_content .character_overview .characters-group .character_image .icon--character,
.characteristics_content .character_overview .impression-group .character_image .icon--character,
.characteristics_content .gameplay_overview .characters-group .character_image .icon--character,
.characteristics_content .gameplay_overview .impression-group .character_image .icon--character {
  height: 4rem;
}
.characteristics_content .character_overview .characters-group .character_image .icon--character:hover,
.characteristics_content .character_overview .impression-group .character_image .icon--character:hover,
.characteristics_content .gameplay_overview .characters-group .character_image .icon--character:hover,
.characteristics_content .gameplay_overview .impression-group .character_image .icon--character:hover {
  fill: var(--color-grey-medium);
  stroke: var(--color-grey-medium);
  cursor: grab;
}
.characteristics_content .character_overview .characters-group .character_name,
.characteristics_content .character_overview .impression-group .character_name,
.characteristics_content .gameplay_overview .characters-group .character_name,
.characteristics_content .gameplay_overview .impression-group .character_name {
  padding-top: 0.2rem;
  text-align: center;
  width: 9rem;
  height: 1.8rem;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.characteristics_content .character_overview .characters-group .impression-container .image_delete .icon,
.characteristics_content .character_overview .impression-group .impression-container .image_delete .icon,
.characteristics_content .gameplay_overview .characters-group .impression-container .image_delete .icon,
.characteristics_content .gameplay_overview .impression-group .impression-container .image_delete .icon {
  height: 1.8rem;
}
.characteristics_content .character_overview .characters-group .impression-container .image_edit,
.characteristics_content .character_overview .characters-group .impression-container .image_add,
.characteristics_content .character_overview .impression-group .impression-container .image_edit,
.characteristics_content .character_overview .impression-group .impression-container .image_add,
.characteristics_content .gameplay_overview .characters-group .impression-container .image_edit,
.characteristics_content .gameplay_overview .characters-group .impression-container .image_add,
.characteristics_content .gameplay_overview .impression-group .impression-container .image_edit,
.characteristics_content .gameplay_overview .impression-group .impression-container .image_add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 2.5rem;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image,
.characteristics_content .character_overview .impression-group .impression-container .impression_image,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image {
  position: relative;
  height: 9rem;
  width: 16rem;
  background-color: var(--color-grey-very-light);
  border-radius: 0.5rem;
  border: 1px solid var(--color-grey-medium);
  display: flex;
  align-items: center;
  justify-content: center;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image-container,
.characteristics_content .character_overview .impression-group .impression-container .impression_image-container,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image-container,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image-container {
  height: 100%;
  width: 100%;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image img,
.characteristics_content .character_overview .impression-group .impression-container .impression_image img,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image img,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image img {
  height: 9rem;
  width: 16rem;
  object-fit: cover;
  border-radius: 0.5rem;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .image_delete,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .image_delete,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .image_delete,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .image_delete {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .image_delete .icon,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .image_delete .icon,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .image_delete .icon,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .image_delete .icon {
  height: 1.6rem;
  fill: white;
  stroke: white;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .image_edit,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .image_edit,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .image_edit,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .image_edit {
  display: none;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .image_add,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .image_add,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .image_add,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .image_add {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .image_add .icon,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .image_add .icon,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .image_add .icon,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .image_add .icon {
  height: 1.6rem;
  fill: white;
  stroke: white;
}
.characteristics_content .character_overview .characters-group .impression-container .impression_image .icon--image,
.characteristics_content .character_overview .impression-group .impression-container .impression_image .icon--image,
.characteristics_content .gameplay_overview .characters-group .impression-container .impression_image .icon--image,
.characteristics_content .gameplay_overview .impression-group .impression-container .impression_image .icon--image {
  height: 5rem;
  fill: var(--color-grey-light);
  stroke: var(--color-grey-light);
}
.characteristics_content .character_overview .add-character,
.characteristics_content .gameplay_overview .add-character {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
.characteristics_content .characteristics_description {
  background-color: white;
  padding: 1.5rem;
}
.characteristics_content .characteristics_description.tab_characters, .characteristics_content .characteristics_description.tab_gameplay {
  height: calc(100vh - 36rem);
  overflow-y: auto;
  overflow-x: hidden;
}
.characteristics_content .characteristics_description.tab_characters::-webkit-scrollbar, .characteristics_content .characteristics_description.tab_gameplay::-webkit-scrollbar {
  width: 0.5rem;
}
.characteristics_content .characteristics_description.tab_characters::-webkit-scrollbar-track, .characteristics_content .characteristics_description.tab_gameplay::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.characteristics_content .characteristics_description.tab_characters::-webkit-scrollbar-thumb, .characteristics_content .characteristics_description.tab_gameplay::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.characteristics_content .characteristics_description.tab_objectives, .characteristics_content .characteristics_description.tab_rewards, .characteristics_content .characteristics_description.tab_timelines {
  height: calc(100vh - 19.5rem);
}
.characteristics_content .characteristics_description .character_forms-container {
  display: grid;
  grid-template-columns: 30rem 1fr 2.4rem;
}
.characteristics_content .characteristics_description .character_forms-container .form_number-field {
  width: 16rem;
}
.characteristics_content .characteristics_description .character_textareas-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  margin: 2rem 0 0 0;
}
.characteristics_content .characteristics_description .character_textareas-container textarea {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 49rem);
}
.characteristics_content .characteristics_description .character_textareas-container textarea::-webkit-scrollbar {
  width: 0.5rem;
}
.characteristics_content .characteristics_description .character_textareas-container textarea::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.characteristics_content .characteristics_description .character_textareas-container textarea::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.characteristics_content .characteristics_description .gameplay_textareas-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  margin: 0;
}
.characteristics_content .characteristics_description .gameplay_textareas-container textarea {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 42rem);
}
.characteristics_content .characteristics_description .gameplay_textareas-container textarea::-webkit-scrollbar {
  width: 0.5rem;
}
.characteristics_content .characteristics_description .gameplay_textareas-container textarea::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.characteristics_content .characteristics_description .gameplay_textareas-container textarea::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}
.characteristics_content .characteristics_description .objectives_textareas-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  margin: 0 0 1rem 0;
}
.characteristics_content .characteristics_description .objectives_textareas-container textarea {
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--color-grey-very-light);
  width: 100%;
  height: calc(100vh - 23rem);
}
.characteristics_content .characteristics_description .objectives_textareas-container textarea::-webkit-scrollbar {
  width: 0.5rem;
}
.characteristics_content .characteristics_description .objectives_textareas-container textarea::-webkit-scrollbar-track {
  background-color: var(--color-grey-very-light);
}
.characteristics_content .characteristics_description .objectives_textareas-container textarea::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
}