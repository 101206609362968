.widget_media-usage {
	.media-usage {
		&_folders {
			display: flex;
			flex-direction: column;
			justify-content: center;
			row-gap: 0.8rem;
		}

		&_header-container,
		&_audio-container,
		&_documents-container,
		&_images-container,
		&_video-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			justify-items: center;
			align-items: center;
		}

		&_header-container {
			font-weight: 600;
			border-bottom: 1px solid var(--color-grey-very-light);
		}

		&_folder {
			justify-self: flex-start;
		}

		&_size {
			justify-self: end;
		}

		&_totals {
			margin-top: 1rem;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			text-align: end;

			&--labels {
				width: 100%;
				grid-column: 1 / span 2;
				justify-self: end;
				border-top: 1px solid var(--color-primary-light);
				padding-top: 0.5rem;
			}

			&--numbers {
				border-top: 1px solid var(--color-primary-light);
				padding-top: 0.5rem;
			}

			&--percentages {
				justify-self: start;
				margin-left: 2rem;
				padding-top: 0.7rem;
			}
		}
	}
	.btn {
		padding: 0.1rem 0.7rem;
		font-size: 1.4rem;
		min-width: 7rem;
	}
}
