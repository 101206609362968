#tooltip {
	position: fixed;
	z-index: 99999;
	background-color: lightyellow;
	border: 1px solid var(--color-grey-very-dark);
	color: var(--color-grey-very-dark);
	padding: 0.2rem 0.5rem;
	font-size: 1.5rem;
	display: none;
}

.explanation {
	font-style: italic;
}
